import React from "react";
import ServiceItem from "../../common/components/serviceItem";
import CustomModal from "../../common/components/customModal";
import ModalForms from "./components/modalForms";
import { Paper, Typography } from "@mui/material";
import CustomAlert from "../../common/components/customAlert";
import ScrollToTop from "../../common/components/scrollToTop";

const CarExchangeView = ({
  formTypes,
  modalType,
  _showModal,
  _onSubmitClick,
  carName,
  setCarName,
  carNameError,
  budget,
  setBudget,
  budgetError,
  state,
  setState,
  stateError,
  carNumber,
  setCarNumber,
  carNumberError,
  distance,
  setDistance,
  distanceError,
  description,
  setDescription,
  descriptionError,
  carType,
  setCarType,
  engineType,
  setEngineType,
  ownerDetails,
  setOwnerDetails,
  ownerDetailsError,
  apiError,
  setApiError,
  isLoading,
  name,
  setName,
  nameError,
  mobileNumber,
  setMobileNumber,
  mobileNumberError,
  firstSideImage,
  setFirstSideImage,
  secondSideImage,
  setSecondSideImage,
  thirdSideImage,
  setThirdSideImage,
  forthSideImage,
  setForthSideImage,
  interiorImage,
  setInteriorImage,
  imageError,
  newCarName,
  setNewCarName,
  newCarNameError,
  email,
  setEmail,
  emailError,
  city,
  setCity,
  cityError,
}) => {
  return (
    <Paper className="container flex-grow-1" elevation={0}>
      <CustomAlert
        isShow={apiError}
        message={apiError}
        onClose={() => setApiError(null)}
      />
      <CustomModal
        showModal={modalType ? true : false}
        isSuccess={modalType === "SUCCESS"}
        hideModal={() => _showModal(null)}
        positiveButtonCallBack={() => {
          if (modalType === "SUCCESS") {
            _showModal(null);
          } else {
            _onSubmitClick();
          }
        }}
        negativeButtonCallBack={() => _showModal(null)}
        title={modalType && modalType.title ? modalType.title : null}
        isPositiveButtonLoading={isLoading}
      >
        <ModalForms
          modalType={modalType}
          carName={carName}
          setCarName={setCarName}
          carNameError={carNameError}
          budget={budget}
          setBudget={setBudget}
          budgetError={budgetError}
          state={state}
          setState={setState}
          stateError={stateError}
          carNumber={carNumber}
          setCarNumber={setCarNumber}
          carNumberError={carNumberError}
          distance={distance}
          setDistance={setDistance}
          distanceError={distanceError}
          description={description}
          setDescription={setDescription}
          descriptionError={descriptionError}
          carType={carType}
          setCarType={setCarType}
          engineType={engineType}
          setEngineType={setEngineType}
          ownerDetails={ownerDetails}
          setOwnerDetails={setOwnerDetails}
          ownerDetailsError={ownerDetailsError}
          name={name}
          setName={setName}
          nameError={nameError}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          mobileNumberError={mobileNumberError}
          firstSideImage={firstSideImage}
          setFirstSideImage={setFirstSideImage}
          secondSideImage={secondSideImage}
          setSecondSideImage={setSecondSideImage}
          thirdSideImage={thirdSideImage}
          setThirdSideImage={setThirdSideImage}
          forthSideImage={forthSideImage}
          setForthSideImage={setForthSideImage}
          interiorImage={interiorImage}
          setInteriorImage={setInteriorImage}
          imageError={imageError}
          newCarName={newCarName}
          setNewCarName={setNewCarName}
          newCarNameError={newCarNameError}
          email={email}
          setEmail={setEmail}
          emailError={emailError}
          city={city}
          setCity={setCity}
          cityError={cityError}
        />
      </CustomModal>
      <div className="d-flex flex-row align-items-center my-3">
        <img
          src={require("../../assets/images/exchange.png")}
          alt="exchange"
          className="img-fluid"
          width={64}
          height={64}
        />
        <Typography className="ms-3" variant="caption">
          Car Exchange
        </Typography>
      </div>
      <div className="height70vh d-flex flex-row align-items-center">
        <div className="d-flex flex-row justify-content-md-between services_container">
          {formTypes.map((item) => {
            return (
              <ServiceItem
                title={item.title}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                imageSrc={item.imagePath}
                onClick={() =>
                  _showModal({ title: item.title, type: item.type })
                }
              />
            );
          })}
        </div>
      </div>
    </Paper>
  );
};

export default CarExchangeView;
