import React from "react";
import ServiceItem from "../../common/components/serviceItem";
import CustomModal from "../../common/components/customModal";
import ModalForms from "./components/modalForms";
import { Paper, Typography } from "@mui/material";
import CustomAlert from "../../common/components/customAlert";
import ScrollToTop from "../../common/components/scrollToTop";

const CarLoanView = ({
  modalType,
  _showModal,
  _onSubmitClick,
  carName,
  setCarName,
  carNameError,
  budget,
  setBudget,
  budgetError,
  engineType,
  setEngineType,
  loanYears,
  setLoanYears,
  loanYearsError,
  oldLoan,
  setOldLoan,
  oldLoanError,
  apiError,
  setApiError,
  isLoading,
  ageType,
  setAgeType,
  name,
  setName,
  nameError,
  mobileNumber,
  setMobileNumber,
  numberError,
  email,
  setEmail,
  emailError,
  companyName,
  setCompanyName,
  companyNameError,
  city,
  setCity,
  cityError,
  state,
  setState,
  stateError,
  carNumber,
  setCarNumber,
  carNumberError,
}) => {
  return (
    <Paper className="container flex-grow-1" elevation={0} style={{ height: "100%" }}>
      <ScrollToTop />
      <CustomAlert
        isShow={apiError}
        message={apiError}
        onClose={() => setApiError(null)}
      />
      <CustomModal
        showModal={modalType ? true : false}
        isSuccess={modalType === "SUCCESS"}
        hideModal={() => _showModal(null)}
        positiveButtonCallBack={_onSubmitClick}
        negativeButtonCallBack={() => _showModal(null)}
        title={modalType && modalType.title ? modalType.title : null}
        isPositiveButtonLoading={isLoading}
      >
        <ModalForms
          modalType={modalType}
          carName={carName}
          setCarName={setCarName}
          carNameError={carNameError}
          budget={budget}
          setBudget={setBudget}
          budgetError={budgetError}
          engineType={engineType}
          setEngineType={setEngineType}
          loanYears={loanYears}
          setLoanYears={setLoanYears}
          loanYearsError={loanYearsError}
          oldLoan={oldLoan}
          setOldLoan={setOldLoan}
          oldLoanError={oldLoanError}
          ageType={ageType}
          setAgeType={setAgeType}
          name={name}
          setName={setName}
          nameError={nameError}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          numberError={numberError}
          email={email}
          setEmail={setEmail}
          emailError={emailError}
          companyName={companyName}
          setCompanyName={setCompanyName}
          companyNameError={companyNameError}
          city={city}
          setCity={setCity}
          cityError={cityError}
          state={state}
          setState={setState}
          stateError={stateError}
          carNumber={carNumber}
          setCarNumber={setCarNumber}
          carNumberError={carNumberError}
        />
      </CustomModal>
      <div className="d-flex flex-row align-items-center my-3">
        <img
          src={require("../../assets/images/car_loans_logo.png")}
          alt="insurance"
          className="img-fluid"
          width={64}
          height={64}
        />
        <Typography className="ms-3" variant="caption">
          Car Loans
        </Typography>
      </div>
      <div className="height70vh d-flex flex-row align-items-center">
        <div className="d-flex flex-row justify-content-md-between services_container">
          <ServiceItem
            title={"New Car Loans"}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            imageSrc={require("../../assets/images/services.png")}
            onClick={() =>
              _showModal({ title: "New Car Loan", type: "NEW_CAR_LOAN" })
            }
          />

          <ServiceItem
            title={"Used Car Loans"}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            imageSrc={require("../../assets/images/services.png")}
            onClick={() =>
              _showModal({ title: "Used Car Loan", type: "USED_CAR_LOAN" })
            }
          />

          <ServiceItem
            title={"Top - Up Car Loans"}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            imageSrc={require("../../assets/images/services.png")}
            onClick={() =>
              _showModal({ title: "Top Up Car Loan", type: "TOP_UP_LOAN" })
            }
          />
        </div>
      </div>
    </Paper>
  );
};

export default CarLoanView;
