import { httpMethod, request } from "./request";

export const login = (data) => {
  let url = "/api/login";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: false,
    data: data,
  });
};

export const register = (data) => {
  let url = "/api/register";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: false,
    data: data,
  });
};

export const sendResetEmail = (data) => {
  let url = "/api/send_email";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: false,
    data: data,
  });
};

export const changePassword = (data) => {
  let url = "/api/change_password";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: false,
    data: data,
  });
};

export const getInsuranceMaster = () => {
  let url = "/api/insurance_master";
  return request({
    requestMethod: httpMethod.GET,
    url: url,
    isAddHeader: true,
  });
};

export const getTopBannerImages = () => {
  let url = "api/baner_top_image";
  return request({
    requestMethod: httpMethod.GET,
    url: url,
    isAddHeader: false,
  });
};

export const getBottomBannerImage = () => {
  let url = "/api/baner_bottom_image";
  return request({
    requestMethod: httpMethod.GET,
    url: url,
    isAddHeader: false,
  });
};

export const buyCar = (data) => {
  let url = "/api/car_buy";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const sellCar = (data) => {
  let url = "/api/car_sell";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const exchangeCar = (data) => {
  let url = "/api/car_exchange";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const usedCarLoans = (data) => {
  let url = "/api/used_car_loan";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const newCarLoans = (data) => {
  let url = "/api/new_car_loan";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const topUpLoans = (data) => {
  let url = "/api/top_up_loan";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const addNonMotorInsurance = (data) => {
  let url = "/api/non_motor_insurance";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const addHealthInsurance = (data) => {
  let url = "/api/non_motor_health_insurance";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const addOtherCommercialInsurance = (data) => {
  let url = "/api/non_motor_other_commercial";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const addTravelInsurance = (data) => {
  let url = "/api/non_motor_travel_insurance";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const addMotorInsurance = (data) => {
  let url = "/api/motor_insurance";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const getModels = () => {
  let url = "/api/model_master";
  return request({
    requestMethod: httpMethod.GET,
    url: url,
    isAddHeader: true,
  });
};

export const addProductEnquiry = (data) => {
  let url = "/api/product_inquiry";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const addTestRide = (data) => {
  let url = "/api/test_ride";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const addExchange = (data) => {
  let url = "/api/exchange";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const getServices = () => {
  let url = "/api/services_file";
  return request({
    requestMethod: httpMethod.GET,
    url: url,
    isAddHeader: true,
  });
};

export const addLifeInsuranceRetirementPlan = (data) => {
  let url = "/api/life_insurance_retirement_plan";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const addLifeInsuranceTermPlan = (data) => {
  let url = "/api/life_insurance_term_plan";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const addLifeInsuranceWorkerRetirementPlan = (data) => {
  let url = "/api/life_insurance_worker_retirement_plan";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const addLifeInsuranceOtherPlan = (data) => {
  let url = "/api/life_insurance_other_plan";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
    data: data,
  });
};

export const logout = () => {
  let url = "/api/logout";
  return request({
    requestMethod: httpMethod.POST,
    url: url,
    isAddHeader: true,
  });
};
