import { Alert, Checkbox, Paper, Typography } from "@mui/material";
import React from "react";
import BaseInput, { inputType } from "../../common/components/inputs/baseInput";
import { makeStyles } from "@mui/styles";
import { darkGrey, formTitleColor, primaryBlue } from "../../common/colors";
import SolidButton from "../../common/components/buttons/solidButton";
import { Link } from "react-router-dom";
import { routes } from "../../common/utils/constants";
import CustomAlert from "../../common/components/customAlert";

const useStyles = makeStyles((theme) => {
  return {
    pageContainer: {
      minHeight: "100vh",
      justifyContent: "center",
      alignItems: "center",
    },
    formContainer: {
      width: "90vw",
      margin: "auto",
      paddingTop: "24px",
      paddingBottom: "24px",
      paddingLeft: "16px",
      paddingRight: "16px",
      [theme.breakpoints.up("md")]: {
        width: "50vw",
        paddingLeft: "56px",
        paddingRight: "56px",
        paddingTop: "44px",
        paddingBottom: "44px",
      },
    },
    formHeader: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "16px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "24px",
      },
    },
    height50: {
      height: "50px",
    },
    checkBoxContainer: {
      display: "flex",
      flexDirection: "row",
      marginBlock: "18px",
      alignItems: "center",
    },
    checkBoxBaseText: {
      fontSize: "14px",
      color: darkGrey,
    },
    checkBoxHighLightText: {
      fontSize: "14px",
      color: primaryBlue,
    },
    redirectText: {
      fontSize: "14px",
      color: formTitleColor,
    },
    redirect: {
      display: "flex",
      flexDirection: "row",
      alignSelf: "center",
      marginTop: "18px",
    },
    alert: {
      display: "flex",
      flexDirection: "row",
    },
  };
});

const RegisterPageView = ({
  isChecked,
  setIsChecked,
  name,
  number,
  email,
  password,
  setName,
  setNumber,
  setEmail,
  setPassword,
  onCreateClick,
  nameError,
  numberError,
  emailError,
  passwordError,
  isLoading,
  error,
  setError,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.pageContainer} elevation={0}>
      <CustomAlert
        isShow={error}
        message={error}
        onClose={() => setError(null)}
      />
      <Paper elevation={3} className={classes.formContainer}>
        <div className={classes.formHeader}>
          <Typography variant="h5">Register</Typography>
        </div>
        <BaseInput
          placeholder="Enter Full Name"
          label="Name"
          value={name}
          setValue={setName}
          error={nameError}
        />
        <BaseInput
          placeholder="Enter Mobile Number"
          customClasses="mt-3"
          label="Mobile Number"
          value={number}
          setValue={setNumber}
          error={numberError}
        />
        <BaseInput
          placeholder="Enter Email Id"
          customClasses="mt-3"
          label="Email Id"
          value={email}
          setValue={setEmail}
          error={emailError}
        />
        <BaseInput
          placeholder="Enter Password"
          customClasses="mt-3"
          label="Password"
          value={password}
          setValue={setPassword}
          type={inputType.PASSWORD}
          error={passwordError}
        />

        <div className={classes.checkBoxContainer}>
          <Checkbox
            checked={isChecked}
            onChange={(event) => setIsChecked(event.target.checked)}
          />
          <div>
            <span className={classes.checkBoxBaseText}>
              {"I read and agreed to "}
            </span>
            <span className={classes.checkBoxHighLightText}>
              Terms & Conditions
            </span>
          </div>
        </div>

        <SolidButton
          btnText="CREATE ACCOUNT"
          btnClick={onCreateClick}
          isDisabled={!isChecked}
          isLoading={isLoading}
        />

        <div className={classes.redirect}>
          <span className={classes.redirectText}>
            {"Already have an account ? "}
          </span>
          <Link className={classes.checkBoxHighLightText} to={routes.LOGIN}>
            LOG IN
          </Link>
        </div>
      </Paper>
    </Paper>
  );
};

export default RegisterPageView;
