import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertType } from "../../common/components/customAlert";
import { changePassword, sendResetEmail } from "../../common/services/services";
import { routes } from "../../common/utils/constants";
import ForgotPasswordView from "./forgotPasswordView";

const ForgotPasswordContainer = () => {
  const navigate = useNavigate();

  const [isSentEmail, setIsSentEmail] = useState(false);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);
  const [otp, setOtp] = useState(null);
  const [otpError, setOtpError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [alertType, setAlertType] = useState(AlertType.ERROR);

  useEffect(() => {
    if (emailError && email) {
      setEmailError(null);
    }

    if (passwordError && password) {
      setPasswordError(null);
    }

    if (confirmPasswordError && confirmPassword) {
      setConfirmPasswordError(null);
    }
  }, [email, password, confirmPassword]);

  const onVerifyClick = () => {
    if (!email || email.trim() === "") {
      setEmailError("Enter Email");
      return;
    } else {
      setEmailError(null);
    }

    setIsLoading(true);
    let formData = new FormData();
    formData.append("email", email);
    sendResetEmail(formData)
      .then((response) => {
        setIsLoading(false);
        setIsSentEmail(true);
        setAlertType(AlertType.SUCCESS);
        setApiError(response.Message);
      })
      .catch((error) => {
        setIsLoading(false);
        setAlertType(AlertType.ERROR);
        setApiError(error.message);
      });
  };

  const onResetPasswordClick = () => {
    if (!password || password.trim() === "") {
      setPasswordError("Enter password");
      return;
    } else {
      setPasswordError(null);
    }

    if (!confirmPassword || confirmPassword.trim() === "") {
      setPasswordError("Enter password");
      return;
    } else if (password !== confirmPassword) {
      setPasswordError("Password and confirm password do not match.");
      return;
    } else {
      setPasswordError(null);
    }

    if (!otp || otp.length !== 6) {
      setOtpError("Enter otp");
      return;
    } else {
      setOtpError(null);
    }

    setIsLoading(true);
    let formData = new FormData();
    formData.append("verification_code", otp);
    formData.append("new_password", password);
    changePassword(formData)
      .then((response) => {
        setIsLoading(false);
        setAlertType(AlertType.SUCCESS);
        setApiError(response.Message);
      })
      .catch((error) => {
        setIsLoading(false);
        setAlertType(AlertType.ERROR);
        setApiError(error.message);
      });
  };

  const _handleAlertClose = () => {
    if (alertType === AlertType.ERROR) {
      setApiError(null);
    } else if (otp) {
      setApiError(null);
      navigate(routes.HOME_PAGE);
    } else {
      setApiError(null);
    }
  };

  return (
    <ForgotPasswordView
      isSentEmail={isSentEmail}
      email={email}
      password={password}
      setEmail={setEmail}
      setPassword={setPassword}
      emailError={emailError}
      passwordError={passwordError}
      onVerifyClick={onVerifyClick}
      onResetPasswordClick={onResetPasswordClick}
      setConfirmPassword={setConfirmPassword}
      confirmPasswordError={confirmPasswordError}
      confirmPassword={confirmPassword}
      setOtp={setOtp}
      isLoading={isLoading}
      otpError={otpError}
      error={apiError}
      alertType={alertType}
      _handleAlertClose={_handleAlertClose}
    />
  );
};

export default ForgotPasswordContainer;
