export const routes = {
  HOME_PAGE: "/",
  INSURANCE: "/insurance",
  CAR_EXCHANGE: "/exchange",
  CAR_LOAN: "/loan",
  PRODUCT_ENQUIRY: "/enquiry",
  REGISTER: "/register",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot",
  SERVICES: "/services",
  LIFE_INSURANCE: "/lifeInsurance",
  BONNY_AUTO_HOME: "/auto",
  BONNY_YAMAHA_HOME: "/yamaha",
  ABOUT: "/about",
  TERMS: "/terms",
  PRIVACY_POLICY: "/privacy-policy",
};

export const getBaseUrl = () => {
  return "https://bonnyauto.co.in/Data/";
};

export const carTypes = [
  { label: "Hatch Back", value: "HATCH_BACK" },
  { label: "SUV", value: "SUV" },
];

export const engineTypes = [
  { label: "Petrol", value: "Petrol" },
  { label: "DIESEL", value: "Diesel" },
  { label: "CNG", value: "CNG" },
  { label: "Others", value: "Others" },
];

export const insuranceCompanies = [
  { label: "HDFC ERGO", value: "HDFC ERGO" },
  { label: "TATA AIG", value: "TATA AIG" },
  { label: "TATA AIA", value: "TATA AIA" },
];

export const insureTypes = [
  { label: "Me", value: "Me" },
  { label: "Me + Wife", value: "Me + Wife" },
  { label: "Me + wife +1", value: "Me + wife +1" },
  { label: "Me + wife + parents", value: "Me + wife + parents" },
  { label: "others", value: "others" },
];

export const carInsuranceTypes = [
  {
    label: "Comprehensive Car Insurance",
    value: "Comprehensive Car Insurance",
  },
  { label: "Third Party Car Insurance", value: "Third Party Car Insurance" },
  { label: "Standalone Damage cover", value: "Standalone Damage cover" },
  { label: "Private Car bundle policy)", value: "Private Car bundle policy)" },
];

export const ageTypes = [
  { label: "New Car", value: "New Car" },
  { label: "Old Used car", value: "Old Used car" },
];

export const emailRegex = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const phoneRegex = /^[0-9]*$/;

export const STATUS = {
  LOADING: "loading",
  SUCCESS: "succeeded",
  ERROR: "failed",
  IDLE: "idle",
};

export const wantTestRideEnum = [
  {
    label: "Yes",
    value: "Y",
  },
  { label: "No", value: "N" },
];

export const genderTypes = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const planTypes = [
  { label: "Term", value: "Term" },
  { label: "Saving", value: "Saving" },
  { label: "ULIPs", value: "ULIPs" },
  { label: "Retirements", value: "Retirements" },
  { label: "Combo Solutions", value: "Combo Solutions" },
  { label: "Term Solution", value: "Term Solution" },
  { label: "Wealth Solutions", value: "Wealth Solutions" },
  { label: "Saving Solutions", value: "Saving Solutions" },
  { label: "Retirement Solutions", value: "Retirement Solutions" },
  { label: "Group Solutions", value: "Group Solutions" },
];

export const modelTypes = [
  { label: "Scooter", value: "scooter" },
  { label: "Motor Bikes", value: "bikes" },
];

export const scooterTypes = [
  {
    label: "Aerox",
    value: "aerox",
    imageSource: require("../../assets/images/modelImages/aerox.png"),
  },
  {
    label: "Fascino 125 Fi Hybrid",
    value: "fascino125fihybrid",
    imageSource: require("../../assets/images/modelImages/fascino125fihybrid.png"),
  },
  {
    label: "Fascino 125 Fi",
    value: "fascino125fi",
    imageSource: require("../../assets/images/modelImages/fascino125fi.png"),
  },
  {
    label: "RayZR 125 Fi Hybrid",
    value: "rayzr125fihybrid",
    imageSource: require("../../assets/images/modelImages/rayzr125fihybrid.png"),
  },
  {
    label: "RayZR 125 Fi",
    value: "rayzr125fi",
    imageSource: require("../../assets/images/modelImages/rayzr125fi.png"),
  },
  {
    label: "RayZR Street Rally 125 Fi Hybrid",
    value: "rayzrstreetrallyhybrid",
    imageSource: require("../../assets/images/modelImages/rayzrstreetrallyhybrid.png"),
  },
  {
    label: "RayZR Street Rally 125 Fi",
    value: "rayzrstreetrally125fi",
    imageSource: require("../../assets/images/modelImages/rayzrstreetrally125fi.png"),
  },
  {
    label: "Alpha",
    value: "alpha",
    imageSource: require("../../assets/images/modelImages/alpha.png"),
  },
  {
    label: "Fascino",
    value: "fascino",
    imageSource: require("../../assets/images/modelImages/fascino.png"),
  },
  {
    label: "Ray Z",
    value: "rayz",
    imageSource: require("../../assets/images/modelImages/rayz.png"),
  },
  {
    label: "RayZR",
    value: "rayzr",
    imageSource: require("../../assets/images/modelImages/rayzr.png"),
  },
];

export const bikeTypes = [
  {
    label: "FZS 25 / FZ 25 BS VI",
    value: "fzs25",
    imageSource: require("../../assets/images/modelImages/fzs25.png"),
  },
  {
    label: "FZ-X",
    value: "fzx",
    imageSource: require("../../assets/images/modelImages/fzx.png"),
  },
  {
    label: "MT-15 Ver 2.0",
    value: "mt15ver2",
    imageSource: require("../../assets/images/modelImages/mt15ver2.png"),
  },
  {
    label: "R15S",
    value: "r15s",
    imageSource: require("../../assets/images/modelImages/r15s.png"),
  },
  {
    label: "R15 v4",
    value: "r15v4",
    imageSource: require("../../assets/images/modelImages/r15v4.png"),
  },
  {
    label: "R15M",
    value: "r15m",
    imageSource: require("../../assets/images/modelImages/r15m.png"),
  },
  {
    label: "YZF R15 BS6",
    value: "yzfr15",
    imageSource: require("../../assets/images/modelImages/yzfr15.png"),
  },
  {
    label: "FZS-FI BS VI",
    value: "fzsfi",
    imageSource: require("../../assets/images/modelImages/fzsfi.png"),
  },
  {
    label: "FZ-FI BS VI",
    value: "fzfi",
    imageSource: require("../../assets/images/modelImages/fzfi.png"),
  },
  {
    label: "R15",
    value: "r15",
    imageSource: require("../../assets/images/modelImages/r15.png"),
  },
  {
    label: "YZF R3",
    value: "yzfr3",
    imageSource: require("../../assets/images/modelImages/yzfr3.png"),
  },
  {
    label: "FAZER-25",
    value: "fazer25",
    imageSource: require("../../assets/images/modelImages/fazer25.png"),
  },
  {
    label: "FZ25",
    value: "fz25",
    imageSource: require("../../assets/images/modelImages/fz25.png"),
  },
  {
    label: "YZF R15 ver 2.0",
    value: "yzfr15ver2",
    imageSource: require("../../assets/images/modelImages/yzfr15ver2.png"),
  },
  {
    label: "FAZER-FI",
    value: "fazerfi",
    imageSource: require("../../assets/images/modelImages/fazerfi.png"),
  },
  {
    label: "SZ-RR VER 2.0",
    value: "szrr",
    imageSource: require("../../assets/images/modelImages/szrr.png"),
  },
  {
    label: "SALUTO 125",
    value: "saluto125",
    imageSource: require("../../assets/images/modelImages/saluto125.png"),
  },
  {
    label: "SALUTO RX",
    value: "salutorx",
    imageSource: require("../../assets/images/modelImages/salutorx.png"),
  },
  {
    label: "FZS-FI VINTAGE",
    value: "fzsfivintage",
    imageSource: require("../../assets/images/modelImages/fzsfivintage.png"),
  },
];

export const htmlData = {
  fzs25: {
    imageSource: require("../../assets/images/modelImages/fzs25.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">5000</td>
                                        <td class="text-center">10000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">60</td>
                                        <td class="text-center">180</td>
                                        <td class="text-center">360</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 5000 Kms Or 180 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  fzx: {
    imageSource: require("../../assets/images/modelImages/fzx.png"),
    html: `
    <div class="col-12 ">
        <div class="table-responsive">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr class="text-center">
                        <th class="table-light" scope="col">Type of Free Service</th>
                                            <th scope="col">1st</th>
                                            <th scope="col">2nd</th>
                                            <th scope="col">3rd</th>
                                            <th scope="col">4th</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                            <td class="text-center">1000</td>
                                            <td class="text-center">4000</td>
                                            <td class="text-center">7000</td>
                                            <td class="text-center">10000</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                            <td class="text-center">30</td>
                                            <td class="text-center">150</td>
                                            <td class="text-center">270</td>
                                            <td class="text-center">390</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Paid Service</td>
                                            <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 120 Days From The Previous Service, Whichever Is Earlier.</td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>`,
  },
  mt15ver2: {
    imageSource: require("../../assets/images/modelImages/mt15ver2.png"),
    html: `
    <div class="col-12 ">
        <div class="table-responsive">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr class="text-center">
                        <th class="table-light" scope="col">Type of Free Service</th>
                                            <th scope="col">1st</th>
                                            <th scope="col">2nd</th>
                                            <th scope="col">3rd</th>
                                            <th scope="col">4th</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                            <td class="text-center">1000</td>
                                            <td class="text-center">5000</td>
                                            <td class="text-center">9000</td>
                                            <td class="text-center">13000</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                            <td class="text-center">30</td>
                                            <td class="text-center">150</td>
                                            <td class="text-center">270</td>
                                            <td class="text-center">390</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Paid Service</td>
                                            <td colspan="6">Each Paid Service Needs To Be Availed After Every 4000 Kms Or 120 Days From The Previous Service, Whichever Is Earlier.</td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>`,
  },
  r15s: {
    imageSource: require("../../assets/images/modelImages/r15s.png"),
    html: `
    <div class="col-12 ">
        <div class="table-responsive">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr class="text-center">
                        <th class="table-light" scope="col">Type of Free Service</th>
                                            <th scope="col">1st</th>
                                            <th scope="col">2nd</th>
                                            <th scope="col">3rd</th>
                                            <th scope="col">4th</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                            <td class="text-center">1000</td>
                                            <td class="text-center">5000</td>
                                            <td class="text-center">9000</td>
                                            <td class="text-center">13000</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                            <td class="text-center">30</td>
                                            <td class="text-center">150</td>
                                            <td class="text-center">270</td>
                                            <td class="text-center">390</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Paid Service</td>
                                            <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 90 Days From The Previous Service, Whichever Is Earlier.</td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>`,
  },
  r15v4: {
    imageSource: require("../../assets/images/modelImages/r15v4.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">5000</td>
                                        <td class="text-center">9000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">30</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">270</td>
                                        <td class="text-center">390</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 4000 Kms Or 120 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  r15m: {
    imageSource: require("../../assets/images/modelImages/r15m.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">5000</td>
                                        <td class="text-center">9000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">30</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">270</td>
                                        <td class="text-center">390</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 4000 Kms Or 120 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  yzfr15: {
    imageSource: require("../../assets/images/modelImages/yzfr15.png"),
    html: `
    <div class="col-12 ">
        <div class="table-responsive">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr class="text-center">
                        <th class="table-light" scope="col">Type of Free Service</th>
                                            <th scope="col">1st</th>
                                            <th scope="col">2nd</th>
                                            <th scope="col">3rd</th>
                                            <th scope="col">4th</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                            <td class="text-center">1000</td>
                                            <td class="text-center">5000</td>
                                            <td class="text-center">9000</td>
                                            <td class="text-center">13000</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                            <td class="text-center">30</td>
                                            <td class="text-center">150</td>
                                            <td class="text-center">270</td>
                                            <td class="text-center">390</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Paid Service</td>
                                            <td colspan="6">Each Paid Service Needs To Be Availed After Every 4000 Kms Or 120 Days From The Previous Service, Whichever Is Earlier.</td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>`,
  },
  fzsfi: {
    imageSource: require("../../assets/images/modelImages/fzsfi.png"),
    html: `
    <div class="col-12 ">
        <div class="table-responsive">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr class="text-center">
                        <th class="table-light" scope="col">Type of Free Service</th>
                                            <th scope="col">1st</th>
                                            <th scope="col">2nd</th>
                                            <th scope="col">3rd</th>
                                            <th scope="col">4th</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                            <td class="text-center">1000</td>
                                            <td class="text-center">4000</td>
                                            <td class="text-center">7000</td>
                                            <td class="text-center">10000</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                            <td class="text-center">30</td>
                                            <td class="text-center">150</td>
                                            <td class="text-center">270</td>
                                            <td class="text-center">390</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Paid Service</td>
                                            <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 120 Days From The Previous Service, Whichever Is Earlier.</td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>`,
  },
  fzfi: {
    imageSource: require("../../assets/images/modelImages/fzfi.png"),
    html: `
    <div class="col-12 ">
        <div class="table-responsive">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr class="text-center">
                        <th class="table-light" scope="col">Type of Free Service</th>
                                            <th scope="col">1st</th>
                                            <th scope="col">2nd</th>
                                            <th scope="col">3rd</th>
                                            <th scope="col">4th</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                            <td class="text-center">1000</td>
                                            <td class="text-center">4000</td>
                                            <td class="text-center">7000</td>
                                            <td class="text-center">10000</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                            <td class="text-center">30</td>
                                            <td class="text-center">150</td>
                                            <td class="text-center">270</td>
                                            <td class="text-center">390</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Paid Service</td>
                                            <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 120 Days From The Previous Service, Whichever Is Earlier.</td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>`,
  },
  r15: {
    imageSource: require("../../assets/images/modelImages/r15.png"),
    html: `
    <div class="col-12 ">
        <div class="table-responsive">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr class="text-center">
                        <th class="table-light" scope="col">Type of Free Service</th>
                                            <th scope="col">1st</th>
                                            <th scope="col">2nd</th>
                                            <th scope="col">3rd</th>
                                            <th scope="col">4th</th>
                                            <th scope="col">5th</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                            <td class="text-center">1000</td>
                                            <td class="text-center">4000</td>
                                            <td class="text-center">7000</td>
                                            <td class="text-center">10000</td>
                                            <td class="text-center">13000</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                            <td class="text-center">30</td>
                                            <td class="text-center">120</td>
                                            <td class="text-center">210</td>
                                            <td class="text-center">300</td>
                                            <td class="text-center">390</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Paid Service</td>
                                            <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 90 Days From The Previous Service, Whichever Is Earlier.</td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>`,
  },
  yzfr3: {
    imageSource: require("../../assets/images/modelImages/yzfr3.png"),
    html: `
    <div class="col-12 ">
        <div class="table-responsive">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr class="text-center">
                        <th class="table-light" scope="col">Type of Free Service</th>
                                            <th scope="col">1st</th>
                                            <th scope="col">2nd</th>
                                            <th scope="col">3rd</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                            <td class="text-center">1000</td>
                                            <td class="text-center">5000</td>
                                            <td class="text-center">10000</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                            <td class="text-center">60</td>
                                            <td class="text-center">180</td>
                                            <td class="text-center">360</td>
                        
                    </tr>
                    <tr>
                        <td class="table-light" scope="row">Paid Service</td>
                                            <td colspan="6">Each Paid Service Needs To Be Availed After Every 5000 Kms Or 180 Days From The Previous Service, Whichever Is Earlier.</td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>`,
  },
  fazer25: {
    imageSource: require("../../assets/images/modelImages/fazer25.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">5000</td>
                                        <td class="text-center">10000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">60</td>
                                        <td class="text-center">180</td>
                                        <td class="text-center">360</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 5000 Kms Or 180 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  fz25: {
    imageSource: require("../../assets/images/modelImages/fz25.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">5000</td>
                                        <td class="text-center">10000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">60</td>
                                        <td class="text-center">180</td>
                                        <td class="text-center">360</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 5000 Kms Or 180 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  yzfr15ver2: {
    imageSource: require("../../assets/images/modelImages/yzfr15ver2.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                                        <th scope="col">6th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">3000</td>
                                        <td class="text-center">6000</td>
                                        <td class="text-center">9000</td>
                                        <td class="text-center">12000</td>
                                        <td class="text-center">15000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">30</td>
                                        <td class="text-center">100</td>
                                        <td class="text-center">170</td>
                                        <td class="text-center">240</td>
                                        <td class="text-center">310</td>
                                        <td class="text-center">380</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 70 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  fazerfi: {
    imageSource: require("../../assets/images/modelImages/fazerfi.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                                        <th scope="col">6th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">3000</td>
                                        <td class="text-center">6000</td>
                                        <td class="text-center">9000</td>
                                        <td class="text-center">12000</td>
                                        <td class="text-center">15000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">30</td>
                                        <td class="text-center">130</td>
                                        <td class="text-center">230</td>
                                        <td class="text-center">330</td>
                                        <td class="text-center">430</td>
                                        <td class="text-center">530</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 100 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  szrr: {
    imageSource: require("../../assets/images/modelImages/szrr.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                                        <th scope="col">6th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">3000</td>
                                        <td class="text-center">6000</td>
                                        <td class="text-center">9000</td>
                                        <td class="text-center">12000</td>
                                        <td class="text-center">15000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">30</td>
                                        <td class="text-center">130</td>
                                        <td class="text-center">230</td>
                                        <td class="text-center">330</td>
                                        <td class="text-center">430</td>
                                        <td class="text-center">530</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 90 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  saluto125: {
    imageSource: require("../../assets/images/modelImages/saluto125.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">8000</td>
                                        <td class="text-center">12000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">30</td>
                                        <td class="text-center">120</td>
                                        <td class="text-center">240</td>
                                        <td class="text-center">360</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 100 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  salutorx: {
    imageSource: require("../../assets/images/modelImages/salutorx.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">8000</td>
                                        <td class="text-center">12000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">30</td>
                                        <td class="text-center">120</td>
                                        <td class="text-center">240</td>
                                        <td class="text-center">360</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 100 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  fzsfivintage: {
    imageSource: require("../../assets/images/modelImages/fzsfivintage.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">7000</td>
                                        <td class="text-center">10000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">30</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">270</td>
                                        <td class="text-center">390</td>
                                        <td class="text-center">510</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 90 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  aerox: {
    imageSource: require("../../assets/images/modelImages/aerox.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">7000</td>
                                        <td class="text-center">10000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">30</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">270</td>
                                        <td class="text-center">390</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 120 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  fascino125fihybrid: {
    imageSource: require("../../assets/images/modelImages/fascino125fihybrid.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">7000</td>
                                        <td class="text-center">10000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">30</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">270</td>
                                        <td class="text-center">390</td>
                                        <td class="text-center">510</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 120 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  fascino125fi: {
    imageSource: require("../../assets/images/modelImages/fascino125fi.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">7000</td>
                                        <td class="text-center">10000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">60</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">240</td>
                                        <td class="text-center">330</td>
                                        <td class="text-center">420</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 90 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  rayzr125fihybrid: {
    imageSource: require("../../assets/images/modelImages/rayzr125fihybrid.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">7000</td>
                                        <td class="text-center">10000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">30</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">270</td>
                                        <td class="text-center">390</td>
                                        <td class="text-center">510</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 120 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  rayzr125fi: {
    imageSource: require("../../assets/images/modelImages/rayzr125fi.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">7000</td>
                                        <td class="text-center">10000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">60</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">240</td>
                                        <td class="text-center">330</td>
                                        <td class="text-center">420</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 90 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  rayzrstreetrallyhybrid: {
    imageSource: require("../../assets/images/modelImages/rayzrstreetrallyhybrid.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">7000</td>
                                        <td class="text-center">10000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">30</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">270</td>
                                        <td class="text-center">390</td>
                                        <td class="text-center">510</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 120 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  rayzrstreetrally125fi: {
    imageSource: require("../../assets/images/modelImages/rayzrstreetrally125fi.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">7000</td>
                                        <td class="text-center">10000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">60</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">240</td>
                                        <td class="text-center">330</td>
                                        <td class="text-center">420</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 90 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  alpha: {
    imageSource: require("../../assets/images/modelImages/alpha.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">7000</td>
                                        <td class="text-center">10000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">60</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">240</td>
                                        <td class="text-center">330</td>
                                        <td class="text-center">420</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 90 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  fascino: {
    imageSource: require("../../assets/images/modelImages/fascino.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">7000</td>
                                        <td class="text-center">10000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">60</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">240</td>
                                        <td class="text-center">330</td>
                                        <td class="text-center">420</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 90 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  rayz: {
    imageSource: require("../../assets/images/modelImages/rayz.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">7000</td>
                                        <td class="text-center">10000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">60</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">240</td>
                                        <td class="text-center">330</td>
                                        <td class="text-center">420</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 90 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
  rayzr: {
    imageSource: require("../../assets/images/modelImages/rayzr.png"),
    html: `<div class="col-12 ">
    <div class="table-responsive">
        <table class="table table-sm table-bordered">
            <thead>
                <tr class="text-center">
                    <th class="table-light" scope="col">Type of Free Service</th>
                                        <th scope="col">1st</th>
                                        <th scope="col">2nd</th>
                                        <th scope="col">3rd</th>
                                        <th scope="col">4th</th>
                                        <th scope="col">5th</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-light" scope="row">Validity in Kms from the Date of Purchase</td>
                                        <td class="text-center">1000</td>
                                        <td class="text-center">4000</td>
                                        <td class="text-center">7000</td>
                                        <td class="text-center">10000</td>
                                        <td class="text-center">13000</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Validity in Days from the Date of Purchase</td>
                                        <td class="text-center">60</td>
                                        <td class="text-center">150</td>
                                        <td class="text-center">240</td>
                                        <td class="text-center">330</td>
                                        <td class="text-center">420</td>
                    
                </tr>
                <tr>
                    <td class="table-light" scope="row">Paid Service</td>
                                        <td colspan="6">Each Paid Service Needs To Be Availed After Every 3000 Kms Or 90 Days From The Previous Service, Whichever Is Earlier.</td>
                    
                </tr>
            </tbody>
        </table>
    </div>
</div>`,
  },
};

export const reviews = [
  {
    image: require("../../assets/images/award1.jpeg"),
    name: "Devendra Kumar",
    review: "Accurate Paper work for New Car and Best Services Offered.",
  },
  {
    image: require("../../assets/images/award1.jpeg"),
    name: "Vinay Shah",
    review:
      "Very fast Procedure, Will Definitely Recommend to my Friends. Glad to have very good Service at Bonny Auto World",
  },
  {
    image: require("../../assets/images/award1.jpeg"),
    name: "Kishore Mehta",
    review:
      "Timely Test Drive, Accurate Answers to Questions, Very responsive towards the actions",
  },
  {
    image: require("../../assets/images/award1.jpeg"),
    name: "Shruti Kanabar",
    review:
      "I’m Impressed ,  You kept your word , Insurance Process completed in less than 4 minutes for my Car, Much Appreciated !!!",
  },
  {
    image: require("../../assets/images/award1.jpeg"),
    name: "Nayan Vora",
    review:
      "Amazing Customer Service!!! .  Prompt response to all the questions asked and no waiting time was there for renewal of insurance of my Bike. Thank you Bonny Auto World.",
  },
  {
    image: require("../../assets/images/award1.jpeg"),
    name: "Krishna Nandan Kumar",
    review:
      "It was great Experience, NO commission, No Waiting, some clicks and almost done. It is very transparent.",
  },
  {
    image: require("../../assets/images/award1.jpeg"),
    name: "Vinayak Singh",
    review:
      "Test Drive was smooth of the Bike, Very good Collections for Yamaha Bikes and Scooters. Great Service although.",
  },
];

export const awards = [
  require("../../assets/images/award1.jpeg"),
  require("../../assets/images/award2.jpeg"),
  require("../../assets/images/award3.jpeg"),
  require("../../assets/images/award4.jpeg"),
];

export const advantages = [
  "In the circumstances of an unfortunate event such as the accidental death of a borrower, sudden unemployment or disability, the personal loan insurance helps the borrower make monthly loan payments for a few months and decrease the outstanding loan amount.",
  "During the above-mentioned unfortunate event, with the loan protection plan in place, the family of the borrower is not burdened to repay the loan amount immediately. It provides a buffer period for both the borrower and the family to recover from the unforeseen event.",
  "Some loan insurance protection plans provide tax benefits under Section 80C.",
  "Some loan insurance plans have a money-back offer, wherein the policyholder receives a certain amount of money at the end of the tenure period.",
];

export const faqs = [
  {
    question: "We are oldest name in loans and insurance provider in morbi ?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    question: "We are oldest name in loans and insurance provider in morbi ?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    question: "We are oldest name in loans and insurance provider in morbi ?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    question: "We are oldest name in loans and insurance provider in morbi ?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
];

export const nonMotorInsuranceTypes = [
  {
    title: "Workmen Compensation",
    type: "WORKMEN_COMPENSATION",
    key: "Workmen Compensation",
    description:
      "Workers' compensation insurance, also known as workers' comp, is a type of insurance that protects employees who are injured on the job. All businesses in India are required to have this insurance. The Employee/Worker Compensation Policy assists employers in financially compensating employees who are injured or killed as a result of an accident that occurs while they are working. Worker compensation covers employee injuries, disabilities, and deaths caused by workplace accidents.",
    whyInsurance: [
      {
        description:
          "Employers are required by law to take reasonable precautions to ensure worker safety. Nevertheless, mishaps do occur. Workers compensation insurance offers protection if this occurs.",
      },
      {
        description:
          "Workers compensation insurance has two functions: it ensures that injured employees receive medical attention and reimbursement for a portion of the income they lose while they are unable to return to work, and it typically shields businesses from lawsuits brought by employees hurt at work.",
      },
      {
        description:
          "Whether or not the accident was the fault of the worker, they are entitled to payments. Workers compensation, or 'workers comp,' offers death benefits for the worker's dependents in the event that they pass away while on the job.",
      },
    ],
  },
  {
    title: "Fire Insurance",
    type: "FIRE_INSURANCE",
    key: "Fire Insurance/Import or Export Marine",
    description:
      "fire insurance, provision against losses caused by fire, lightning, and the removal of property from premises endangered by fire. The insurer agrees, for a fee, to reimburse the insured in the event of such an occurrence.",
    whyInsurance: [
      {
        title:
          "A fire insurance policy provides additional coverage for structures damaged or destroyed in a fire.",
      },
      {
        title:
          "Fire insurance may be capped at a rate that is less than the cost of the losses accrued, necessitating a separate fire insurance policy.",
      },
      {
        title:
          "The policy pays the policyholder back on either a replacement-cost basis or an actual cash-value basis for damages.",
      },
      {
        title:
          "Although some homeowners insurance policies include fire coverage, they may not be extensive enough for some homeowners.",
      },
    ],
  },
  {
    title: "Domestic Marine ",
    type: "DOMESTIC_MARINE",
    key: "Domestic Marine",
    description:
      "Marine insurance covers the loss or damage of ships, cargo, terminals, and any transport by which the property is transferred, acquired, or held between the points of origin and the final destination.",
    whyInsurance: [
      {
        title: "Goods are Venerable to Theft",
        description:
          "There is a chance that well-coordinated robbers will notice you are shipping valuable products by road. Unfortunately, this could happen not just in rural areas but even in cities, where your shipment is scheduled to travel. Or it might occur when the truck driver is sleeping at a truck parking facility. Usually, expensive commodities are the objective, although thieves will sometimes be content to steal less expensive items like food supplies. The loss may be recovered through marine insurance.",
      },

      {
        title: "Damage or Loss in the Sea",
        description:
          "Regardless of how carefully you have planned for your shipment to travel by water, the truck or vessel is still subject to the weather. Even while container ships may grow larger and more dependable, sudden, extreme weather conditions might still result in the loss of containers.",
      },
      {
        title: "Costly Items Produce Greater Losses",
        description:
          "To maintain the security of your priceless possessions, you must get marine insurance. The carriers used to deliver the merchandise are only partially liable. You can insure the things up to a set amount above the insurer's invoice value, depending on your preferred insurance provider. In the event of a loss, the insurer will make a payment in accordance with the terms and premium. Caro marine insurance may also cover (part of) duties, taxes, and additional delivery expenses in addition to the invoice value.",
      },
      {
        title: "Fast and Smooth Claim Settlement",
        description:
          "In simple words, settling a marine insurance claim takes less time compared to a liability claim. This clause is valid when the cause of loss or damage is uncertain or the claim is registered for a significant amount and requires investigation. For instance, if the claimant may be assumed the loss due to an event which the carrier can't be held liable for, the carrier can oppose it. In such a case, the claim has to be proved by the claimant.",
      },
    ],
  },
  {
    title: "Import or Export Marine",
    type: "IMPORT_EXPORT_MARINES",
    key: "Fire Insurance/Import or Export Marine",
    description:
      "Cargo insurance is the sub-branch of marine insurance, though Marine insurance also includes Onshore and Offshore exposed property, (container terminals, ports, oil platforms, pipelines), Hull, Marine Casualty, and Marine Liability. When goods are transported by mail or courier, shipping insurance is used instead.",
    whyInsurance: [
      {
        title: "Open Cover Cargo Insurance Policy:",
        description:
          "This kind of policy is issued for up to 12 months and is suitable for both exported and imported goods.",
      },
      {
        title: "Specific Voyage Policy:",
        description:
          "Usually issued before the transit begins, this cover is suitable for single transit.",
      },
      {
        title: "Annual Policy:",
        description:
          "These provide coverage to goods transferred through the sea, rail, and road through depots hired or owned by the policyholder. This marine cargo insurance can't be transferred.",
      },
      {
        title: "Open Policy Cargo Insurance Policy:",
        description:
          "This is issued for 12 months and only covers the goods in transit within India.\n\nDepending on your cargo, the number of times the cargo needs to be shipped, and the duration your items need to be protected,you can buy marine insurance online according to your needs without any hassle.",
      },
    ],
  },
  {
    title: "Health Insurance",
    type: "HEALTH_INSURANCE",
    description:
      "Health insurance is a type of insurance that pays for medical expenses incurred as a result of an illness. Some people also opt for the Mediclaim policy, which only provides compensation up to a certain amount. However, if you need coverage for doctor's fees, medication, diagnostic tests, and hospitalisation, health insurance may be a better option",
    whyInsurance: [
      {
        description:
          "A  health insurance coverage should be in place for a variety of reasons, including a changing lifestyle. Our lifestyle's tectonic change has increased our susceptibility to a variety of illnesses. The likelihood of acquiring health issues has increased as a result of commuting, stressful work schedules, poor eating habits, food quality, and rising pollution levels.",
      },
      {
        description:
          "Increasing medical expenses: Recently, medical expenses have increased significantly. Therefore, consumers wind up using their savings in the event of a medical emergency, which has an impact on their future plans. According to reports, Indians generally rely on their personal money to cover medical crises.",
      },
      {
        description:
          "Tax benefit: Section 80D of the Indian Income Tax Act allows for tax deductions for payments made for health insurance premiums. Up to 25,000 in deductions are available to anyone under the age of 60 for health insurance premiums they've paid for themselves, their spouse, or their kids. If you get health insurance for your parents who are 60 years of age or older, you can additionally deduct an additional 50,000 from your income.\n\nThis deduction will be available for contributions made for an annual health insurance premium, a senior citizen's preventive health checkup, or medical expenses related to any senior citizen.\n\nTherefore, instead of the previous 55,000 (Rs. 25,000 + 30,000), you can now receive a total deduction of up to 75,000 (Rs. 25,000 + 50,000) if you are paying the health insurance payments for your elderly parents.",
      },
      {
        description:
          "Pre- and post-hospitalization charges are covered. As the cost of diagnostic tests and OPD (out-patient departments) fees have increased recently, it is now more important than ever to purchase a health insurance coverage. It is important that medical insurance covers not only hospitalisation charges but also OPD and diagnostic test fees before and after a predetermined time period as specified by the policy.",
      },
      {
        description:
          "Additional advantages: Health insurance also provides advantages including ambulance coverage, coverage for day care surgeries, coverage for health check-up and vaccine costs, and more. Health insurance for Ayush therapy is now provided by insurance firms.",
      },
    ],
  },
  {
    title: "Travel Insurance",
    type: "TRAVEL_INSURANCE",
    description:
      "Travel insurance is a sort of insurance that protects against a variety of dangers. It covers any damages a traveller can suffer while on the road, including medical costs, lost luggage, cancelled flights, and other misfortunes.\n\nTravel insurance is often purchased from the day of departure until the traveller returns to India. Purchasing travel insurance guarantees complete coverage in the event of an emergency abroad. Although Bharat Bhraman & E Travel and other companies offer travel insurance for domestic travels as well, it is a more common choice for international travel.\n\nSome of the risks covered under Travel Insurance are:",
    whyInsurance: [
      {
        title: " Personal Accident Cover, which covers:",
      },
      {
        title: "Insured’s Death",
      },
      {
        title: "Permanent Total Disability",
      },
      {
        title: "Accident & sickness medical expense reimbursement",
      },
      {
        title: "Dental treatment relief",
      },
      {
        title: "Emergency evacuation",
      },
      {
        title: "Repatriation of remains in case of death",
      },
      {
        title: "Baggage delay",
      },
      {
        title: "Loss of checked baggage",
      },
      {
        title: " Loss of passport",
      },
      {
        title: "Flight delay",
      },
      {
        title: "Hijack",
      },
      {
        title: "Home burglary",
      },
      {
        title: "Trip curtailment",
      },
      {
        title: "Trip cancellation",
      },
      {
        title: "Missed connection/missed departure",
      },
      {
        title: "Bounced hotel/airline booking",
      },
      {
        title:
          "There may be more risks covered under individual policies depending on the requirements of the traveller. The amounts to which these risks are covered depends on the policy & plan that the traveller opts for. Travel Insurance also covers additional risks at an additional premium. Some of these risks are specific to the traveller or the place the person is travelling to.",
      },
    ],
  },
  {
    title: "Other Commercial Insurance",
    type: "OTHER",
  },
];

export const vehicleLoanItems = [
  {
    title: "Two Wheeler Loan",
    type: "TWO_WHEELER_LOAN",
    description:
      "A two-wheeler is a great vehicle for navigating traffic, especially in a city with plenty of people. It is fuel-efficient, small, and enables comfortable short-distance travel. With Two-Wheeler Loans, you can buy geared motorbikes and non-geared scooters. Most lenders provide Two-Wheeler Loans with up to 100% financing and a five-year maximum repayment period. Such loans can be financed up to a limit of Rs 10 lakh. Once more, in order to be accepted for this loan, you must present documentation of your income and creditworthiness.",
  },
  {
    title: "Four Wheeler Loan",
    type: "FOUR_WHEELER_LOAN",
    description:
      "You can get a Car Loan to purchase a brand-new car of your preferred brand. Lenders generally offer up to 90% financing of the car's on-road price, while you have to pay the remaining 10% as a down payment. The on-road price of a new car includes the ex-showroom price, Regional Transport Office (RTO) registration charges, insurance costs, road tax, etc. A Car Loan generally comes with a seven-year repayment tenure. You can repay the Car Loans in affordable EMIs over your chosen tenure.",
  },
];

export const freeAndPaidServices = [
  {
    title: "Expert Technicians",
    points: ["Trained Technicians", "Lady Supervisor"],
  },
  {
    title: "Latest Facility",
    points: ["Fuel Injection Stations"],
  },
  {
    title: "Unique Program",
    points: ["Time Commitment Service", "Lady Supervisor"],
  },
  {
    title: "Add-on Benefits",
    points: [
      "Road Side Assistance",
      "Pick and Drop Service",
      "Cashless Insurance",
      "AMC - Annual Maintenance Contract",
      "Complete peace of mind with 5 years of Protection Plus policy",
    ],
  },
];

export const amcIncludes = [
  " The name and address of the involved parties, usually a business and the company providing the service",
  "Price of the contract",
  "Annual maintenance contract terms and conditions",
  "Penalties for breaking contract or contract termination",
  " Expectations for how frequently your techs will need to service products",
  "Definition of whether the deal is a comprehensive maintenance contract (CMC) or otherwise",
  "List of the equipment covered for service",
  "Timeline for how long service will last and how long it will typically take",
  "A spot for both parties’ signatures and the date",
];

export const serviceProviderAmcBenifits = [
  {
    title: "Easier annual planning",
    description:
      "Drawing up AMCs makes your annual planning and budgeting easier. Both customers and service providers can use cost estimates from AMCs to calculate service requirements, estimated downtime, hours of operation, and much more. Customers also benefit from eliminating unexpected maintenance and repair costs, and the service provider has a picture of what types of jobs they can expect.",
  },
  {
    title: "Informed scheduling",
    description:
      "Create a  deeper understanding of your scheduling and hiring needs. With AMCs, you get an overview of your year, and can have a better handle on how many techs you’ll need, how much support backup your contracts will require, and be able to pre-arrange your technicians’ schedules.",
  },
  {
    title: "Deeper client knowledge",
    description:
      "Service providers gain a deeper understanding of their client’s ongoing support requirements. Companies build better, longer-lasting relationships and create better contracts when service techs know how often equipment downtimes occur, how many visits they have to do on average, and what kind of needs the client has.",
  },
];

export const customerAmcBenifits = [
  {
    title: "Higher Quality of work",
    description:
      "Agreeing  upon an annual maintenance contract allows clients to ensure the quality of work is standardized across that time period and ensure the service provider adheres to the terms agreed upon.",
  },
  {
    title: "Efficient & planned timelines",
    description:
      "Customers will benefit from knowing that they always have emergency support and their needs covered. They’ll never need to worry about how they will get something repaired or when it will happen.",
  },
  {
    title: "Regular maintenance plans",
    description:
      "Both service providers and customers benefit from having regular maintenance planned for ahead of time. Well-maintained things break less frequently, resulting in less work for your techs and less downtime for clients.",
  },
];
