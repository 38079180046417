import React, { useRef } from "react";
import { Typography } from "@mui/material";
import BaseLoader from "../../common/components/loader";
import CustomAlert from "../../common/components/customAlert";
import Carousel from "react-material-ui-carousel";
import { makeStyles } from "@mui/styles";
import { backgroundGray, primaryDarkBlue, white } from "../../common/colors";
import Marquee from "react-fast-marquee";
import CarouselItem from "../bonnyAutoHome/components/caroselItem";
import CustomerReviews from "../../common/components/customerReviews";
import AwardsAndRecognition from "../../common/components/awardsRecognitions";
import Faq from "../../common/components/faq";
import ContactUs from "../../common/components/contactUs";
import OfferItem from "../bonnyAutoHome/components/offerItem";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { scooterTypes } from "../../common/utils/constants";
import ScrollToTop from "../../common/components/scrollToTop";
import ModalForms from "./components/modalForms";
import CustomModal from "../../common/components/customModal";
import Video from "../../assets/images/Yamaha_video.mp4";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: "70vh",
      width: "70vw",
      paddingBlock: "10vh",
    },
    insuranceCompanyLogoContainer: {
      backgroundColor: "white",
      paddingTop: "24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        paddingTop: "56px",
      },
    },
    insuranceCompanyLogo: {
      objectFit: "none",
    },
    contentBackground: {
      backgroundColor: backgroundGray,
    },
    banner: {
      width: "100vw",
      height: "50vh",
      objectFit: "fill",
      [theme.breakpoints.up("md")]: {
        height: "80vh",
      },
    },
    insuranceCompanyLogoSeparator: {
      width: "50%",
      alignSelf: "center",
      marginBottom: "32px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "64px",
      },
    },
    offerSubContainer: {
      backgroundImage:
        "linear-gradient(to bottom right, rgba(255,255,255,0.4), rgba(255,255,255,0.4))",
      backdropFilter: "blur(10px)",
      padding: "18px 24px",
      borderRadius: "16px",
      marginTop: "20px",
      width: "70%",
      overflow: "scroll",
      [theme.breakpoints.up("md")]: {
        padding: "36px 44px",
        borderRadius: "30px",
        marginTop: "40px",
        width: "45%",
        overflow: "hidden",
      },
    },
    width25: {
      width: "50vw",
      [theme.breakpoints.up("md")]: {
        width: "25vw",
      },
    },
    img: {
      objectFit: "fill",
      width: "200px",
      minHeight: "200px",
    },
    middleImg: {
      objectFit: "fill",
      width: "250px",
      height: "250px",
      minHeight: "250px",
    },
    imgContainer: {
      maxWidth: "33vw",
    },
    carouselItem: {
      minWidth: "33vw",
      maxWidth: "33vw",
      minHeight: "30vh",
      maxHeight: "30vh",
      cursor: "pointer",
    },
    carouselImage: {
      maxWidth: "100vw",
      maxHeight: "24vh",
      display: "flex",
      [theme.breakpoints.up("md")]: {
        maxWidth: "25vw",
        maxHeight: "26vh",
      },
    },
    caroselContainer: {
      minHeight: "35vh",
      maxHeight: "35vh",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    bigCaroselImage: {
      maxWidth: "100vw",
      maxHeight: "26vh",
      display: "flex",
      [theme.breakpoints.up("md")]: {
        maxWidth: "25vw",
        maxHeight: "26vh",
      },
    },
    arrowForward: {
      position: "absolute",
      top: "50%",
      right: "24px",
      fontSize: "50px",
      cursor: "pointer",
    },
    arrowBack: {
      position: "absolute",
      top: "50%",
      left: "24px",
      fontSize: "50px",
      cursor: "pointer",
    },
    caroselItemTitle: {
      fontSize: "16px",
      color: primaryDarkBlue,
      marginTop: "16px",
    },
  };
});

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 70,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 0,
  },
};

const BonnyYamahaHomePageView = ({
  bannerImages,
  apiError,
  setApiError,
  modalForms,
  bannerBottomImages,
  userDetails,
  reviews,
  awards,
  offerItemImages,
  bikeTypes,
  bikesCaroselRef,
  scooterCaroselRef,
  navigateToServices,
  _showModal,
  modalType,
  _onSubmitClick = () => {},
  isLoading,
  city,
  setCity,
  cityError,
  name,
  setName,
  nameError,
  mobileNumber,
  setMobileNumber,
  numberError,
  email,
  setEmail,
  emailError,
  state,
  setState,
  stateError,
  mobileBannerImages,
}) => {
  const classes = useStyles();
  if (
    (userDetails && bannerImages && bannerBottomImages && mobileBannerImages) ||
    (!userDetails && bannerImages && bannerBottomImages && mobileBannerImages)
  ) {
    return (
      <div>
        <ScrollToTop />
        <CustomAlert
          isShow={apiError}
          message={apiError}
          onClose={() => setApiError(null)}
        />
        <CustomModal
          showModal={modalType ? true : false}
          isSuccess={modalType === "SUCCESS"}
          hideModal={() => _showModal(null)}
          positiveButtonCallBack={() => {
            if (modalType === "SUCCESS") {
              _showModal(null);
            } else {
              _onSubmitClick();
            }
          }}
          negativeButtonCallBack={() => _showModal(null)}
          positiveButtonText={modalType === "SUCCESS" ? "Okay" : "Submit"}
          title={""}
          isPositiveButtonLoading={isLoading}
        >
          <ModalForms
            modalType={modalType}
            city={city}
            setCity={setCity}
            cityError={cityError}
            name={name}
            setName={setName}
            nameError={nameError}
            mobileNumber={mobileNumber}
            setMobileNumber={setMobileNumber}
            numberError={numberError}
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            state={state}
            setState={setState}
            stateError={stateError}
          />
        </CustomModal>
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          className={classes.banner}
        >
          <source src={Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="offerContainer">
          <div className={`d-flex flex-column align-items-center offers py-5`}>
            <Typography variant="h1" className="text-center">
              What We Offer
            </Typography>
            <div
              className={`d-flex flex-row align-items-center ${classes.offerSubContainer}`}
            >
              {modalForms.map((item, index) => {
                return (
                  <OfferItem
                    title={item.title}
                    description={item.desc}
                    imageSrc={item.imageSource}
                    linkTo={item.linkTo}
                    isFromHomePage={true}
                    offerItemImage={
                      offerItemImages[index % offerItemImages.length]
                    }
                    index={index}
                    onClick={_showModal}
                    showCursor={true}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {/* bikes carosel */}
        <div className="bikeCarosel py-5">
          <div className="bikeCaroselContent">
            <Typography variant="h1" className="text-center mb-5">
              Motorcycles
            </Typography>
            <MultiCarousel
              ssr
              itemClass={classes.caroselContainer}
              infinite={true}
              responsive={responsive}
              containerClass="w-100"
              focusOnSelect={true}
              ref={bikesCaroselRef}
            >
              {bikeTypes.map((bikeType, index) => {
                return (
                  <div
                    className={classes.caroselContainer}
                    onClick={() => navigateToServices("bikes", bikeType)}
                  >
                    <img
                      className={`mx-auto ${
                        index % 2 === 0
                          ? classes.bigCaroselImage
                          : classes.carouselImage
                      }`}
                      style={
                        index % 2 === 0
                          ? { maxHeight: "26vh" }
                          : { maxHeight: "24vh", marginTop: "1vh" }
                      }
                      src={bikeType.imageSource}
                    />

                    <span className={classes.caroselItemTitle}>
                      {bikeType.label}
                    </span>
                  </div>
                );
              })}
            </MultiCarousel>
          </div>
        </div>

        {/* scrooter carosel */}
        <div className="scooterCarosel py-5">
          <div className="scooterCaroselContent">
            <Typography variant="h1" className="text-center mb-5">
              Scooters
            </Typography>
            <MultiCarousel
              ssr
              itemClass={classes.caroselContainer}
              infinite={true}
              responsive={responsive}
              containerClass="w-100"
              focusOnSelect={true}
              ref={scooterCaroselRef}
            >
              {scooterTypes.map((bikeType, index) => {
                return (
                  <div
                    className={classes.caroselContainer}
                    onClick={() => navigateToServices("scooter", bikeType)}
                  >
                    <img
                      className={`mx-auto ${
                        index % 2 === 0
                          ? classes.bigCaroselImage
                          : classes.carouselImage
                      }`}
                      style={
                        index % 2 === 0
                          ? { maxHeight: "26vh" }
                          : { maxHeight: "24vh", marginTop: "1vh" }
                      }
                      src={bikeType.imageSource}
                    />
                    <span className={classes.caroselItemTitle}>
                      {bikeType.label}
                    </span>
                  </div>
                );
              })}
            </MultiCarousel>
          </div>
        </div>

        <div className="pt-5 d-md-block d-none">
          <div className="container my-5">
            <Carousel
              ssr
              partialVisbile
              itemClass="image-item"
              responsive={responsive}
            >
              {bannerBottomImages.map((item) => {
                return <CarouselItem images={item} />;
              })}
            </Carousel>
          </div>
        </div>

        <div className="pt-5 d-md-none d-block">
          <div className="container my-5">
            <Carousel
              ssr
              partialVisbile
              itemClass="image-item"
              responsive={responsive}
            >
              {mobileBannerImages.map((item) => {
                return <CarouselItem images={[item]} />;
              })}
            </Carousel>
          </div>
        </div>

        <CustomerReviews reviews={reviews} />

        <AwardsAndRecognition awards={awards} />

        {/* <Faq faqs={faqs} /> */}

        <ContactUs />
      </div>
    );
  } else {
    return <BaseLoader />;
  }
};

export default BonnyYamahaHomePageView;
