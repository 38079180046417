import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  addHealthInsurance,
  addMotorInsurance,
  addNonMotorInsurance,
  addOtherCommercialInsurance,
  addTravelInsurance,
} from "../../common/services/services";
import {
  carInsuranceTypes,
  emailRegex,
  insuranceCompanies,
  insureTypes,
  STATUS,
} from "../../common/utils/constants";
import {
  fetchInsurances,
  selectInsurance,
} from "../../redux/slices/insuranceSlice";
import { selectUserDetails } from "../../redux/slices/userDetailsSlice";
import InsurancePageView from "./insurancePageView";

const insuranceTypes = [
  {
    title: "Workmen Compensation",
    type: "WORKMEN_COMPENSATION",
    key: "Workmen Compensation",
  },
  {
    title: "Fire Insurance",
    type: "FIRE_INSURANCE",
    key: "Fire Insurance/Import or Export Marine",
  },
  {
    title: "Domestic Marine ",
    type: "DOMESTIC_MARINE",
    key: "Domestic Marine",
  },
  {
    title: "Import or Export Marine",
    type: "IMPORT_EXPORT_MARINES",
    key: "Fire Insurance/Import or Export Marine",
  },
  {
    title: "Health Insurance",
    type: "HEALTH_INSURANCE",
  },
  {
    title: "Travel Insurance",
    type: "TRAVEL_INSURANCE",
  },
  {
    title: "Other Commercial Insurance",
    type: "OTHER",
  },
];

const InsurancePageContainer = () => {
  const insuranceObserver = useSelector(selectInsurance);
  const insurances = insuranceObserver.data;
  const dispatch = useDispatch();
  const { state } = useLocation();

  console.log(state)

  const userDetailsObserver = useSelector(selectUserDetails);
  const userDetails = userDetailsObserver.userDetails;

  const [modalType, setModalType] = useState(null);
  const [insuraceCompany, setInsuraceCompany] = useState(insuranceCompanies);
  const [insure, setInsure] = useState(insureTypes[0].value);
  const [companyName, setCompanyName] = useState(null);
  const [companyNameError, setCompanyNameError] = useState(null);
  const [city, setCity] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [userState, setUserState] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [age, setAge] = useState(null);
  const [ageError, setAgeError] = useState(null);
  const [carNumber, setCarNumber] = useState(null);
  const [carNumberError, setCarNumberError] = useState(null);
  const [carInsuranceType, setCarInsuranceType] = useState(
    carInsuranceTypes[0].value
  );
  const [name, setName] = useState(userDetails?.name);
  const [nameError, setNameError] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(userDetails?.mobile_no);
  const [numberError, setNumberError] = useState(null);
  const [email, setEmail] = useState(userDetails?.email);
  const [emailError, setEmailError] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      insuranceObserver.status !== STATUS.SUCCESS ||
      insuranceObserver.status !== STATUS.LOADING
    ) {
      dispatch(fetchInsurances());
    }
  }, []);

  const _showModal = (type) => {
    setModalType(type);
  };

  const _onSubmitClick = () => {
    if (
      (state.type && state.type.type === "HEALTH_INSURANCE") ||
      (state.type && state.type.type === "TRAVEL_INSURANCE")
    ) {
      if (!name || name.trim() === "") {
        setNameError("Enter name");
        return;
      } else {
        setNameError(null);
      }

      if (!email || email.trim() === "") {
        setEmailError("Enter email");
        return;
      } else if (!emailRegex.test(email)) {
        setEmailError("Enter valid email");
        return;
      } else {
        setEmailError(null);
      }

      if (!mobileNumber || mobileNumber.trim() === "") {
        setNumberError("Enter name");
        return;
      } else if (mobileNumber.length !== 10) {
        setNumberError("Enter valid number");
        return;
      } else {
        setNumberError(null);
      }

      if (!city || city.trim() === "") {
        setCityError("Enter city");
        return;
      } else {
        setCityError(null);
      }

      if (!userState || userState.trim() === "") {
        setStateError("Enter userState");
        return;
      } else {
        setStateError(null);
      }

      if (state.type && state.type.type === "HEALTH_INSURANCE") {
        if (!age || age.trim() === "") {
          setAgeError("Enter age");
          return;
        } else {
          setAgeError(null);
        }
      }

      setIsLoading(true);
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile_number", mobileNumber);
      formData.append("city", city);
      formData.append("state", userState);
      formData.append("insurance_id", insuraceCompany);
      formData.append("age", age);
      if (state.type && state.type.type === "HEALTH_INSURANCE") {
        formData.append("whom", insure);
      }

      if (state.type && state.type.type === "HEALTH_INSURANCE") {
        addHealthInsurance(formData)
          .then((response) => {
            setIsLoading(false);
            setModalType("SUCCESS");
          })
          .catch((error) => {
            setIsLoading(false);
            setApiError(error.message);
          });
      } else {
        addTravelInsurance(formData)
          .then((response) => {
            setIsLoading(false);
            setModalType("SUCCESS");
          })
          .catch((error) => {
            setIsLoading(false);
            setApiError(error.message);
          });
      }
    } else {
      if (!name || name.trim() === "") {
        setNameError("Enter name");
        return;
      } else {
        setNameError(null);
      }

      if (!email || email.trim() === "") {
        setEmailError("Enter email");
        return;
      } else if (!emailRegex.test(email)) {
        setEmailError("Enter valid email");
        return;
      } else {
        setEmailError(null);
      }

      if (!mobileNumber || mobileNumber.trim() === "") {
        setNumberError("Enter name");
        return;
      } else if (mobileNumber.length !== 10) {
        setNumberError("Enter valid number");
        return;
      } else {
        setNumberError(null);
      }

      if (!city || city.trim() === "") {
        setCityError("Enter city");
        return;
      } else {
        setCityError(null);
      }

      if (!userState || userState.trim() === "") {
        setStateError("Enter userState");
        return;
      } else {
        setStateError(null);
      }

      if (!companyName || companyName.trim() === "") {
        setCompanyNameError("Enter company name");
        return;
      } else {
        setCompanyNameError(null);
      }

      setIsLoading(true);
      if (
        state.type &&
        (state.type.type === "WORKMEN_COMPENSATION" ||
          state.type.type === "FIRE_INSURANCE" ||
          state.type.type === "DOMESTIC_MARINE" ||
          state.type.type === "IMPORT_EXPORT_MARINES")
      ) {
        let formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("mobile_number", mobileNumber);
        formData.append("city", city);
        formData.append("userState", userState);
        formData.append("insurance_id", insuraceCompany);
        formData.append("type", state);
        formData.append('state', userState);
        formData.append("company_name", companyName);

        addNonMotorInsurance(formData)
          .then((response) => {
            setIsLoading(false);
            setModalType("SUCCESS");
          })
          .catch((error) => {
            setIsLoading(false);
            setApiError(error.message);
          });
      } else {
        let formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("mobile_number", mobileNumber);
        formData.append("city", city);
        formData.append('state', userState);
        formData.append("insurance_id", insuraceCompany);
        formData.append("organization", companyName);

        addOtherCommercialInsurance(formData)
          .then((response) => {
            setIsLoading(false);
            setModalType("SUCCESS");
          })
          .catch((error) => {
            setIsLoading(false);
            setApiError(error.message);
          });
      }
    }
  };

  const _onSubmitMotorInsurance = () => {
    if (!name || name.trim() === "") {
      setNameError("Enter name");
      return;
    } else {
      setNameError(null);
    }

    if (!email || email.trim() === "") {
      setEmailError("Enter email");
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter valid email");
      return;
    } else {
      setEmailError(null);
    }

    if (!mobileNumber || mobileNumber.trim() === "") {
      setNumberError("Enter name");
      return;
    } else if (mobileNumber.length !== 10) {
      setNumberError("Enter valid number");
      return;
    } else {
      setNumberError(null);
    }

    if (!city || city.trim() === "") {
      setCityError("Enter city");
      return;
    } else {
      setCityError(null);
    }

    if (!userState || userState.trim() === "") {
      setStateError("Enter userState");
      return;
    } else {
      setStateError(null);
    }

    if (!carNumber || carNumber.trim() === "") {
      setCarNumberError("Enter car number");
      return;
    } else {
      setCarNumberError(null);
    }

    let formData = new FormData();
    formData.append("car_number", carNumber);
    formData.append("type_of_insurance", carInsuranceType);
    formData.append("name", name);
    formData.append("mobile_number", mobileNumber);
    formData.append("email", email);
    formData.append("city", city);
    formData.append("userState", userState);
    formData.append("insurance_id", insuraceCompany);

    addMotorInsurance(formData)
      .then((response) => {
        setIsLoading(false);
        setModalType("SUCCESS");
      })
      .catch((error) => {
        setIsLoading(false);
        setApiError(error.message);
      });
  };

  return (
    <InsurancePageView
      insuranceTypes={insuranceTypes}
      modalType={modalType}
      _showModal={_showModal}
      insuraceCompany={insuraceCompany}
      setInsuraceCompany={setInsuraceCompany}
      companyName={companyName}
      setCompanyName={setCompanyName}
      companyNameError={companyNameError}
      name={name}
      setName={setName}
      nameError={nameError}
      mobileNumber={mobileNumber}
      setMobileNumber={setMobileNumber}
      numberError={numberError}
      email={email}
      setEmail={setEmail}
      emailError={emailError}
      city={city}
      setCity={setCity}
      cityError={cityError}
      userState={userState}
      setState={setUserState}
      stateError={stateError}
      apiError={apiError}
      setApiError={setApiError}
      isLoading={isLoading}
      age={age}
      setAge={setAge}
      ageError={ageError}
      insure={insure}
      setInsure={setInsure}
      _onSubmitClick={_onSubmitClick}
      value={carNumber}
      setCarNumber={setCarNumber}
      carNumberError={carNumberError}
      carInsuranceType={carInsuranceType}
      setCarInsuranceType={setCarInsuranceType}
      _onSubmitMotorInsurance={_onSubmitMotorInsurance}
      insurances={insuranceCompanies}
      item={state}
    />
  );
};

export default InsurancePageContainer;
