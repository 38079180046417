import { createTheme } from "@mui/material";
import { primaryBlue, primaryDarkBlue } from "./colors";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: primaryBlue,
    },
    secondary: {
      main: "#A6A6A6",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation0: {
          display: "flex",
          flexDirection: "column",
        },
        root: {
          display: "flex",
          flexDirection: "column",
        },
      },
    },
  },
});
