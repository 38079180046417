export const primaryBlue = "#3047B6";
export const primaryBlueLight = "#dee4ff";
export const grayColor = "#A6A6A6";
export const borderYellow = "rgba(250, 177, 39, 0.8)";
export const primaryDarkBlue = "#412673";
export const darkGrey = "#646464";
export const formTitleColor = "#0F0E0E";
export const hoverBlue = "#3957ed1a";
export const backgroundGray = "#F4F4F4";
export const lightBackgroundGray = "#D9D9D9";
export const pageTitleColor = "#3047B6";
export const darkGreySecondary = "#969283";
export const white = "#fff";
