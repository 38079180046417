import { Box, Paper, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import {
  borderYellow,
  darkGrey,
  darkGreySecondary,
  formTitleColor,
  pageTitleColor,
  primaryBlue,
  primaryDarkBlue,
} from "./common/colors";
import Footer from "./common/components/footer";
import NavBar from "./common/components/navBar";
import ProtectedRoutes from "./common/components/protectedRoutes";
import { customTheme } from "./common/theme";
import { routes } from "./common/utils/constants";
import CarExchangeContainer from "./pages/carExchange/carExchnageContainer";
import CarLoanContainer from "./pages/carLoans/carLoanContainer";
import ForgotPasswordContainer from "./pages/forgotPassword/forgotPasswordContainer";
import HomePageContainer from "./pages/home/homePageContainer";
import InsurancePageContainer from "./pages/insurance/insurancePageContainer";
import LoginPageContainer from "./pages/login/loginPageContainer";
import RegisterPageContainer from "./pages/register/registerPageContainer";
import { selectUserDetails } from "./redux/slices/userDetailsSlice";
import { useSelector } from "react-redux";
import ServicesPageContainer from "./pages/servicesPage/servicePageContainer";
import LifeInsuranceContainer from "./pages/lifeInsurance/lifeInsuranceContainer";
import BonnyAutoHomePageContainer from "./pages/bonnyAutoHome/bonnyAutoHomePageContainer";
import BonnyYamahaHomePageContainer from "./pages/bonnyYahamaHome/bonnyYamahaHomePageContainer";
import PrivacyPolicy from "./pages/static/privacyPolicy";
import Terms from "./pages/static/terms";

// to be used for page titles
customTheme.typography.h1 = {
  fontSize: "28px",
  lineHeight: "18px",
  fontWeight: 700,
  color: primaryDarkBlue,
  [customTheme.breakpoints.up("md")]: {
    fontSize: "48px",
    lineHeight: "68px",
  },
};

// to be used for section titles
customTheme.typography.h2 = {
  fontSize: "20px",
  lineHeight: "22px",
  fontWeight: 600,
  color: primaryDarkBlue,
  [customTheme.breakpoints.up("md")]: {
    fontSize: "34px",
    lineHeight: "48px",
  },
};

// to be used to footer titles
customTheme.typography.h3 = {
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: 700,
  color: darkGrey,
  marginBottom: "1px",
  [customTheme.breakpoints.up("md")]: {
    fontSize: "24px",
    marginBottom: "32px",
  },
};

// to be used for footer subtitles
customTheme.typography.subtitle1 = {
  fontSize: "12px",
  lineHeight: "22px",
  fontWeight: 700,
  color: darkGrey,
  [customTheme.breakpoints.up("md")]: {
    fontSize: "15px",
  },
};

// to be used for item titles
customTheme.typography.h4 = {
  fontSize: "20px",
  lineHeight: "22px",
  fontWeight: 600,
  color: primaryBlue,
  [customTheme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
};

// to be used for item description
customTheme.typography.body1 = {
  fontSize: "12px",
  lineHeight: "18px",
  fontWeight: 400,
  color: darkGreySecondary,
  letterSpacing: "0.02em",
  wordSpacing: "2px",
  [customTheme.breakpoints.up("md")]: {
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.03em",
  },
};

// to be used for form titles
customTheme.typography.h5 = {
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: 600,
  color: formTitleColor,
  [customTheme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
};

// to be used for form subtitles
customTheme.typography.subtitle2 = {
  fontSize: "12px",
  lineHeight: "22px",
  fontWeight: 700,
  color: "black",
  [customTheme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
};

// to be used for other section titles
customTheme.typography.h6 = {
  fontSize: "10px",
  lineHeight: "22px",
  fontWeight: 500,
  color: primaryBlue,
  [customTheme.breakpoints.up("md")]: {
    fontSize: "14px",
  },
};

// to be used for footer links
customTheme.typography.body2 = {
  fontSize: "12px",
  lineHeight: "18px",
  fontWeight: 400,
  color: darkGrey,
  letterSpacing: "0.02em",
  wordSpacing: "2px",
  [customTheme.breakpoints.up("md")]: {
    fontSize: "15px",
    lineHeight: "28px",
    letterSpacing: "0.024em",
  },
};

customTheme.typography.caption = {
  fontSize: "10px",
  lineHeight: "14px",
  fontWeight: 400,
  color: darkGrey,
  letterSpacing: "0.01em",
  wordSpacing: "1px",
  [customTheme.breakpoints.up("md")]: {
    fontSize: "15px",
    lineHeight: "28px",
    letterSpacing: "0.024em",
  },
};

function App() {
  const userDetailsObserver = useSelector(selectUserDetails);
  const { accessToken, userDetails } = userDetailsObserver || {};
  return (
    <ThemeProvider theme={customTheme}>
      <Paper elevation={0} className="appWrapper">
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path={routes.REGISTER} element={<RegisterPageContainer />} />
            <Route path={routes.LOGIN} element={<LoginPageContainer />} />
            {/* <Route path={routes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={routes.TERMS} element={<Terms />} /> */}
            <Route
              path={routes.FORGOT_PASSWORD}
              element={<ForgotPasswordContainer />}
            />
            <Route path={routes.HOME_PAGE} element={<HomePageContainer />} />
            <Route
              path={routes.BONNY_AUTO_HOME}
              element={<BonnyAutoHomePageContainer />}
            />
            <Route
              path={routes.BONNY_YAMAHA_HOME}
              element={<BonnyYamahaHomePageContainer />}
            />
            {/* protected routes */}
            <Route
              path={routes.INSURANCE}
              element={
                // <ProtectedRoutes token={accessToken}>
                <InsurancePageContainer />
                // </ProtectedRoutes>
              }
            />
            <Route
              path={routes.CAR_EXCHANGE}
              element={
                // <ProtectedRoutes token={accessToken}>
                <CarExchangeContainer />
                // </ProtectedRoutes>
              }
            />
            <Route
              path={routes.CAR_LOAN}
              element={
                // <ProtectedRoutes token={accessToken}>
                <CarLoanContainer />
                // </ProtectedRoutes>
              }
            />
            <Route
              path={routes.SERVICES}
              element={
                // <ProtectedRoutes token={accessToken}>
                <ServicesPageContainer />
                // </ProtectedRoutes>
              }
            />
            <Route
              path={routes.LIFE_INSURANCE}
              element={
                // <ProtectedRoutes token={accessToken}>
                <LifeInsuranceContainer />
                // </ProtectedRoutes>
              }
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </Paper>
    </ThemeProvider>
  );
}

export default App;
