import React from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { grayColor } from "../../common/colors";
import { Link } from "react-router-dom";
import { routes } from "../../common/utils/constants";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      background: "linear-gradient(#BBD2C5, #F2F2F2, #DBDBDB, #EAEAEA)",
      minHeight: "100vh",
      minWidth: "100vw",
    },
    content: {
      maxHeight: "70vh",
      marginBlock: "auto",
    },
    logoImg: {
      width: "15vw",
    },
    title: {
      marginTop: "48px",
      color: "#636567",
      fontSize: "32px",
      fontWeight: "700",
      lineHeight: "24px",
      [theme.breakpoints.up("md")]: {
        fontSize: "60px",
        lineHeight: "42px",
        marginTop: "90px",
      },
    },
    description: {
      marginTop: "8vh",
      color: "#636567",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "24px",
      [theme.breakpoints.up("md")]: {
        fontSize: "32px",
        lineHeight: "42px",
        marginTop: "10vh",
      },
    },
    borderRight: {
      borderRightColor: grayColor,
      borderRightWidth: "3px",
      borderRightStyle: "solid",
    },
  };
});

const HomePageView = ({}) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={`d-flex flex-row ${classes.content}`}>
        <Link
          to={routes.BONNY_AUTO_HOME}
          className={`container text-center flex-grow-1 ${classes.borderRight}`}
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <img
              src={require("../../assets/images/bonny_logo_big.png")}
              className={`img-fluid ${classes.logoImg}`}
            />
            <span className={classes.title}>Bonny Auto</span>
            <span className={classes.description}>
              A Best Auto Finance Place in Morbi
            </span>
          </div>
        </Link>
        <Link
          to={routes.BONNY_YAMAHA_HOME}
          className="container text-center flex-grow-1"
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <img
              src={require("../../assets/images/yamaha_logo_big.png")}
              className={`img-fluid ${classes.logoImg}`}
            />
            <span className={classes.title}>Bonny Yamaha</span>
            <span className={classes.description}>
              A Best Bike Showroom in Morbi
            </span>
          </div>
        </Link>
      </div>
    </Paper>
  );
};

export default HomePageView;
