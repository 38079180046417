import React from "react";
import DropDown from "../../common/components/dropDown";
import CustomModal from "../../common/components/customModal";
import ModalForms from "./components/modalForms";
import BaseInput from "../../common/components/inputs/baseInput";
import { Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { darkGrey, formTitleColor, hoverBlue } from "../../common/colors";
import SolidButton from "../../common/components/buttons/solidButton";
import SecondaryButton from "../../common/components/buttons/secondaryButton";
import { carInsuranceTypes, insureTypes } from "../../common/utils/constants";
import CustomAlert from "../../common/components/customAlert";
import ScrollToTop from "../../common/components/scrollToTop";
import {
  getNonMotorInsuranceImages,
  Section,
} from "../bonnyAutoHome/components/services";

const useStyles = makeStyles((theme) => {
  return {
    insuranceItem: {
      fontSize: "12px",
      padding: "24px",
      lineHeight: "22px",
      color: formTitleColor,
      borderColor: formTitleColor,
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "10px",
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
        padding: "32px",
      },
      "&:hover": {
        backgroundColor: hoverBlue,
      },
    },
    listBullet: {
      minWidth: "5px",
      width: "5px",
      height: "5px",
      marginTop: "8px",
      borderRadius: "10px",
      backgroundColor: darkGrey,
      [theme.breakpoints.up("md")]: {
        minWidth: "10px",
        width: "10px",
        height: "10px",
      },
    },
  };
});

const InsurancePageView = ({
  modalType,
  _showModal,
  insuraceCompany,
  setInsuraceCompany,
  companyName,
  setCompanyName,
  companyNameError,
  city,
  setCity,
  cityError,
  state,
  setState,
  stateError,
  apiError,
  setApiError,
  isLoading,
  age,
  setAge,
  ageError,
  insure,
  setInsure,
  _onSubmitClick,
  _onSubmitMotorInsurance,
  name,
  setName,
  nameError,
  mobileNumber,
  setMobileNumber,
  numberError,
  email,
  setEmail,
  emailError,
  insurances,
  item,
}) => {

  console.log(item);
  const classes = useStyles();
  return (
    <Paper className="container" elevation={0}>
      <ScrollToTop />
      <CustomAlert
        isShow={apiError}
        message={apiError}
        onClose={() => setApiError(null)}
      />
      <CustomModal
        showModal={modalType ? true : false}
        isSuccess={modalType === "SUCCESS"}
        hideModal={() => _showModal(null)}
        isPositiveButtonLoading={isLoading}
        positiveButtonCallBack={() => {
          console.log('callback', modalType);
          if (modalType === "SUCCESS") {
            _showModal(null);
          } else if (modalType) {
            _onSubmitClick();
          } else {
            _onSubmitMotorInsurance();
          }
        }}
        negativeButtonCallBack={() => _showModal(null)}
        title={modalType && modalType.title ? modalType.title : null}
      >
        {modalType === "HEALTH_INSURANCE" ||
        modalType === "TRAVEL_INSURANCE" ? (
          <>
            <BaseInput
              placeholder="Enter Name"
              customClasses="mt-3"
              label="Name"
              value={name}
              setValue={setName}
              error={nameError}
            />
            <BaseInput
              placeholder="Enter Email"
              customClasses="mt-3"
              label="Email"
              value={email}
              setValue={setEmail}
              error={emailError}
            />
            <BaseInput
              placeholder="Enter Mobile Number"
              customClasses="mt-3"
              label="Mobile Number"
              value={mobileNumber}
              setValue={setMobileNumber}
              error={numberError}
            />
            <BaseInput
              placeholder="Enter City"
              customClasses="mt-3"
              label="City"
              value={city}
              setValue={setCity}
              error={cityError}
            />
            <BaseInput
              placeholder="Enter State"
              customClasses="mt-3"
              label="State"
              value={state}
              setValue={setState}
              error={stateError}
            />
            <DropDown
              list={insurances}
              label="Company"
              className="mt-3"
              selectedValue={insuraceCompany}
              setSelectedValue={setInsuraceCompany}
            />
            {item.type === "HEALTH_INSURANCE" ? (
              <DropDown
                list={insureTypes}
                className="mt-3"
                label="Insure"
                selectedValue={insure}
                setSelectedValue={setInsure}
              />
            ) : null}
            <BaseInput
              placeholder="Enter Age"
              customClasses="mt-3"
              label="Age"
              value={age}
              setValue={setAge}
              error={ageError}
            />
          </>
        ) : (
          <>
            <BaseInput
              placeholder="Enter Name"
              customClasses="mt-3"
              label="Name"
              value={name}
              setValue={setName}
              error={nameError}
            />
            <BaseInput
              placeholder="Enter Email"
              customClasses="mt-3"
              label="Email"
              value={email}
              setValue={setEmail}
              error={emailError}
            />
            <BaseInput
              placeholder="Enter Mobile Number"
              customClasses="mt-3"
              label="Mobile Number"
              value={mobileNumber}
              setValue={setMobileNumber}
              error={numberError}
            />
            <BaseInput
              placeholder="Enter City"
              customClasses="mt-3"
              label="City"
              value={city}
              setValue={setCity}
              error={cityError}
            />
            <BaseInput
              placeholder="Enter State"
              customClasses="mt-3"
              label="State"
              value={state}
              setValue={setState}
              error={stateError}
            />
            <DropDown
              list={insurances}
              className="mt-3"
              label="Company"
              selectedValue={insuraceCompany}
              setSelectedValue={setInsuraceCompany}
            />
            <BaseInput
              placeholder="Enter Company Name"
              customClasses="mt-3"
              label="Company Name"
              value={companyName}
              setValue={setCompanyName}
              error={companyNameError}
            />
          </>
        )}
      </CustomModal>

      <Section
        imageSrc={getNonMotorInsuranceImages(item.type)}
        title={item.title}
        containerClassName="mt-5"
      />

      {item.type === "OTHER" ? (
        <div>
          <Typography variant="body2" className="text-center px-3">
            Commercial insurance is offered to commercial entities such as
            businessmen, industries etc. and provides cover for various business
            and related needs. Commercial insurance is a type of business
            insurance that offers solutions for industrial sectors including but
            not limited to construction, manufacturing, telecom, textiles,
            logistics etc. These solutions aim to offer a safety net for
            business operations and assets when the need arises.
          </Typography>

          <Typography
            variant="h5"
            className="mt-5"
          >{`What is covered in commercial vehicle insurance?`}</Typography>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">
                Accident cover in commercial vehicle insurance
              </Typography>
              <Typography variant="body2">
                AccidentsCommercial vehicle insurance policies provide coverage
                against the damages to or by a commercial vehicle in case of an
                accident.
              </Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">Fire</Typography>
              <Typography variant="body2">
                Losses/Damages caused to commercial vehicles in case of fire are
                covered in this policy.protection against fire in commercial
                vehicle insurance.
              </Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">Theft cover</Typography>
              <Typography variant="body2">
                If your commercial vehicle is stolen, this policy ensures you
                are covered for the loss of the vehicle if your vehicle can not
                be tracked.
              </Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">Natural Calamities</Typography>
              <Typography variant="body2">
                Natural disasters such as floods, lightning, and storms can
                cause huge damage to vehicles and a commercial vehicle insurance
                policy can protect you from losses due to any natural
                calamity.cover against natural disasters in commercial vehicle
                insurance.
              </Typography>
            </div>
          </div>

          <Typography
            variant="h5"
            className="mt-5"
          >{`Commercial Vehicle Insurance Third-Party Coverage`}</Typography>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">Third-Party Losses</Typography>
              <Typography variant="body2">
                Third-party property damages are covered up to 7.5 lac and
                injury, and deaths of third parties are covered by an unlimited
                amount in commercial vehicle insurance.
              </Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">
                Towing Disabled Vehicles
              </Typography>
              <Typography variant="body2">
                Any damages to or by the commercial vehicle while being towed
                are covered in the commercial vehicle insurance policy.
              </Typography>
            </div>
          </div>

          <Typography
            variant="h5"
            className="mt-5"
          >{`What is not covered in commercial vehicle insurance?`}</Typography>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">General Wear & Tear</Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">
                Any loss or damage to the vehicle due to war, invasion or
                nuclear warfare etc.
              </Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">
                Mechanical or electrical breakdown of the vehicle.
              </Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">
                Any loss or damage caused outside the geographical area of
                coverage as mentioned in the policy.
              </Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">
                Usual depreciation of the value.
              </Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">
                Driving without an appropriate driving license.
              </Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">
                Non-accidental losses.
              </Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">
                Any loss occurred to the commercial vehicle while using it for
                racing or any illegal purposes.
              </Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">
                Any losses occurred while drunk driving the vehicle.
              </Typography>
            </div>
          </div>

          <Typography
            variant="h5"
            className="mt-5"
          >{`Types of Commercial Vehicle Insurance Plans`}</Typography>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">Third Party Insurance</Typography>
              <Typography variant="body2">
                Third Party policy covers the damages to third-party properties
                or injuries or death of other persons involved in an accident
                with the insured. As per Indian laws, it's mandatory to have at
                least third-party insurance to drive a vehicle in India.
                Third-party insurance is recommended to vehicle owners if the
                vehicle is very old and has a very low IDV compared to the
                premium being paid or if it's being driven very less.
              </Typography>
            </div>
          </div>

          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              <Typography variant="subtitle1">
                Comprehensive/First Party Insurance
              </Typography>
              <Typography variant="body2">
                This type of policy covers both third-party damages and own
                vehicle damages in case of any accident, theft or fire. It is
                not mandatory to buy a comprehensive policy for your commercial
                vehicle but it's always advisable to opt for it because of the
                limited coverage provided in third-party-only policies. A
                comprehensive policy will cover insured vehicle against any
                partial or total damage to the vehicle due to any accident or
                external means such as riots or natural calamities as well hence
                it's best to buy a comprehensive package policy.
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Typography variant="body2" className="text-center px-3">
            {item.description}
          </Typography>

          {item.whyInsurance && item.whyInsurance.length ? (
            <>
              <Typography
                variant="h5"
                className="mt-5"
              >{`Why do we need of need to take ${item.title} insurance`}</Typography>
              {item.whyInsurance.map((data) => {
                return (
                  <div className="d-flex flex-row mt-3">
                    <div className={`${classes.listBullet}`} />
                    <div className="d-flex flex-column ms-3">
                      {data.title ? (
                        <Typography variant="subtitle1">
                          {data.title}
                        </Typography>
                      ) : null}
                      {data.description ? (
                        <Typography variant="body2">
                          {data.description}
                        </Typography>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
      )}

      <SolidButton
        btnText={"Get Quote Now"}
        customClasses="w-md-25 w-75 align-self-center mt-5"
        btnClick={() => _showModal(item.type ? item.type : 'MOTOR')}
      />
    </Paper>
  );
};

export default InsurancePageView;
