import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { darkGrey } from "../../common/colors";
import ScrollToTop from "../../common/components/scrollToTop";

const useStyles = makeStyles((theme) => {
  return {
    listBullet: {
      minWidth: "5px",
      width: "5px",
      height: "5px",
      borderRadius: "10px",
      marginTop: "8px",
      backgroundColor: darkGrey,
      [theme.breakpoints.up("md")]: {
        minWidth: "10px",
        width: "10px",
        height: "10px",
      },
    },
  };
});

const Terms = () => {
  const classes = useStyles();
  return (
    <div className="container mt-5">
      <ScrollToTop />
      <Typography variant="h1" className="text-center">
        Terms & Conditions
      </Typography>

      <Typography variant="body1" className="mt-3">
        {
          "Bonny Auto World operates the website https://www.bonnyauto.co.in/ to provide consumers choice and an easy way to buy Insurance products from multiple Insurance companies. Bonny Auto World is not an insurance company. Bonny Auto World is a licensed Insurance Broking Company holding a Broking license from the Indian Insurance Regulator – Insurance Regulatory and Development authority."
        }
      </Typography>

      <Typography variant="body1" className="mt-2">
        {
          "Bonny Auto World does not warrant or guarantee the Timeliness, accuracy or completeness of the Website Information; or Quality of the results obtained from the use of the Website."
        }
      </Typography>

      <Typography variant="body1" className="mt-2">
        {
          "To the maximum extent permitted by law, Bonny Auto World has no liability in relation to or arising out of the Website Information and Website recommendations. You are responsible for the final choice of your product and you should take time to read through all information supplied before proceeding. If you are in any doubt regarding a product or its terms you should seek further advice from Bonny Auto World or the relevant participating provider before choosing your product."
        }
      </Typography>

      <Typography variant="body1" className="mt-2">
        {
          "By visiting our website and accessing the information, resources, services, products, and tools we provide, you understand and agree to accept and adhere to the following terms and conditions as stated in this policy (hereafter referred to as ‘User Agreement’), along with the terms and conditions as stated in our Privacy Policy (please refer to the Privacy Policy section below for more information)."
        }
      </Typography>

      <Typography variant="body1" className="mt-2">
        {"This agreement is in effect as of Oct 02, 2021."}
      </Typography>

      <Typography variant="body1" className="mt-2">
        {
          "We reserve the right to change this User Agreement from time to time without notice. You acknowledge and agree that it is your responsibility to review this User Agreement periodically to familiarize yourself with any modifications. Your continued use of this site after such modifications will constitute acknowledgment and agreement of the modified terms and conditions."
        }
      </Typography>

      <Typography variant="h3" className="mt-5">
        Responsible Use and Conduct
      </Typography>

      <Typography variant="body1" className="mt-2">
        By visiting our website and accessing the information, resources,
        services, products, and tools we provide for you, either directly or
        indirectly (hereafter referred to as ‘Resources’), you agree to use
        these Resources only for the purposes intended as permitted by (a) the
        terms of this User Agreement, and (b) applicable laws, regulations and
        generally accepted online practices or guidelines.
      </Typography>

      <Typography variant="body1" className="mt-2">
        Wherein, you understand that:
      </Typography>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          In order to access our Resources, you may be required to provide
          certain information about yourself (such as identification, contact
          details, etc.) as part of the registration process, or as part of your
          ability to use the Resources. You agree that any information you
          provide will always be accurate, correct, and up to date.
        </Typography>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          You are responsible for maintaining the confidentiality of any login
          information associated with any account you use to access our
          Resources. Accordingly, you are responsible for all activities that
          occur under your account/s.
        </Typography>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          Accessing (or attempting to access) any of our Resources by any means
          other than through the means we provide, is strictly prohibited. You
          specifically agree not to access (or attempt to access) any of our
          Resources through any automated, unethical or unconventional means.
        </Typography>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          Engaging in any activity that disrupts or interferes with our
          Resources, including the servers and/or networks to which our
          Resources are located or connected, is strictly prohibited.
        </Typography>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          Attempting to copy, duplicate, reproduce, sell, trade, or resell our
          Resources is strictly prohibited.
        </Typography>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          You are solely responsible any consequences, losses, or damages that
          we may directly or indirectly incur or suffer due to any unauthorized
          activities conducted by you, as explained above, and may incur
          criminal or civil liability.
        </Typography>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          We have the right at our sole discretion to remove any content that,
          we feel in our judgment does not comply with this User Agreement,
          along with any content that we feel is otherwise offensive, harmful,
          objectionable, inaccurate, or violates any 3rd party copyrights or
          trademarks. We are not responsible for any delay or failure in
          removing such content. If you post content that we choose to remove,
          you hereby consent to such removal, and consent to waive any claim
          against us.
        </Typography>
      </div>

      <Typography variant="h3" className="mt-5">
        Privacy
      </Typography>

      <Typography variant="body1" className="mt-2">
        {
          "Your privacy is very important to us, which is why we’ve created a separate Privacy Policy in order to explain in detail how we collect, manage, process, secure, and store your private information. Our privacy policy is included under the scope of this User Agreement. To read our privacy policy in its entirety, click here."
        }
      </Typography>

      <Typography variant="h3" className="mt-5">
        Limitation of Liability
      </Typography>

      <Typography variant="body1" className="mt-2">
        {
          "In conjunction with the Limitation of Warranties as explained above, you expressly understand and agree that any claim against us shall be limited to the amount you paid, if any, for use of products and/or services Bonny Auto World. will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred by you as a result of using our Resources, or as a result of any changes, data loss or corruption, cancellation, loss of access, or downtime to the full extent that applicable limitation of liability laws apply."
        }
      </Typography>

      <Typography variant="h3" className="mt-5">
        Termination of Use
      </Typography>

      <Typography variant="body1" className="mt-2">
        {
          "You agree that we may, at our sole discretion, suspend or terminate your access to all or part of our website and Resources with or without notice and for any reason, including, without limitation, breach of this User Agreement. Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities. Upon suspension or termination, your right to use the Resources we provide will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us, including any account or login information."
        }
      </Typography>

      <Typography variant="h3" className="mt-5">
        Contact Information
      </Typography>

      <Typography variant="body1" className="mt-2">
        {
          "YIf you have any questions or comments about these our Terms of Service as outlined above, you can contact us at:"
        }
      </Typography>

      <Typography variant="body1" className="mt-2">
        {
          "Bonny Auto World:  1, Ravapar Rd, Sardar Nagar, Morbi, Gujarat 363641"
        }
      </Typography>
      <Typography variant="body1" className="mt-2">
        {"Email: info@bonnyauto.co.in"}
      </Typography>
      <Typography variant="body1" className="mt-2">
        {"Mobile: +91 98253 75754"}
      </Typography>
    </div>
  );
};

export default Terms;
