import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import SolidButton from "../../common/components/buttons/solidButton";
import BaseInput from "../../common/components/inputs/baseInput";

const useStyles = makeStyles((theme) => {
  return {
    titleImage: {
      marginLeft: "8px",
      width: "56px",
      [theme.breakpoints.up("md")]: {
        marginLeft: "16px",
        width: "134px",
      },
    },
  };
});

const ContactUs = () => {
  const classes = useStyles();
  return (
    <div className="py-5 container" id="contact-us">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 mx-0">
          <div className="d-flex flex-row align-items-end">
            <Typography variant="h1">Contact Us</Typography>
            <img
              src={require("../../assets/images/arrow.png")}
              className={classes.titleImage}
            />
          </div>
          <Typography variant="body1" className="mt-2">
            Our friendly team would love to hear from You !!!
          </Typography>

          <BaseInput customClasses="mt-5" label="Full Name" />

          <BaseInput label="Email" customClasses="mt-3" />

          <BaseInput label="Mobile Number" customClasses="mt-3" />

          <BaseInput
            label="Message"
            customClasses="mt-3 mb-5"
            multiline={true}
            rows={4}
          />

          <div className="mt-5">
            <SolidButton customClasses="w-100" />
          </div>
        </div>
        <div className="container col-12 col-md-6 align-items-center mt-5 mt-md-0">
          <iframe
            src="https://www.google.com/maps/place/Bonny+Auto/@22.8120854,70.830911,15z/data=!4m2!3m1!1s0x0:0xeaa1205d0639195e?sa=X&ved=2ahUKEwihhcDipPT7AhULs1YBHZ5lBCQQ_BJ6BAhjEAg"
            width="100%"
            height="100%"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
