import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userDetailsReducer from "./slices/userDetailsSlice";
import insuranceSlice from "./slices/insuranceSlice";

const combinedReducer = combineReducers({
  userDetails: userDetailsReducer,
  insurance: insuranceSlice,
});

const store = configureStore({
  reducer: combinedReducer,
});

export default store;
