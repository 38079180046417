const keyMap = {
  ACCESS_TOKEN: "access_token",
  USER: "user",
};

const setItemInStore = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getItemInStore = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const getAccessToken = () => {
  return getItemInStore(keyMap.ACCESS_TOKEN);
};

export const setAccessToken = (token) => {
  setItemInStore(keyMap.ACCESS_TOKEN, token);
};

export const setUser = (user) => {
  setItemInStore(keyMap.USER, user);
};

export const getUser = () => {
  return getItemInStore(keyMap.USER);
};

export const clearStorage = () => {
  localStorage.clear();
};
