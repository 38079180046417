import React from "react";
import DropDown from "../../../common/components/dropDown";
import BaseInput from "../../../common/components/inputs/baseInput";
import {
  ageTypes,
  engineTypes,
  wantTestRideEnum,
} from "../../../common/utils/constants";

const ModalForms = ({
  city,
  setCity,
  cityError,
  name,
  setName,
  nameError,
  mobileNumber,
  setMobileNumber,
  numberError,
  email,
  setEmail,
  emailError,
  state,
  setState,
  stateError,
}) => {
  return (
    <div>
      <div className="d-flex flex-column">
        <BaseInput
          placeholder="Enter Name"
          customClasses="mt-3"
          label="Name"
          value={name}
          setValue={setName}
          error={nameError}
        />
        <BaseInput
          placeholder="Enter Email"
          customClasses="mt-3"
          label="Email"
          value={email}
          setValue={setEmail}
          error={emailError}
        />
        <BaseInput
          placeholder="Enter Mobile Number"
          customClasses="mt-3"
          label="Mobile Number"
          value={mobileNumber}
          setValue={setMobileNumber}
          error={numberError}
        />
        <BaseInput
          placeholder="City"
          customClasses="mt-3"
          label="City"
          value={city}
          setValue={setCity}
          error={cityError}
        />
        <BaseInput
          placeholder="State"
          customClasses="mt-3"
          label="Enter State"
          value={state}
          setValue={setState}
          error={stateError}
        />
        {/* <DropDown
            list={models}
            className="mt-3"
            label="Model"
            selectedValue={selectedModel}
            setSelectedValue={setSelectedModel}
          /> */}
      </div>
    </div>
  );
};

export default ModalForms;
