import React, { useRef } from "react";
import {
  carInsuranceTypes,
  getBaseUrl,
  routes,
  STATUS,
} from "../../common/utils/constants";
import { FormHelperText, Paper, Typography } from "@mui/material";
import BaseLoader from "../../common/components/loader";
import CustomAlert from "../../common/components/customAlert";
import Carousel from "react-material-ui-carousel";
import { makeStyles } from "@mui/styles";
import { backgroundGray, lightBackgroundGray } from "../../common/colors";
import Banner from "../../assets/images/bonny_auto_banner.jpg";
import OfferBackground from "../../assets/images/home_page_offer_background.png";
import OfferItem from "./components/offerItem";
import Overview from "./components/overview";
import Services from "./components/services";
import CarouselItem from "./components/caroselItem";
import Marquee from "react-fast-marquee";
import CustomerReviews from "../../common/components/customerReviews";
import AwardsAndRecognition from "../../common/components/awardsRecognitions";
import Faq from "../../common/components/faq";
import ContactUs from "../../common/components/contactUs";
import ScrollToTop from "../../common/components/scrollToTop";
import CustomModal from "../../common/components/customModal";
import BaseInput from "../../common/components/inputs/baseInput";
import DropDown from "../../common/components/dropDown";
import Video from "../../assets/images/video.mp4";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: "70vh",
      width: "70vw",
      paddingBlock: "10vh",
    },
    insuranceCompanyLogoContainer: {
      backgroundColor: "white",
      paddingTop: "24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        paddingTop: "56px",
      },
    },
    insuranceCompanyLogo: {
      objectFit: "none",
    },
    contentBackground: {
      backgroundColor: backgroundGray,
    },
    banner: {
      width: "100vw",
      height: "50vh",
      objectFit: "fill",
      [theme.breakpoints.up("md")]: {
        height: "80vh",
      },
    },
    insuranceCompanyLogoSeparator: {
      width: "50%",
      alignSelf: "center",
      marginBottom: "32px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "64px",
      },
    },
    offerSubContainer: {
      backgroundImage:
        "linear-gradient(to bottom right, rgba(255,255,255,0.4), rgba(255,255,255,0.4))",
      backdropFilter: "blur(10px)",
      padding: "18px 24px",
      borderRadius: "16px",
      marginTop: "20px",
      width: "90%",
      overflow: "scroll",
      [theme.breakpoints.up("md")]: {
        padding: "36px 44px",
        borderRadius: "30px",
        marginTop: "40px",
        width: "60%",
        overflow: "hidden",
      },
    },
    width25: {
      width: "50vw",
      [theme.breakpoints.up("md")]: {
        width: "25vw",
      },
    },
    whatWeOfferImg: {
      width: "36px",
      height: "36px",
      zIndex: 1,
      marginLeft: "-8px",
      marginTop: "24px",
      [theme.breakpoints.up("md")]: {
        width: "56px",
        height: "56px",
        marginLeft: "-16px",
        marginTop: "-12px",
      },
    },
  };
});

// https://cdn.suzukimotorcycle.co.in/public-live/videos/desktop-autoexpo-video.mp4

const BonnyAutoHomePageView = ({
  bannerImages,
  apiError,
  setApiError,
  insuranceObserver,
  bannerBottomImages,
  userDetails,
  bonnyAutoModalForm,
  offerItemImages,
  content,
  nonMotorInsuranceTypes,
  vehicleLoanItems,
  reviews,
  awards,
  advantages,
  faqs,
  modelType,
  _showModal,
  _onSubmitClick,
  name,
  setName,
  nameError,
  email,
  setEmail,
  emailError,
  mobileNumber,
  setMobileNumber,
  numberError,
  city,
  setCity,
  cityError,
  state,
  setState,
  stateError,
  insuraceCompany,
  setInsuraceCompany,
  carNumber,
  setCarNumber,
  carNumberError,
  carInsuranceType,
  setCarInsuranceType,
  carName,
  setCarName,
  carNameError,
  budget,
  setBudget,
  budgetError,
  distance,
  setDistance,
  distanceError,
  firstSideImage,
  setFirstSideImage,
  secondSideImage,
  setSecondSideImage,
  thirdSideImage,
  setThirdSideImage,
  forthSideImage,
  setForthSideImage,
  interiorImage,
  setInteriorImage,
  imageError,
  newCarName,
  setNewCarName,
  newCarNameError,
  isLoading,
  mobileBannerImages,
}) => {
  const classes = useStyles();
  const firstImageRef = useRef();
  const secondImageRef = useRef();
  const thirdImageRef = useRef();
  const forthImageRef = useRef();
  const interiorImageRef = useRef();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 70,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0,
    },
  };

  if (
    (userDetails &&
      bannerImages &&
      bannerBottomImages &&
      mobileBannerImages &&
      insuranceObserver.status === STATUS.SUCCESS) ||
    (!userDetails && bannerImages && bannerBottomImages && mobileBannerImages)
  ) {
    return (
      <div>
        <ScrollToTop />
        <CustomAlert
          isShow={apiError}
          message={apiError}
          onClose={() => setApiError(null)}
        />
        <CustomModal
          showModal={modelType ? true : false}
          isSuccess={modelType === "SUCCESS"}
          hideModal={() => _showModal(null)}
          isPositiveButtonLoading={isLoading}
          positiveButtonCallBack={() => {
            if (modelType === "SUCCESS") {
              _showModal(null);
            } else {
              _onSubmitClick();
            }
          }}
          negativeButtonCallBack={() => _showModal(null)}
          title={""}
        >
          {modelType === "MOTOR" ? (
            <>
              <BaseInput
                placeholder="Enter Name"
                customClasses="mt-3"
                label="Name"
                value={name}
                setValue={setName}
                error={nameError}
              />
              <BaseInput
                placeholder="Enter Email"
                customClasses="mt-3"
                label="Email"
                value={email}
                setValue={setEmail}
                error={emailError}
              />
              <BaseInput
                placeholder="Enter Mobile Number"
                customClasses="mt-3"
                label="Mobile Number"
                value={mobileNumber}
                setValue={setMobileNumber}
                error={numberError}
              />
              <BaseInput
                placeholder="Enter City"
                customClasses="mt-3"
                label="City"
                value={city}
                setValue={setCity}
                error={cityError}
              />
              <BaseInput
                placeholder="Enter State"
                customClasses="mt-3"
                label="State"
                value={state}
                setValue={setState}
                error={stateError}
              />
              <DropDown
                list={carInsuranceTypes}
                label="Company"
                className="mt-3"
                selectedValue={insuraceCompany}
                setSelectedValue={setInsuraceCompany}
              />
              <BaseInput
                placeholder="GJ-03-AB-0000"
                containerClasses="mt-3"
                label="Car Number"
                value={carNumber}
                setValue={setCarNumber}
                error={carNumberError}
              />
              <DropDown
                list={carInsuranceTypes}
                className="mt-3"
                label="Insurance Type"
                selectedValue={carInsuranceType}
                setSelectedValue={setCarInsuranceType}
              />
            </>
          ) : (
            <>
              <BaseInput
                placeholder="Enter Name"
                customClasses="mt-3"
                label="Name"
                value={name}
                setValue={setName}
                error={nameError}
              />
              <BaseInput
                placeholder="Enter Email"
                customClasses="mt-3"
                label="Email"
                value={email}
                setValue={setEmail}
                error={emailError}
              />
              <BaseInput
                placeholder="Enter Mobile Number"
                customClasses="mt-3"
                label="Mobile Number"
                value={mobileNumber}
                setValue={setMobileNumber}
                error={numberError}
              />
              <BaseInput
                placeholder="Enter City"
                customClasses="mt-3"
                label="City"
                value={city}
                setValue={setCity}
                error={cityError}
              />
              <BaseInput
                placeholder="Enter State"
                customClasses="mt-3"
                label="State"
                value={state}
                setValue={setState}
                error={stateError}
              />
              <BaseInput
                placeholder="Enter Old Car Name"
                customClasses="mt-3"
                label="Old Car Name"
                value={carName}
                setValue={setCarName}
                error={carNameError}
              />
              <BaseInput
                placeholder="Enter KMS"
                customClasses="mt-3"
                label="Distance"
                value={distance}
                setValue={setDistance}
                error={distanceError}
              />
              <BaseInput
                placeholder="Enter Budget"
                customClasses="mt-3"
                label="Budget"
                value={budget}
                setValue={setBudget}
                error={budgetError}
              />
              <BaseInput
                placeholder="Enter Car Name"
                customClasses="mt-3"
                label="Car Name"
                value={newCarName}
                setValue={setNewCarName}
                error={newCarNameError}
              />
              <span className="mt-3 upload_subtitle">Upload Side Images</span>
              <div className="d-flex flex-row">
                <div className={`mt-2 me-2 d-flex ${classes.imgContainer}`}>
                  <img
                    className={classes.img}
                    onClick={() => firstImageRef.current.click()}
                    src={
                      firstSideImage
                        ? URL.createObjectURL(firstSideImage)
                        : require("../../assets/images/place_holder_image.png")
                    }
                  />
                  <input
                    hidden={true}
                    type="file"
                    accept="image/*"
                    ref={firstImageRef}
                    onChange={(event) =>
                      setFirstSideImage(event.target.files[0])
                    }
                  />
                </div>
                <div className={`mt-2 me-2 d-flex ${classes.imgContainer}`}>
                  <img
                    className={classes.img}
                    onClick={() => secondImageRef.current.click()}
                    src={
                      secondSideImage
                        ? URL.createObjectURL(secondSideImage)
                        : require("../../assets/images/place_holder_image.png")
                    }
                  />
                  <input
                    hidden={true}
                    type="file"
                    accept="image/*"
                    ref={secondImageRef}
                    onChange={(event) =>
                      setSecondSideImage(event.target.files[0])
                    }
                  />
                </div>
              </div>

              <div className="d-flex flex-row">
                <div className={`mt-2 me-2 d-flex ${classes.imgContainer}`}>
                  <img
                    className={classes.img}
                    onClick={() => thirdImageRef.current.click()}
                    src={
                      thirdSideImage
                        ? URL.createObjectURL(thirdSideImage)
                        : require("../../assets/images/place_holder_image.png")
                    }
                  />
                  <input
                    hidden={true}
                    type="file"
                    accept="image/*"
                    ref={thirdImageRef}
                    onChange={(event) =>
                      setThirdSideImage(event.target.files[0])
                    }
                  />
                </div>
                <div className={`mt-2 me-2 d-flex ${classes.imgContainer}`}>
                  <img
                    className={classes.img}
                    onClick={() => forthImageRef.current.click()}
                    src={
                      forthSideImage
                        ? URL.createObjectURL(forthSideImage)
                        : require("../../assets/images/place_holder_image.png")
                    }
                  />
                  <input
                    hidden={true}
                    type="file"
                    accept="image/*"
                    ref={forthImageRef}
                    onChange={(event) =>
                      setForthSideImage(event.target.files[0])
                    }
                  />
                </div>
              </div>

              <div className={`mt-3 ${classes.imgContainer100}`}>
                <img
                  className={classes.imgLarge}
                  onClick={() => interiorImageRef.current.click()}
                  src={
                    interiorImage
                      ? URL.createObjectURL(interiorImage)
                      : require("../../assets/images/place_holder_image.png")
                  }
                />
                <input
                  hidden={true}
                  type="file"
                  accept="image/*"
                  ref={interiorImageRef}
                  onChange={(event) => setInteriorImage(event.target.files[0])}
                />
              </div>

              {imageError ? (
                <FormHelperText className="mt-3" error={true}>
                  {imageError}
                </FormHelperText>
              ) : null}
            </>
          )}
        </CustomModal>
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          className={classes.banner}
        >
          <source src={Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className={classes.insuranceCompanyLogoContainer}>
          <Typography variant="h3" className="text-center">
            Our General Insurance Partners
          </Typography>
          <Marquee className="my-5">
            <div className={classes.width25}>
              <img
                src={require("../../assets/images/tata_aia_logo.png")}
                className={`${classes.insuranceCompanyLogo} img-fluid mx-auto`}
              />
            </div>
            <div className={classes.width25}>
              <img
                src={require("../../assets/images/tata_aig_logo.png")}
                className={`${classes.insuranceCompanyLogo} img-fluid mx-auto`}
              />
            </div>
            <div className={classes.width25}>
              <img
                src={require("../../assets/images/hdfc_ergo_logo.png")}
                className={`${classes.insuranceCompanyLogo} img-fluid mx-auto`}
              />
            </div>
            <div className={classes.width25}>
              <img
                src={require("../../assets/images/hdfc_logo_2.png")}
                className={`${classes.insuranceCompanyLogo} img-fluid mx-auto`}
              />
            </div>
          </Marquee>
          <hr className={classes.insuranceCompanyLogoSeparator} />
        </div>

        <div className="offerContainer">
          <div className={`d-flex flex-column align-items-center offers py-5`}>
            <div className="d-flex flex-row align-items-center">
              <Typography variant="h1" className="text-center mt-5">
                What We Offer
              </Typography>
              <img
                src={require("../../assets/images/semi_circle.png")}
                className={classes.whatWeOfferImg}
              />
            </div>
            <div
              className={`d-flex flex-row align-items-center ${classes.offerSubContainer}`}
            >
              {bonnyAutoModalForm.map((item, index) => {
                return (
                  <OfferItem
                    title={item.title}
                    description={item.desc}
                    imageSrc={item.imageSource}
                    linkTo={item.linkTo}
                    isFromHomePage={true}
                    offerItemImage={
                      offerItemImages[index % offerItemImages.length]
                    }
                    index={index}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <Overview content={content} />

        <div className="pt-md-5 d-md-block d-none">
          <div className="container my-5">
            <Carousel
              ssr
              partialVisbile
              itemClass="image-item"
              responsive={responsive}
            >
              {bannerBottomImages.map((item) => {
                return <CarouselItem images={item} />;
              })}
            </Carousel>
          </div>
        </div>

        <div className="pt-5 pt-md-0  d-md-none d-block">
          <div className="container my-5">
            <Carousel
              ssr
              partialVisbile
              itemClass="image-item"
              responsive={responsive}
            >
              {mobileBannerImages.map((item) => {
                return <CarouselItem images={[item]} />;
              })}
            </Carousel>
          </div>
        </div>

        <Services
          nonMotorInsuranceTypes={nonMotorInsuranceTypes}
          vehicleLoanItems={vehicleLoanItems}
          advantages={advantages}
          _showModal={_showModal}
        />

        <CustomerReviews reviews={reviews} />

        <AwardsAndRecognition awards={awards} />

        {/* <Faq faqs={faqs} /> */}

        <ContactUs />
      </div>
    );
  } else {
    return <BaseLoader />;
  }
};

export default BonnyAutoHomePageView;
