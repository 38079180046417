import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const DropDown = ({
  selectedValue,
  list = [],
  setSelectedValue,
  className = "",
  label = "",
}) => {
  return (
    <FormControl className={`${className} w-100`} style={{ height: "48px" }}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue}
        label={label}
        style={{ height: "48px" }}
        onChange={(val) => setSelectedValue(val.target.value)}
      >
        {list.map((item) => {
          return <MenuItem value={item.value}>{item.label}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default DropDown;
