import { makeStyles } from "@mui/styles";
import React from "react";
import { getBaseUrl } from "../../../common/utils/constants";

const useStyles = makeStyles((theme) => {
  return {
    img: {
      objectFit: "fill",
      maxWidth: "100vw",
      maxHeight: "250px",
      [theme.breakpoints.up("md")]: {
        maxWidth: "40vw",
      },
    },
  };
});

const CarouselItem = ({ images }) => {
  const classes = useStyles();
  return (
    <div className="row">
      {images.map((item) => {
        return (
          <div className="col d-flex justify-content-center">
            <img
              src={`${getBaseUrl()}${item.bottom_Image}`}
              className={`${classes.img} mx-auto`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CarouselItem;
