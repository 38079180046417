import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../common/services/services";
import { routes } from "../../common/utils/constants";
import { setAccessToken, setUser } from "../../common/utils/storageUtils";
import LoginPageView from "./loginPageView";
import { useDispatch } from "react-redux";
import {
  setUserAccessToken,
  setUserDetails,
} from "../../redux/slices/userDetailsSlice";

const LoginPageContainer = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const emailRegex = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const phoneRegex = /^[0-9]*$/;

  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    if (emailError && email) {
      setEmailError(null);
    }

    if (passwordError && password) {
      setPasswordError(null);
    }
  }, [email, password]);

  const onLoginClick = () => {
    if (isEmail) {
      if (!email || email.trim() === "") {
        setEmailError("Enter Email");
        return;
      } else if (!emailRegex.test(email)) {
        setEmailError("Enter Valid Email Id");
        return;
      } else {
        setEmailError(null);
      }
    } else {
      if (!email || email.trim() === "") {
        setEmailError("Enter Mobile Number");
        return;
      } else if (!phoneRegex.test(email)) {
        setEmailError("Enter Valid Mobile Number");
        return;
      } else {
        setEmailError(null);
      }
    }

    if (!password || password.trim() === "") {
      setPasswordError("Enter Password");
      return;
    } else {
      setPasswordError(null);
    }

    setIsLoading(true);
    const formData = new FormData();
    if (isEmail) {
      formData.append("email", email);
    } else {
      formData.append("mobile_no", email);
    }
    formData.append("password", password);

    login(formData)
      .then((response) => {
        setAccessToken(response.access_token);
        setUser(response.user);
        dispatch(setUserAccessToken(response.access_token));
        dispatch(setUserDetails(response.user));
        setIsLoading(false);
        navigate(routes.HOME_PAGE);
      })
      .catch((error) => {
        setApiError(error.message);
        setIsLoading(false);
      });
  };

  const _emailMobileCallback = (text) => {
    if (text && text.length && phoneRegex.test(text)) {
      setIsEmail(false);
    } else {
      setIsEmail(true);
    }
    setEmail(text);
  };

  return (
    <LoginPageView
      email={email}
      password={password}
      setEmail={setEmail}
      setPassword={setPassword}
      emailError={emailError}
      passwordError={passwordError}
      onLoginClick={onLoginClick}
      isLoading={isLoading}
      _emailMobileCallback={_emailMobileCallback}
      error={apiError}
      setError={setApiError}
    />
  );
};

export default LoginPageContainer;
