import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => {
  return {
    btn: {
      borderRadius: "10px",
    },
  };
});

const SecondaryButton = ({
  btnText,
  btnClick,
  customClasses,
  isLoading = false,
}) => {
  const classes = useStyles();
  return (
    <LoadingButton
      variant="contained"
      color="secondary"
      onClick={btnClick}
      loading={isLoading}
      className={`${classes.btn} ${customClasses}`}
    >
      {btnText}
    </LoadingButton>
  );
};

export default SecondaryButton;
