import { CircularProgress, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => {
  return {
    pageContainer: {
      minHeight: "100vh",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});

const BaseLoader = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.pageContainer} elevation={0}>
      <CircularProgress />
    </Paper>
  );
};

export default BaseLoader;
