import React from "react";
import { SubSectionTitle } from "../../pages/bonnyAutoHome/components/services";
import { default as CustomerReview } from "../../assets/images/customer_review.svg";
import { makeStyles } from "@mui/styles";
import { Rating, Typography } from "@mui/material";
import AwardsLogo from "../../assets/images/user_placeholder.webp";
import { darkGrey, darkGreySecondary } from "../colors";

const useStyles = makeStyles((theme) => {
  return {
    hideScrollBar: {
      msOverflowStyle: "none",
      scrollbarWidth: "none",
    },
    reviewImage: {
      width: "100px",
      height: "100px",
      borderRadius: "8px",
    },
    reviewCard: {
      margin: "8px 20px",
      width: "322px",
      maxWidth: "322px",
      borderRadius: "10px",
      backgroundColor: "white",
    },
    reviewName: {
      flexGrow: 1,
      flexShrink: 0,
      fontSize: "17px",
      lineHeight: "22px",
      fontWeight: 400,
      color: darkGrey,
    },
    review: {
      fontSize: "10px",
      lineHeight: "14px",
      fontWeight: 400,
      color: darkGreySecondary,
      letterSpacing: "0.01em",
      wordSpacing: "2px",
      fontStyle: "italic",
      [theme.breakpoints.up("md")]: {
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "0.02em",
      },
    },
  };
});

const CustomerReviews = ({ reviews }) => {
  const classes = useStyles();
  return (
    <div className={`d-flex flex-column align-items-center py-5`}>
      <SubSectionTitle
        imageSrc={CustomerReview}
        title="What Our Customers Say"
      />

      <div
        className={`d-flex flex-row services_container ${classes.hideScrollBar}`}
      >
        {reviews.map((item) => {
          return <ReviewItem review={item} />;
        })}
      </div>
    </div>
  );
};

const ReviewItem = ({ review }) => {
  const classes = useStyles();
  return (
    <div
      className={`mx-4 px-4 py-4 flex-grow-1 flex-shrink-0 align-items-center rounded-3 ${classes.reviewCard} boxShadowLight`}
    >
      <div className={`d-flex flex-row align-items-center ${classes.width100}`}>
        <img src={AwardsLogo} className={classes.reviewImage} />
        <div className="ms-4">
          <span className={classes.reviewName}>{review.name}</span>
        </div>
      </div>

      <hr className="w-50 my-4" />

      <Typography variant="body1" className={classes.review}>
        {review.review}
      </Typography>
      {/* <Rating
        value={review.rating}
        readOnly
        className={`${classes.ratings} mt-2`}
      /> */}
    </div>
  );
};

export default CustomerReviews;
