import React, { useEffect, useState } from "react";
import { register } from "../../common/services/services";
import { setAccessToken, setUser } from "../../common/utils/storageUtils";
import {
  setUserAccessToken,
  setUserDetails,
} from "../../redux/slices/userDetailsSlice";
import RegisterPageView from "./registerPageView";
import { useDispatch } from "react-redux";
import { routes } from "../../common/utils/constants";
import { useNavigate } from "react-router-dom";

const RegisterPageContainer = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const emailRegex = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const phoneRegex = /^[0-9]*$/;

  const [isChecked, setIsChecked] = useState(true);
  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [number, setNumber] = useState(null);
  const [numberError, setNumberError] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (nameError && name) {
      setNameError(null);
    }

    if (numberError && number) {
      setNumberError(null);
    }

    if (emailError && email) {
      setEmailError(null);
    }

    if (passwordError && password) {
      setPasswordError(null);
    }
  }, [name, number, email, password]);

  const onCreateClick = () => {
    if (!isChecked) {
      return;
    }

    if (!name || name.trim() === "") {
      setNameError("Enter Name");
      return;
    } else {
      setNameError(null);
    }

    if (!number || number.trim() === "") {
      setNumberError("Enter Mobile Number");
      return;
    } else if (number.trim().length !== 10) {
      setNumberError("Enter Valid Mobile Number");
      return;
    } else if (!phoneRegex.test(number)) {
      setNumberError("Enter Valid Mobile Number");
      return;
    } else {
      setNumberError(null);
    }

    if (!email || email.trim() === "") {
      setEmailError("Enter Email");
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter Valid Email Id");
      return;
    } else {
      setEmailError(null);
    }

    if (!password || password.trim() === "") {
      setNumberError("Enter Password");
      return;
    } else {
      setNumberError(null);
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", name.trim());
    formData.append("mobile_no", number.trim());
    formData.append("email", email.trim());
    formData.append("password", password.trim());

    register(formData)
      .then((response) => {
        setAccessToken(response.access_token);
        setUser(response.user);
        dispatch(setUserAccessToken(response.access_token));
        dispatch(setUserDetails(response.user));
        setIsLoading(false);
        navigate(routes.HOME_PAGE);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  };

  return (
    <RegisterPageView
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      name={name}
      number={number}
      email={email}
      password={password}
      setName={setName}
      setNumber={setNumber}
      setEmail={setEmail}
      setPassword={setPassword}
      nameError={nameError}
      numberError={numberError}
      emailError={emailError}
      passwordError={passwordError}
      onCreateClick={onCreateClick}
      isLoading={isLoading}
      error={error}
      setError={setError}
    />
  );
};

export default RegisterPageContainer;
