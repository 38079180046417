import { FormHelperText, Paper, Typography } from "@mui/material";
import React from "react";
import BaseInput, { inputType } from "../../common/components/inputs/baseInput";
import { makeStyles } from "@mui/styles";
import SolidButton from "../../common/components/buttons/solidButton";
import OtpInput from "../../common/components/inputs/otpInput";
import CustomAlert from "../../common/components/customAlert";

const useStyles = makeStyles((theme) => {
  return {
    pageContainer: {
      minHeight: "100vh",
      justifyContent: "center",
      alignItems: "center",
    },
    formContainer: {
      width: "90vw",
      margin: "auto",
      paddingTop: "24px",
      paddingBottom: "24px",
      paddingLeft: "16px",
      paddingRight: "16px",
      [theme.breakpoints.up("md")]: {
        width: "50vw",
        paddingLeft: "56px",
        paddingRight: "56px",
        paddingTop: "44px",
        paddingBottom: "44px",
      },
    },
    formHeader: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "16px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "24px",
      },
    },
    formContent: {
      display: "flex",
      flexDirection: "column",
    },
  };
});

const ForgotPasswordView = ({
  email,
  password,
  setEmail,
  setPassword,
  onLoginClick,
  emailError,
  passwordError,
  onVerifyClick,
  onResetPasswordClick,
  setConfirmPassword,
  confirmPasswordError,
  isSentEmail,
  confirmPassword,
  setOtp,
  isLoading,
  otpError,
  error,
  alertType,
  _handleAlertClose,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.pageContainer} elevation={0}>
      <CustomAlert
        isShow={error}
        message={error}
        onClose={_handleAlertClose}
        type={alertType}
      />
      <Paper elevation={3} className={classes.formContainer}>
        <div className={classes.formHeader}>
          <Typography variant="h5">
            {isSentEmail ? "Create New Password" : "Forgot Password"}
          </Typography>
        </div>

        {!isSentEmail ? (
          <BaseInput
            placeholder="Enter Email Id"
            label="Email Id"
            value={email}
            setValue={setEmail}
            error={emailError}
          />
        ) : (
          <div className={classes.formContent}>
            <div className="mt-3">
              <OtpInput getOtp={setOtp} />
              {otpError ? (
                <FormHelperText error={true}>{otpError}</FormHelperText>
              ) : null}
            </div>
            <BaseInput
              placeholder="Enter Password"
              label="Password"
              type={inputType.PASSWORD}
              value={password}
              setValue={setPassword}
              error={passwordError}
            />

            <BaseInput
              placeholder="Re-enter Password"
              customClasses="mt-3"
              label="Confirm Password"
              type={inputType.PASSWORD}
              value={confirmPassword}
              setValue={setConfirmPassword}
              error={confirmPasswordError}
            />
          </div>
        )}

        <SolidButton
          btnText={isSentEmail ? "RESET PASSWORD" : "VERIFY"}
          btnClick={isSentEmail ? onResetPasswordClick : onVerifyClick}
          customClasses="mt-3"
          isLoading={isLoading}
        />
      </Paper>
    </Paper>
  );
};

export default ForgotPasswordView;
