import { Snackbar, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";

export const AlertType = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

const useStyles = makeStyles((theme) => {
  return {
    alert: {
      display: "flex",
      flexDirection: "row",
    },
  };
});

const CustomAlert = ({ message, type = AlertType.ERROR, isShow, onClose }) => {
  const classes = useStyles();
  return (
    <Snackbar
      autoHideDuration={3000}
      open={isShow}
      onClose={onClose}
      className={classes.alert}
    >
      <Alert severity={type} onClose={onClose} className={classes.alert}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;
