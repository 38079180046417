import axios from "axios";
import { getBaseUrl, routes } from "../utils/constants";
import { getAccessToken } from "../utils/storageUtils";
import { clearStorage } from "../utils/storageUtils";
import { isObject } from "../utils/utils";

export const httpMethod = {
  POST: "post",
  GET: "get",
};

export const request = ({ requestMethod, url, data, isAddHeader = false }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: requestMethod,
      baseURL: getBaseUrl(),
      url: url,
      data: data,
      headers: isAddHeader
        ? { Authorization: `Bearer ${getAccessToken()}` }
        : {},
    })
      .then((response) => {
        if (
          (response && response.data && response.data.success) ||
          (response && response.data && response.data.status)
        ) {
          if (response.data.status == 401) {
            clearStorage();
            let keys = [];
            let tempMessage = "Please try again";

            if (isObject(response.data.Message)) {
              if (response.data.Message) {
                keys = Object.keys(response.data.Message);
              }
              if (keys && keys.length) {
                tempMessage = response.data.Message[keys[0]][0];
              }
            } else if (response.data.Message) {
              tempMessage = response.data.Message;
            }
            reject({ status: response.data.status, message: tempMessage });
          } else if (response.data.success || response.data.status == 200) {
            resolve(response.data);
          } else {
            let keys = [];
            let tempMessage = "Please try again";

            if (isObject(response.data.Message)) {
              if (response.data.Message) {
                keys = Object.keys(response.data.Message);
              }
              if (keys && keys.length) {
                tempMessage = response.data.Message[keys[0]][0];
              }
            } else if (response.data.Message) {
              tempMessage = response.data.Message;
            }
            reject({ status: response.data.status, message: tempMessage });
          }
        }
      })
      .catch((error) => {
        console.log('msg', error.status);
        reject({ status: 500, message: 'Something went wrong. Please try again' });
      });
  });
};
