import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { darkGrey } from "../../common/colors";
import ScrollToTop from "../../common/components/scrollToTop";

const useStyles = makeStyles((theme) => {
  return {
    listBullet: {
      minWidth: "5px",
      width: "5px",
      height: "5px",
      borderRadius: "10px",
      marginTop: "8px",
      backgroundColor: darkGrey,
      [theme.breakpoints.up("md")]: {
        minWidth: "10px",
        width: "10px",
        height: "10px",
      },
    },
  };
});

const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <div className="container mt-5">
      <ScrollToTop />
      <Typography variant="h1" className="text-center">
        Privacy Policy
      </Typography>

      <Typography variant="body1" className="mt-3 text-center">
        This Privacy Policy governs the manner in which Bonny Auto collects,
        uses, maintains and discloses information collected from users (each, a
        “User”) of the https://www.bonnyauto.co.in website ('Site'). This
        privacy policy applies to the Site and all products and services offered
        by BonnyAuto.
      </Typography>

      <Typography variant="body1" className="mt-5">
        We may collect personal identification information from Users in a
        variety of ways, including, but not limited to, when Users visit our
        site, register on the site, fill out a form, and in connection with
        other activities, services, features or resources we make available on
        our Site. Users may be asked for, as appropriate, name, email address,
        mailing address, phone number. Users may, however, visit our Site
        anonymously. We will collect personal identification information from
        Users only if they voluntarily submit such information to us. Users can
        always refuse to supply personally identification information, except
        that it may prevent them from engaging in certain Site related
        activities.
      </Typography>

      <Typography variant="h3" className="mt-5">
        How we use collected information
      </Typography>

      <Typography variant="body1" className="mt-2">
        BonnyAuto may collect and use Users personal information for the
        following purposes:
      </Typography>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          To improve customer service Information you provide helps us respond
          to your customer service requests and support needs more efficiently.
        </Typography>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          To personalize user experience We may use information in the aggregate
          to understand how our Users as a group use the services and resources
          provided on our Site.
        </Typography>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          To run a promotion, contest, survey or other Site feature To send
          Users information they agreed to receive about topics we think will be
          of interest to them.
        </Typography>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          To improve our Site We may use feedback you provide to improve our
          products and services.
        </Typography>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          To share some information (other than your personal information like
          your name, address, email address or telephone number) with
          third-party advertising companies and/or ad agencies to serve ads when
          you visit our Web site.
        </Typography>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className={`${classes.listBullet}`} />
        <Typography variant="body2" className="ms-3">
          To send periodic emails
        </Typography>
      </div>

      <Typography variant="body2" className="mt-2">
        It may also be used to respond to their inquiries, questions, and/or
        other requests.
      </Typography>

      <Typography variant="body2">
        If User decides to opt-in to our mailing list, they will receive emails
        that may include company news, updates, related product or service
        information, etc. If at any time the User would like to unsubscribe from
        receiving future emails, we include detailed unsubscribe instructions at
        the bottom of each email or User may contact us via our Site.
      </Typography>

      <Typography variant="h3" className="mt-5">
        How we protect your information
      </Typography>

      <Typography variant="body1" className="mt-2">
        {
          "We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.\n\nSensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures."
        }
      </Typography>

      <Typography variant="h3" className="mt-5">
        Your acceptance of these terms
      </Typography>

      <Typography variant="body1" className="mt-2">
        {
          "By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes."
        }
      </Typography>

      <Typography variant="h3" className="mt-5">
        Data Grievance Officer
      </Typography>

      <Typography variant="body1" className="mt-2">
        {"Name: Mr. Mayur Daftary"}
      </Typography>
      <Typography variant="body1" className="mt-2">
        {"Address : 1, Ravapar Rd, Sardar Nagar, Morbi, Gujarat 363641"}
      </Typography>
      <Typography variant="body1" className="mt-2">
        {"Email id: info@bonnyauto.com"}
      </Typography>
    </div>
  );
};

export default PrivacyPolicy;
