import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";
import { primaryDarkBlue } from "../../../common/colors";

const useStyles = makeStyles((theme) => {
  return {
    itemImage: {
      width: "100%",
      height: "140px",
      objectFit: "contain",
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "140px",
      },
    },
    container: {
      margin: "0px 16px",
      width: "45vw",
      minWidth: "45vw",
      height: "90%",
      minHeight: "90%",
      backgroundColor: "white",
      borderRadius: "20px",
      display: "block",
      maxLines: 2,
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "30%",
        minWidth: "30%",
      },
    },
    offerItemImage: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "100%",
      },
    },
    subContainer: {
      padding: "16px 16px 0px 16px",
      [theme.breakpoints.up("md")]: {
        padding: "32px 32px 0px 32px",
      },
    },
    title: {
      fontSize: "16px",
      fontWeight: 600,
      color: primaryDarkBlue,
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        fontSize: "22px",
      },
    },
  };
});

const OfferItem = ({
  linkTo,
  imageSrc,
  title,
  offerItemImage,
  onClick = () => {},
  index,
  showCursor = false,
}) => {
  console.log(onClick);
  const classes = useStyles();
  if (linkTo) {
    return (
      <Link
        to={linkTo}
        className={classes.container}
        data-aos="fade-up"
        data-aos-delay={400 * index}
      >
        <div className={classes.subContainer}>
          <img src={imageSrc} className={classes.itemImage} />
          <p className={`${classes.title}`}>{title}</p>
        </div>
        <img src={offerItemImage} className={classes.offerItemImage} />
      </Link>
    );
  } else {
    return (
      <div
        className={`${classes.container} ${showCursor ? "cursorPointer" : ""}`}
        onClick={onClick}
        data-aos="fade-up"
        data-aos-delay={400 * index}
      >
        <div className={classes.subContainer}>
          <img src={imageSrc} alt="insurance" className={classes.itemImage} />
          <p className={`${classes.title}`}>{title}</p>
        </div>
        <img src={offerItemImage} className={classes.offerItemImage} />
      </div>
    );
  }
};

export default OfferItem;
