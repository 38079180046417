import React from "react";
import { Navigate, Route } from "react-router-dom";
import { routes } from "../utils/constants";
import ScrollToTop from "./scrollToTop";

const ProtectedRoutes = ({ token, children }) => {
  // if (!token) {
  //   return <Navigate to={routes.LOGIN} />;
  // }

  return <ScrollToTop>{children}</ScrollToTop>;
};

export default ProtectedRoutes;
