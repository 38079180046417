import React from "react";
import { SubSectionTitle } from "../../pages/bonnyAutoHome/components/services";
import { makeStyles } from "@mui/styles";
import AwardsLogo from "../../assets/images/awards_and_recognition.png";
import Award from "../../assets/images/award_logo.png";
import { Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import { awards } from "../utils/constants";

const useStyles = makeStyles((theme) => {
  return {
    hideScrollBar: {
      msOverflowStyle: "none",
      scrollbarWidth: "none",
    },
    width25: {
      width: "60vw",
      [theme.breakpoints.up("md")]: {
        width: "30vw",
      },
    },
    insuranceCompanyLogo: {
      objectFit: "cover",
      width: "40vw",
      [theme.breakpoints.up("md")]: {
        width: "20vw",
      },
    },
    marqueeContainer: {
      backgroundColor: "white",
      paddingTop: "24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        paddingTop: "56px",
      },
    },
  };
});

const AwardsAndRecognition = () => {
  const classes = useStyles();
  return (
    <div>
      <div className="d-flex flex-column align-items-center">
        <SubSectionTitle
          imageSrc={AwardsLogo}
          title="Awards & Recognition"
          smallImage={true}
        />
      </div>

      <div className={classes.marqueeContainer}>
        <Marquee className="">
          {awards.map((item) => {
            return (
              <div className={classes.width25}>
                <img
                  src={item}
                  className={`${classes.insuranceCompanyLogo} img-fluid mx-auto`}
                />
              </div>
            );
          })}
        </Marquee>
      </div>
    </div>
  );
};

export default AwardsAndRecognition;
