import { Input, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => {
  return {
    otpContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    inputContainer: {
      alignSelf: "center",
      borderRadius: "10px",
      height: "58px",
      width: "43px",
    },
    input: {
      height: "56px",
      width: "40px",
      borderRadius: "10px",
      margin: "auto",
    },
  };
});

const OtpInput = ({
  getOtp,
  setOtpString,
  otpInputStyles = [],
  noOfInputs = 6,
  initialValue = [],
}) => {
  const classes = useStyles();
  const [otp, setOtp] = useState(
    initialValue && initialValue.length ? initialValue : []
  );
  const [isNotNumber, setIsNotNumber] = useState();
  const [focused, setFocused] = useState(-1);

  let otpTextInput = [];
  const inputs = Array(noOfInputs).fill(0);

  const focusPrevious = (index) => {
    if (otp[index]) {
      let tempOtp = [...otp];
      tempOtp.pop();
      setOtp(tempOtp);
      if (index !== 0) {
        otpTextInput[index - 1].focus();
      }
      var otpString = tempOtp.join("");
      if (setOtpString) {
        setOtpString(otpString);
      }
    }
  };

  const focusNext = (index, value) => {
    var isnum = /^\d+$/.test(value);
    console.log(value, isnum);
    if (isnum) {
      if (index < otpTextInput.length - 1 && value) {
        otpTextInput[index + 1].focus();
      }
      if (index === otpTextInput.length - 1) {
        otpTextInput[index].blur();
      }
      const tempOtp = [...otp];
      tempOtp[index] = value;
      setOtp(tempOtp);
      var otpString = tempOtp.join("");
      if (setOtpString) {
        setOtpString(otpString);
      }
      if (tempOtp && otpString.length === noOfInputs) {
        var isnum = /^\d+$/.test(otpString);
        if (isnum) {
          getOtp(otpString);
        }
      }
    }
  };

  const getInputValue = (index) => {
    if (otp.length > index) {
      return otp[index];
    }

    return "";
  };

  return (
    <div style={otpInputStyles}>
      <div className={classes.otpContainer}>
        {inputs.map((item, index) => {
          return (
            <div className={classes.inputContainer}>
              <TextField
                inputProps={{ style: { textAlign: "center" } }}
                className={classes.input}
                onChange={(event) => {
                  if (event.nativeEvent.inputType === "deleteContentBackward") {
                    focusPrevious(index);
                  } else {
                    focusNext(index, event.target.value);
                  }
                }}
                value={getInputValue(index)}
                variant="standard"
                inputRef={(ref) => (otpTextInput[index] = ref)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OtpInput;
