import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import FaqExpandLogo from "../../assets/images/faq_expand_arrow.png";
import { default as FaqLogo } from "../../assets/images/faq.svg";

const useStyles = makeStyles((theme) => {
  return {
    subSectionImage: {
      width: "200px",
      height: "150px",
    },
    expandIcon: {
      width: "24px",
      height: "24px",
    },
    accordionContainer: {
      backgroundColor: "#F9F9F9",
      borderRadius: "10px",
      marginTop: "40px",
      "&.MuiAccordion-root:before": {
        backgroundColor: "white",
        borderRadius: "10px",
      },
    },
    accordionSummary: {
      borderRadius: "10px",
    },
  };
});

const Faq = ({ faqs }) => {
  const classes = useStyles();
  return (
    <div className="d-flex flex-column align-items-center py-5" id="faqs">
      <div className="d-flex flex-md-row align-items-center flex-column">
        <img src={FaqLogo} className={classes.subSectionImage} />
        <Typography variant="h3" className="mb-0 mt-3 mt-md-0">
          FAQs
        </Typography>
      </div>

      {faqs.map((item) => {
        return (
          <Accordion className={`${classes.accordionContainer} mx-3 mx-md-0`} elevation={0}>
            <AccordionSummary
              expandIcon={
                <img src={FaqExpandLogo} className={classes.expandIcon} />
              }
              aria-controls="panel1a-content"
              className={classes.accordionSummary}
              id="panel1a-header"
            >
              <Typography variant="body1">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default Faq;
