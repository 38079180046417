import React, { useState } from "react";
import LifeInsuranceView from "./lifeInsuranceView";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails } from "../../redux/slices/userDetailsSlice";
import {
  addLifeInsuranceOtherPlan,
  addLifeInsuranceRetirementPlan,
  addLifeInsuranceTermPlan,
  addLifeInsuranceWorkerRetirementPlan,
} from "../../common/services/services";
import {
  emailRegex,
  genderTypes,
  phoneRegex,
  planTypes,
} from "../../common/utils/constants";

const formTypes = [
  {
    title: "Life Insurance Retirement Plan",
    imagePath: require("../../assets/images/services.png"),
    type: "RETIREMENT_PLAN",
  },
  {
    title: "Life Insurance Term Plan",
    imagePath: require("../../assets/images/services.png"),
    type: "TERM_PLAN",
  },
  {
    title: "Life Insurance Worker Retirement Plan",
    imagePath: require("../../assets/images/services.png"),
    type: "WORKER_RETIREMENT_PLAN",
  },
  {
    title: "Life Insurance Other Plan",
    imagePath: require("../../assets/images/services.png"),
    type: "OTHER_PLAN",
  },
];

const LifeInsuranceContainer = ({}) => {
  const dispatch = useDispatch();
  const userDetailsObserver = useSelector(selectUserDetails);
  const userDetails = userDetailsObserver.userDetails;

  const [apiError, setApiError] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState(userDetails.name);
  const [nameError, setNameError] = useState(null);
  const [email, setEmail] = useState(userDetails.email);
  const [emailError, setEmailError] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(userDetails.mobile_no);
  const [numberError, setNumberError] = useState(null);
  const [city, setCity] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [state, setState] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [age, setAge] = useState(null);
  const [ageError, setAgeError] = useState(null);
  const [gender, setGender] = useState(genderTypes[0].value);
  const [dob, setDob] = useState(new Date());
  const [companyName, setCompanyName] = useState(null);
  const [companyNameError, setCompanyNameError] = useState(null);
  const [numberEmployees, setNumberEmployees] = useState(null);
  const [numberEmployeesError, setNumberEmployeesError] = useState(null);
  const [typePlan, setTypePlan] = useState(planTypes[0].value);

  const _showModal = (type) => {
    setModalType(type);
  };

  const _onSubmitClick = () => {
    if (!name || name.trim() === "") {
      setNameError("Enter name");
      return;
    } else {
      setNameError(null);
    }

    if (!email || email.trim() === "") {
      setEmailError("Enter email");
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter valid email");
      return;
    } else {
      setEmailError(null);
    }

    if (!mobileNumber || mobileNumber.trim() === "") {
      setNumberError("Enter name");
      return;
    } else if (mobileNumber.length !== 10 || !phoneRegex.test(mobileNumber)) {
      setNumberError("Enter valid number");
      return;
    } else {
      setNumberError(null);
    }

    if (!city || city.trim() === "") {
      setCityError("Enter city");
      return;
    } else {
      setCityError(null);
    }

    if (!state || state.trim() === "") {
      setStateError("Enter state");
      return;
    } else {
      setStateError(null);
    }

    if (modalType && modalType.type === "RETIREMENT_PLAN") {
      if (!age || age.trim() === "") {
        setAgeError("Enter age");
        return;
      } else {
        setAgeError(null);
      }

      setIsLoading(true);
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile_number", mobileNumber);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("age", age);

      addLifeInsuranceRetirementPlan(formData)
        .then((response) => {
          setIsLoading(false);
          setModalType("SUCCESS");
        })
        .catch((error) => {
          setIsLoading(false);
          setApiError(error.message);
        });
    } else if (modalType && modalType.type === "TERM_PLAN") {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile_number", mobileNumber);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("gender", gender);
      formData.append("date_of_birth", dob);

      addLifeInsuranceTermPlan(formData)
        .then((response) => {
          setIsLoading(false);
          setModalType("SUCCESS");
        })
        .catch((error) => {
          setIsLoading(false);
          setApiError(error.message);
        });
    } else if (modalType && modalType.type === "WORKER_RETIREMENT_PLAN") {
      if (!companyName || companyName.trim() === "") {
        setCompanyNameError("Enter company name");
        return;
      } else {
        setCompanyNameError(null);
      }
      if (!numberEmployees || numberEmployees.trim() === "") {
        setNumberEmployeesError("Enter number of employees");
        return;
      } else if (
        !phoneRegex.test(numberEmployees) ||
        Number(numberEmployees) === 0
      ) {
        setNumberEmployeesError("Enter valid number of employees");
        return;
      } else {
        setNumberEmployeesError(null);
      }

      setIsLoading(true);
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile_number", mobileNumber);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("company_name", companyName);
      formData.append("number_of_employees", Number(numberEmployees));

      addLifeInsuranceWorkerRetirementPlan(formData)
        .then((response) => {
          setIsLoading(false);
          setModalType("SUCCESS");
        })
        .catch((error) => {
          setIsLoading(false);
          setApiError(error.message);
        });
    } else {
      if (!age || age.trim() === "") {
        setAgeError("Enter age");
        return;
      } else {
        setAgeError(null);
      }

      setIsLoading(true);
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile_number", mobileNumber);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("type_of_plan", typePlan);
      formData.append("age", age);

      addLifeInsuranceOtherPlan(formData)
        .then((response) => {
          setIsLoading(false);
          setModalType("SUCCESS");
        })
        .catch((error) => {
          setIsLoading(false);
          setApiError(error.message);
        });
    }
  };

  return (
    <LifeInsuranceView
      apiError={apiError}
      setApiError={setApiError}
      modalType={modalType}
      isLoading={isLoading}
      _onSubmitClick={_onSubmitClick}
      _showModal={_showModal}
      formTypes={formTypes}
      name={name}
      setName={setName}
      nameError={nameError}
      email={email}
      setEmail={setEmail}
      emailError={emailError}
      mobileNumber={mobileNumber}
      setMobileNumber={setMobileNumber}
      numberError={numberError}
      city={city}
      setCity={setCity}
      cityError={cityError}
      state={state}
      setState={setState}
      stateError={stateError}
      age={age}
      setAge={setAge}
      ageError={ageError}
      gender={gender}
      setGender={setGender}
      dob={dob}
      setDob={setDob}
      companyName={companyName}
      setCompanyName={setCompanyName}
      companyNameError={companyNameError}
      numberEmployees={numberEmployees}
      setNumberEmployees={setNumberEmployees}
      numberEmployeesError={numberEmployeesError}
      typePlan={typePlan}
      setTypePlan={setTypePlan}
    />
  );
};

export default LifeInsuranceContainer;
