import { createSlice } from "@reduxjs/toolkit";
import { getAccessToken, getUser } from "../../common/utils/storageUtils";

const initialState = {
  accessToken: getAccessToken(),
  userDetails: getUser(),
};

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: initialState,
  reducers: {
    setUserAccessToken: {
      reducer: (state, action) => {
        state.accessToken = action.payload;
      },
    },
    setUserDetails: {
      reducer: (state, action) => {
        state.userDetails = action.payload;
      },
    },
    clearRedux: {
      reducer: (state, action) => {
        state.accessToken = null;
        state.userDetails = null;
      },
    },
  },
});

export const selectUserDetails = (state) => state.userDetails;
export const { setUserAccessToken, setUserDetails, clearRedux } =
  userDetailsSlice.actions;
export default userDetailsSlice.reducer;
