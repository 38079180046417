import React from "react";
import DropDown from "../../../common/components/dropDown";
import BaseInput from "../../../common/components/inputs/baseInput";
import { genderTypes, planTypes } from "../../../common/utils/constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";

const ModalForms = ({
  modalType,
  companyName,
  setCompanyName,
  companyNameError,
  city,
  setCity,
  cityError,
  state,
  setState,
  stateError,
  age,
  setAge,
  ageError,
  name,
  setName,
  nameError,
  mobileNumber,
  setMobileNumber,
  numberError,
  email,
  setEmail,
  emailError,
  gender,
  setGender,
  dob,
  setDob,
  dobError,
  numberEmployees,
  setNumberEmployees,
  numberEmployeesError,
  typePlan,
  setTypePlan,
}) => {
  return (
    <div className="d-flex flex-column">
      <BaseInput
        placeholder="Enter Name"
        customClasses="mt-3"
        label="Name"
        value={name}
        setValue={setName}
        error={nameError}
      />
      <BaseInput
        placeholder="Enter Email"
        customClasses="mt-3"
        label="Email"
        value={email}
        setValue={setEmail}
        error={emailError}
      />
      <BaseInput
        placeholder="Enter Mobile Number"
        customClasses="mt-3"
        label="Mobile Number"
        value={mobileNumber}
        setValue={setMobileNumber}
        error={numberError}
      />
      <BaseInput
        placeholder="Enter City"
        customClasses="mt-3"
        label="City"
        value={city}
        setValue={setCity}
        error={cityError}
      />
      <BaseInput
        placeholder="Enter State"
        customClasses="mt-3"
        label="State"
        value={state}
        setValue={setState}
        error={stateError}
      />
      {modalType && modalType.type === "RETIREMENT_PLAN" ? (
        <BaseInput
          placeholder="Enter Age"
          customClasses="mt-3"
          label="Age"
          value={age}
          setValue={setAge}
          error={ageError}
        />
      ) : modalType && modalType.type === "TERM_PLAN" ? (
        <>
          <DropDown
            list={genderTypes}
            label="Gender"
            className="mt-3"
            selectedValue={gender}
            setSelectedValue={setGender}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Birth"
              value={dob}
              onChange={(newValue) => {
                setDob(newValue);
              }}
              renderInput={(params) => {
                return <TextField {...params} className="mt-3" />;
              }}
            />
          </LocalizationProvider>
        </>
      ) : modalType && modalType.type === "WORKER_RETIREMENT_PLAN" ? (
        <>
          <BaseInput
            placeholder="Enter Company Name"
            customClasses="mt-3"
            label="Company Name"
            value={companyName}
            setValue={setCompanyName}
            error={companyNameError}
          />
          <BaseInput
            placeholder="Enter No of Employees"
            customClasses="mt-3"
            label="No of Employees"
            value={numberEmployees}
            setValue={setNumberEmployees}
            error={numberEmployeesError}
          />
        </>
      ) : (
        <>
          <DropDown
            list={planTypes}
            label="Company"
            className="mt-3"
            selectedValue={typePlan}
            setSelectedValue={setTypePlan}
          />
          <BaseInput
            placeholder="Enter Age"
            customClasses="mt-3"
            label="Age"
            value={age}
            setValue={setAge}
            error={ageError}
          />
        </>
      )}
    </div>
  );
};

export default ModalForms;
