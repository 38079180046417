import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

export const inputType = {
  TEXT: "text",
  PASSWORD: "password",
};

const useStyles = makeStyles((theme) => {
  return {
    errorText: {
      fontSize: "16px",
      color: "red",
      marginLeft: "8px",
      marginTop: "4px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
    },
    textfield: {
      maxHeight: "50px",
    }
  };
});

const BaseInput = ({
  placeholder = "",
  customClasses = "",
  value,
  label = "",
  setValue = () => {},
  type = inputType.TEXT,
  error,
  containerClasses = "",
  multiline = false,
  rows = 1,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  if (type === inputType.PASSWORD) {
    return (
      <div className={containerClasses}>
        <TextField
          id="outlined-basic"
          label={label}
          variant="outlined"
          placeholder={placeholder}
          value={value}
          size={50}
          type={showPassword ? inputType.TEXT : inputType.PASSWORD}
          className={customClasses}
          onChange={(event) => setValue(event.target.value)}
          InputProps={
            type === inputType.PASSWORD
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
        {error ? <span className={classes.errorText}>{error}</span> : null}
      </div>
    );
  }
  return (
    <div className={`${classes.container} ${containerClasses}`}>
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        type={type}
        size="small"
        className={`${classes.textfield} ${customClasses}`}
        error={error}
        rows={rows}
        multiline={multiline}
        onChange={(event) => setValue(event.target.value)}
      />
      {error ? <span className={classes.errorText}>{error}</span> : null}
    </div>
  );
};

export default BaseInput;
