import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const ServiceItem = ({
  linkTo,
  imageSrc,
  title,
  description,
  onClick = () => {},
}) => {
  if (linkTo) {
    return (
      <Link to={linkTo} className={"mx-3 service_item col-8 col-md"}>
        <div className="d-flex flex-column text-center align-items-center">
          <img src={imageSrc} alt="insurance" width={64} height={64} />
          <Typography variant="h4" className="mt-4 mb-4">
            {title}
          </Typography>
          {description ? (
            <Typography variant="body1">{description}</Typography>
          ) : null}
        </div>
      </Link>
    );
  } else {
    return (
      <div className={"mx-3 service_item col-8 col-md"}>
        <div
          className="d-flex flex-column text-center align-items-center cursorPointer"
          onClick={onClick}
        >
          <img
            src={imageSrc}
            alt="insurance"
            className="img-fluid"
            width={64}
            height={64}
          />
          <Typography variant="h4" className="mt-4 mb-4">
            {title}
          </Typography>
          {description ? (
            <Typography variant="body1">{description}</Typography>
          ) : null}
        </div>
      </div>
    );
  }
};

export default ServiceItem;
