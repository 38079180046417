import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { buyCar, exchangeCar, sellCar } from "../../common/services/services";
import {
  carTypes,
  emailRegex,
  engineTypes,
  phoneRegex,
} from "../../common/utils/constants";
import { selectUserDetails } from "../../redux/slices/userDetailsSlice";
import CarExchangeView from "./carExchangeView";

const formTypes = [
  {
    title: "Buy Car",
    imagePath: require("../../assets/images/services.png"),
    type: "BUY_CAR",
  },
  {
    title: "Sell Car",
    imagePath: require("../../assets/images/services.png"),
    type: "SELL_CAR",
  },
  {
    title: "Exchange Car",
    imagePath: require("../../assets/images/services.png"),
    type: "EXCHANGE_CAR",
  },
];

const CarExchangeContainer = () => {
  const userDetailsObserver = useSelector(selectUserDetails);
  const userDetails = userDetailsObserver.userDetails;
  const [modalType, setModalType] = useState(null);
  const [carName, setCarName] = useState(null);
  const [carNameError, setCarNameError] = useState(null);
  const [newCarName, setNewCarName] = useState(null);
  const [newCarNameError, setNewCarNameError] = useState(null);
  const [budget, setBudget] = useState(null);
  const [budgetError, setBudgetError] = useState(null);
  const [state, setState] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [carNumber, setCarNumber] = useState(null);
  const [carNumberError, setCarNumberError] = useState(null);
  const [distance, setDistance] = useState(null);
  const [distanceError, setDistanceError] = useState(null);
  const [description, setDescription] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [carType, setCarType] = useState(carTypes[0].value);
  const [engineType, setEngineType] = useState(engineTypes[0].value);
  const [ownerDetails, setOwnerDetails] = useState(null);
  const [ownerDetailsError, setOwnerDetailsError] = useState(null);
  const [name, setName] = useState(userDetails.name);
  const [nameError, setNameError] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(userDetails.mobile_no);
  const [mobileNumberError, setMobileNumberError] = useState(null);
  const [email, setEmail] = useState(userDetails.email);
  const [emailError, setEmailError] = useState(null);
  const [city, setCity] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [firstSideImage, setFirstSideImage] = useState(null);
  const [secondSideImage, setSecondSideImage] = useState(null);
  const [thirdSideImage, setThirdSideImage] = useState(null);
  const [forthSideImage, setForthSideImage] = useState(null);
  const [interiorImage, setInteriorImage] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [oldCarName, setOldCarName] = useState(null);
  const [oldCarNameError, setOldCarNameError] = useState(null);

  const _showModal = (type) => {
    setModalType(type);
  };

  const _onSubmitClick = () => {
    if (!name || name.trim() === "") {
      setNameError("Enter name");
      return;
    } else {
      setNameError(null);
    }

    if (!email || email.trim() === "") {
      setEmailError("Enter email");
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter valid email");
      return;
    } else {
      setEmailError(null);
    }

    if (!mobileNumber || mobileNumber.trim() === "") {
      setMobileNumberError("Enter name");
      return;
    } else if (mobileNumber.length !== 10 || !phoneRegex.test(mobileNumber)) {
      setMobileNumberError("Enter valid number");
      return;
    } else {
      setMobileNumberError(null);
    }

    if (!city || city.trim() === "") {
      setCityError("Enter city");
      return;
    } else {
      setCityError(null);
    }

    if (!state || state.trim() === "") {
      setStateError("Enter state");
      return;
    } else {
      setStateError(null);
    }
    if (modalType.type === "BUY_CAR") {
      if (!carName || carName.trim() === "") {
        setCarNameError("Enter car name");
        return;
      } else {
        setCarNameError(null);
      }

      if (!budget || budget.trim() === "") {
        setBudgetError("Enter budget");
        return;
      } else {
        setBudgetError(null);
      }

      setIsLoading(true);
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile_number", mobileNumber);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("car_type", carType);
      formData.append("car_name", carName);
      formData.append("engine_type", engineType);
      formData.append("budget", budget);

      buyCar(formData)
        .then((response) => {
          setIsLoading(false);
          setModalType("SUCCESS");
        })
        .catch((error) => {
          setIsLoading(false);
          setApiError(error.message);
        });
    } else if (modalType.type === "SELL_CAR") {
      if (!distance || distance.trim() === "") {
        setDistanceError("Enter distance");
        return;
      } else {
        setDistanceError(null);
      }

      if (!ownerDetails || ownerDetails.trim() === "") {
        setOwnerDetailsError("Enter owner details");
        return;
      } else {
        setOwnerDetailsError(null);
      }

      if (
        !firstSideImage ||
        !secondSideImage ||
        !thirdSideImage ||
        !forthSideImage
      ) {
        setImageError("Select all images");
        return;
      } else {
        setImageError(null);
      }

      if (!description || description.trim() === "") {
        setDescriptionError("Enter description");
        return;
      } else {
        setDescriptionError(null);
      }

      setIsLoading(true);
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile_number", mobileNumber);
      formData.append("engine_type", engineType);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("km_completed", distance);
      formData.append("owner_details", ownerDetails);
      formData.append("product_type", engineType);
      formData.append("first_side_img", firstSideImage, firstSideImage.name);
      formData.append("second_side_img", secondSideImage, secondSideImage.name);
      formData.append("third_side_img", thirdSideImage, thirdSideImage.name);
      formData.append("fourth_side_img", forthSideImage, forthSideImage.name);
      formData.append("interior_img", interiorImage, interiorImage.name);
      formData.append("product_description", description);

      sellCar(formData)
        .then((response) => {
          setIsLoading(false);
          setModalType("SUCCESS");
        })
        .catch((error) => {
          setIsLoading(false);
          setApiError(error.message);
        });
    } else {
      if (!carName || carName.trim() === "") {
        setCarNameError("Enter car name");
        return;
      } else {
        setCarNameError(null);
      }

      if (!distance || distance.trim() === "") {
        setDistanceError("Enter distance");
        return;
      } else {
        setDistanceError(null);
      }

      if (!budget || budget.trim() === "") {
        setBudgetError("Enter budget");
        return;
      } else {
        setBudgetError(null);
      }

      if (!newCarName || newCarName.trim() === "") {
        setNewCarNameError("Enter car name");
        return;
      } else {
        setNewCarNameError(null);
      }

      if (
        !firstSideImage ||
        !secondSideImage ||
        !thirdSideImage ||
        !forthSideImage
      ) {
        setImageError("Select all images");
        return;
      } else {
        setImageError(null);
      }

      setIsLoading(true);
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile_number", mobileNumber);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("old_car_name", carName);
      formData.append("km_completed", distance);
      formData.append("budget", budget);
      formData.append("new_car_name", carName);
      formData.append("first_side_img", firstSideImage, firstSideImage.name);
      formData.append("second_side_img", secondSideImage, secondSideImage.name);
      formData.append("third_side_img", thirdSideImage, thirdSideImage.name);
      formData.append("fourth_side_img", forthSideImage, forthSideImage.name);
      formData.append("interior_img", interiorImage, interiorImage.name);

      exchangeCar(formData)
        .then((response) => {
          setIsLoading(false);
          setModalType("SUCCESS");
        })
        .catch((error) => {
          setIsLoading(false);
          setApiError(error.message);
        });
    }
  };

  return (
    <CarExchangeView
      modalType={modalType}
      _showModal={_showModal}
      _onSubmitClick={_onSubmitClick}
      formTypes={formTypes}
      carName={carName}
      setCarName={setCarName}
      carNameError={carNameError}
      budget={budget}
      setBudget={setBudget}
      budgetError={budgetError}
      state={state}
      setState={setState}
      stateError={stateError}
      carNumber={carNumber}
      setCarNumber={setCarNumber}
      carNumberError={carNumberError}
      distance={distance}
      setDistance={setDistance}
      distanceError={distanceError}
      description={description}
      setDescription={setDescription}
      descriptionError={descriptionError}
      carType={carType}
      setCarType={setCarType}
      engineType={engineType}
      setEngineType={setEngineType}
      ownerDetails={ownerDetails}
      setOwnerDetails={setOwnerDetails}
      ownerDetailsError={ownerDetailsError}
      apiError={apiError}
      setApiError={setApiError}
      isLoading={isLoading}
      name={name}
      setName={setName}
      nameError={nameError}
      mobileNumber={mobileNumber}
      setMobileNumber={setMobileNumber}
      mobileNumberError={mobileNumberError}
      firstSideImage={firstSideImage}
      setFirstSideImage={setFirstSideImage}
      secondSideImage={secondSideImage}
      setSecondSideImage={setSecondSideImage}
      thirdSideImage={thirdSideImage}
      setThirdSideImage={setThirdSideImage}
      forthSideImage={forthSideImage}
      setForthSideImage={setForthSideImage}
      interiorImage={interiorImage}
      setInteriorImage={setInteriorImage}
      imageError={imageError}
      newCarName={newCarName}
      setNewCarName={setNewCarName}
      newCarNameError={newCarNameError}
      email={email}
      setEmail={setEmail}
      emailError={emailError}
      city={city}
      setCity={setCity}
      cityError={cityError}
    />
  );
};

export default CarExchangeContainer;
