import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  newCarLoans,
  topUpLoans,
  usedCarLoans,
} from "../../common/services/services";
import {
  ageTypes,
  emailRegex,
  engineTypes,
} from "../../common/utils/constants";
import { selectUserDetails } from "../../redux/slices/userDetailsSlice";
import CarLoanView from "./carLoansView";

const CarLoanContainer = () => {
  const userDetailsObserver = useSelector(selectUserDetails);
  const userDetails = userDetailsObserver.userDetails;
  const [modalType, setModalType] = useState(null);
  const [carName, setCarName] = useState(null);
  const [carNameError, setCarNameError] = useState(null);
  const [budget, setBudget] = useState(null);
  const [budgetError, setBudgetError] = useState(null);
  const [loanYears, setLoanYears] = useState(null);
  const [loanYearsError, setLoanYearsError] = useState(null);
  const [oldLoan, setOldLoan] = useState(null);
  const [oldLoanError, setOldLoanError] = useState(null);
  const [engineType, setEngineType] = useState(engineTypes[0].value);
  const [ageType, setAgeType] = useState(ageTypes[0].value);
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(userDetails.name);
  const [nameError, setNameError] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(userDetails.mobile_no);
  const [numberError, setNumberError] = useState(null);
  const [email, setEmail] = useState(userDetails.email);
  const [emailError, setEmailError] = useState(null);
  const [companyName, setCompanyName] = useState();
  const [companyNameError, setCompanyNameError] = useState(null);
  const [state, setState] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [city, setCity] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [carNumber, setCarNumber] = useState(null);
  const [carNumberError, setCarNumberError] = useState(null);

  const _showModal = (type) => {
    setModalType(type);
  };

  const _onSubmitClick = () => {
    if (modalType.type === "NEW_CAR_LOAN") {
      if (!name || name.trim() === "") {
        setNameError("Enter name");
        return;
      } else {
        setNameError(null);
      }

      if (!email || email.trim() === "") {
        setEmailError("Enter email");
        return;
      } else if (!emailRegex.test(email)) {
        setEmailError("Enter valid email");
        return;
      } else {
        setEmailError(null);
      }

      if (!mobileNumber || mobileNumber.trim() === "") {
        setNumberError("Enter name");
        return;
      } else if (mobileNumber.length !== 10) {
        setNumberError("Enter valid number");
        return;
      } else {
        setNumberError(null);
      }

      if (!city || city.trim() === "") {
        setCityError("Enter city");
        return;
      } else {
        setCityError(null);
      }

      if (!state || state.trim() === "") {
        setStateError("Enter state");
        return;
      } else {
        setStateError(null);
      }

      if (!companyName || companyName.trim() === "") {
        setCompanyNameError("Enter company name");
        return;
      } else {
        setCompanyNameError(null);
      }

      if (!carName || carName.trim() === "") {
        setCarNameError("Enter car name");
        return;
      } else {
        setCarNameError(null);
      }

      if (!budget || budget.trim() === "") {
        setBudgetError("Enter budget");
        return;
      } else {
        setBudgetError(null);
      }

      if (!loanYears || loanYears.trim() === "") {
        setLoanYearsError("Enter loan years");
        return;
      } else {
        setLoanYearsError(null);
      }

      setIsLoading(true);
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile_no", mobileNumber);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("company_name", companyName);
      formData.append("car_name", carName);
      formData.append("budget", budget);
      formData.append("engine_type", engineType);
      formData.append("loan_years", loanYears);

      newCarLoans(formData)
        .then((response) => {
          setIsLoading(false);
          setModalType("SUCCESS");
        })
        .catch((error) => {
          setIsLoading(false);
          setApiError(error.message);
        });
    } else if (modalType.type === "USED_CAR_LOAN") {
      if (!name || name.trim() === "") {
        setNameError("Enter name");
        return;
      } else {
        setNameError(null);
      }

      if (!email || email.trim() === "") {
        setEmailError("Enter email");
        return;
      } else if (!emailRegex.test(email)) {
        setEmailError("Enter valid email");
        return;
      } else {
        setEmailError(null);
      }

      if (!mobileNumber || mobileNumber.trim() === "") {
        setNumberError("Enter name");
        return;
      } else if (mobileNumber.length !== 10) {
        setNumberError("Enter valid number");
        return;
      } else {
        setNumberError(null);
      }

      if (!city || city.trim() === "") {
        setCityError("Enter city");
        return;
      } else {
        setCityError(null);
      }

      if (!state || state.trim() === "") {
        setStateError("Enter state");
        return;
      } else {
        setStateError(null);
      }

      if (!carName || carName.trim() === "") {
        setCarNameError("Enter car name");
        return;
      } else {
        setCarNameError(null);
      }

      if (!carNumber || carNumber.trim() === "") {
        setCarNumberError("Enter car number");
        return;
      } else {
        setCarNumberError(null);
      }

      if (!budget || budget.trim() === "") {
        setBudgetError("Enter budget");
        return;
      } else {
        setBudgetError(null);
      }

      if (!loanYears || loanYears.trim() === "") {
        setLoanYearsError("Enter loann years");
        return;
      } else {
        setLoanYearsError(null);
      }

      setIsLoading(true);
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile_no", mobileNumber);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("company_name", companyName);
      formData.append("car_name", carName);
      formData.append("car_number", carNumber);
      formData.append("budget", budget);
      formData.append("engine_type", engineType);
      formData.append("loan_years", loanYears);

      usedCarLoans(formData)
        .then((response) => {
          setIsLoading(false);
          setModalType("SUCCESS");
        })
        .catch((error) => {
          setIsLoading(false);
          setApiError(error.message);
        });
    } else {
      if (!name || name.trim() === "") {
        setNameError("Enter name");
        return;
      } else {
        setNameError(null);
      }

      if (!email || email.trim() === "") {
        setEmailError("Enter email");
        return;
      } else if (!emailRegex.test(email)) {
        setEmailError("Enter valid email");
        return;
      } else {
        setEmailError(null);
      }

      if (!mobileNumber || mobileNumber.trim() === "") {
        setNumberError("Enter name");
        return;
      } else if (mobileNumber.length !== 10) {
        setNumberError("Enter valid number");
        return;
      } else {
        setNumberError(null);
      }

      if (!city || city.trim() === "") {
        setCityError("Enter city");
        return;
      } else {
        setCityError(null);
      }

      if (!state || state.trim() === "") {
        setStateError("Enter state");
        return;
      } else {
        setStateError(null);
      }

      if (!oldLoan || oldLoan.trim() === "") {
        setCarNameError("Enter old loan");
        return;
      } else {
        setCarNameError(null);
      }

      if (!loanYears || loanYears.trim() === "") {
        setOldLoanError("Enter loan years");
        return;
      } else {
        setOldLoanError(null);
      }

      setIsLoading(true);
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile_no", mobileNumber);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("car", ageType);
      formData.append("old_loan", oldLoan);
      formData.append("loan_year", loanYears);

      topUpLoans()
        .then((response) => {
          setIsLoading(false);
          setModalType("SUCCESS");
        })
        .catch((error) => {
          setIsLoading(false);
          setApiError(error.message);
        });
    }
  };
  return (
    <CarLoanView
      modalType={modalType}
      _showModal={_showModal}
      _onSubmitClick={_onSubmitClick}
      carName={carName}
      setCarName={setCarName}
      carNameError={carNameError}
      budget={budget}
      setBudget={setBudget}
      budgetError={budgetError}
      engineType={engineType}
      setEngineType={setEngineType}
      loanYears={loanYears}
      setLoanYears={setLoanYears}
      loanYearsError={loanYearsError}
      oldLoan={oldLoan}
      setOldLoan={setOldLoan}
      oldLoanError={oldLoanError}
      apiError={apiError}
      setApiError={setApiError}
      isLoading={isLoading}
      ageType={ageType}
      setAgeType={setAgeType}
      name={name}
      setName={setName}
      nameError={nameError}
      mobileNumber={mobileNumber}
      setMobileNumber={setMobileNumber}
      numberError={numberError}
      email={email}
      setEmail={setEmail}
      emailError={emailError}
      companyName={companyName}
      setCompanyName={setCompanyName}
      companyNameError={companyNameError}
      city={city}
      setCity={setCity}
      cityError={cityError}
      state={state}
      setState={setState}
      stateError={stateError}
      carNumber={carNumber}
      setCarNumber={setCarNumber}
      carNumberError={carNumberError}
    />
  );
};

export default CarLoanContainer;
