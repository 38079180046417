import { Paper, Typography } from "@mui/material";
import React from "react";
import CustomAlert from "../../common/components/customAlert";
import CustomModal from "../../common/components/customModal";
import ScrollToTop from "../../common/components/scrollToTop";
import ServiceItem from "../../common/components/serviceItem";
import ModalForms from "./components/modalForms";

const LifeInsuranceView = ({
  apiError,
  setApiError,
  formTypes,
  modalType,
  _showModal,
  _onSubmitClick,
  isLoading,
  name,
  setName,
  nameError,
  mobileNumber,
  email,
  setEmail,
  emailError,
  setMobileNumber,
  mobileNumberError,
  state,
  setState,
  stateError,
  city,
  setCity,
  cityError,
  age,
  setAge,
  ageError,
  gender,
  setGender,
  dob,
  setDob,
  companyName,
  setCompanyName,
  companyNameError,
  numberEmployees,
  setNumberEmployees,
  numberEmployeesError,
  typePlan,
  setTypePlan,
}) => {
  return (
    <Paper className="container flex-grow-1" elevation={0}>
      <ScrollToTop />
      <CustomAlert
        message={apiError}
        onClose={() => setApiError(null)}
        isShow={apiError}
      />
      <CustomModal
        showModal={modalType ? true : false}
        isSuccess={modalType === "SUCCESS"}
        hideModal={() => _showModal(null)}
        positiveButtonCallBack={() => {
          if (modalType === "SUCCESS") {
            _showModal(null);
          } else {
            _onSubmitClick();
          }
        }}
        negativeButtonCallBack={() => _showModal(null)}
        title={modalType && modalType.title ? modalType.title : null}
        isPositiveButtonLoading={isLoading}
      >
        <ModalForms
          modalType={modalType}
          state={state}
          setState={setState}
          stateError={stateError}
          city={city}
          setCity={setCity}
          cityError={cityError}
          name={name}
          setName={setName}
          nameError={nameError}
          email={email}
          setEmail={setEmail}
          emailError={emailError}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          mobileNumberError={mobileNumberError}
          age={age}
          setAge={setAge}
          ageError={ageError}
          gender={gender}
          setGender={setGender}
          dob={dob}
          setDob={setDob}
          companyName={companyName}
          setCompanyName={setCompanyName}
          companyNameError={companyNameError}
          numberEmployees={numberEmployees}
          setNumberEmployees={setNumberEmployees}
          numberEmployeesError={numberEmployeesError}
          typePlan={typePlan}
          setTypePlan={setTypePlan}
        />
      </CustomModal>
      <div className="d-flex flex-row align-items-center my-3">
        <img
          src={require("../../assets/images/exchange.png")}
          alt="exchange"
          className="img-fluid"
          width={64}
          height={64}
        />
        <Typography className="ms-3" variant="caption">
          Life Insurance
        </Typography>
      </div>
      <div className="height70vh d-flex flex-row align-items-center">
        <div className="d-flex flex-row justify-content-md-between services_container">
          {formTypes.map((item) => {
            return (
              <ServiceItem
                title={item.title}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                imageSrc={item.imagePath}
                onClick={() =>
                  _showModal({ title: item.title, type: item.type })
                }
              />
            );
          })}
        </div>
      </div>
    </Paper>
  );
};

export default LifeInsuranceView;
