import {
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import { formTitleColor, primaryBlue } from "../colors";
import {
  nonMotorInsuranceTypes,
  routes,
  vehicleLoanItems,
} from "../utils/constants";
import {
  clearRedux,
  selectUserDetails,
} from "../../redux/slices/userDetailsSlice";
import { clearInsurances } from "../../redux/slices/insuranceSlice";
import { logout } from "../services/services";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from "./customAlert";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: theme.typography.fontSize,
    "&:hover": {},
    marginBottom: 30,
  },
  icon: {
    color: "white",
  },
  container: {
    marginTop: "24px",
    width: "50vw",
  },
  closeIcon: {
    marginTop: "56px",
    marginLeft: "16px",
    cursor: "pointer",
  },
  logo: {
    width: "100px",
    height: "32px",
  },
  link: {
    textDecoration: "none",
    color: formTitleColor,
    fontSize: "15px",
    fontWeight: "600",
    width: "100%",
    margin: "16px 8px",
    cursor: "pointer",
    "&:hover": {
      color: primaryBlue,
    },
    minWidth: "60vw",
  },
  activeLink: {
    color: primaryBlue,
    fontSize: "15px",
    fontWeight: "700",
  },
  dropDownMenuItem: {
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "8px",
    wordWrap: "break-word",
    cursor: "pointer",
    maxWidth: "60vw",
    minWidth: "60vw",
  },
  dropDownMenuItemText: {
    fontSize: "16px",
    lineHeight: 1.35,
    wordWrap: "break-word",
  },
}));

function DrawerComponent() {
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetailsObserver = useSelector(selectUserDetails);
  const { accessToken, userDetails } = userDetailsObserver || {};
  console.log(location.pathname);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [isOpenInsuranceMenu, setIsOpenInsuranceMenu] = useState(false);
  const [isOpenLoanMenu, setIsOpenLoanMenu] = useState(false);

  const logoutClick = () => {
    setOpenDrawer(false);
    logout()
      .then((response) => {
        dispatch(clearRedux());
        dispatch(clearInsurances());
        navigate(routes.HOME_PAGE);
      })
      .catch((error) => {
        dispatch(clearRedux());
        dispatch(clearInsurances());
        setApiError(error.message);
      });
  };

  const openInsuranceMenu = () => {
    setIsOpenInsuranceMenu(!isOpenInsuranceMenu);
  };

  const openLoanMenu = () => {
    setIsOpenLoanMenu(!isOpenLoanMenu);
  };

  const nonMotorInsuranceItemClick = (insurance) => {
    navigate(routes.INSURANCE, {
      state: insurance,
    });
  };

  return (
    <>
      <CustomAlert
        isShow={apiError}
        message={apiError}
        onClose={() => setApiError(null)}
      />
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        style={{ minWidth: "60vw" }}
      >
        <div onClick={() => setOpenDrawer(false)}>
          <Close color="#000000" className={classes.closeIcon} />
        </div>
        <Link
          to={routes.BONNY_AUTO_HOME}
          className={`${classes.link} ${
            location.pathname === routes.BONNY_AUTO_HOME
              ? classes.activeLink
              : ""
          }`}
        >
          Bonny Auto
        </Link>
        <Link
          to={routes.BONNY_YAMAHA_HOME}
          className={`${classes.link} ${
            location.pathname === routes.BONNY_YAMAHA_HOME
              ? classes.activeLink
              : ""
          }`}
        >
          Bonny Yamaha
        </Link>
        <div className={`${classes.link}`} onClick={openInsuranceMenu}>
          <span>Insurance</span>
          {isOpenInsuranceMenu ? <ExpandLess /> : <ExpandMore />}
        </div>
        <Collapse in={isOpenInsuranceMenu} timeout="auto" unmountOnExit>
          <div className={classes.dropDownMenuItem}>
            <span
              onClick={() => nonMotorInsuranceItemClick({})}
              className={classes.dropDownMenuItemText}
            >
              Motor Insurance
            </span>
          </div>
          {nonMotorInsuranceTypes.map((item) => {
            return (
              <div className={classes.dropDownMenuItem}>
                <span
                  onClick={() => nonMotorInsuranceItemClick(item)}
                  className={classes.dropDownMenuItemText}
                >
                  {item.title}
                </span>
              </div>
            );
          })}
        </Collapse>

        <div className={`${classes.link}`} onClick={openLoanMenu}>
          <span>Loan</span>
          {isOpenLoanMenu ? <ExpandLess /> : <ExpandMore />}
        </div>
        <Collapse in={isOpenLoanMenu} timeout="auto" unmountOnExit>
          {vehicleLoanItems.map((item) => {
            return (
              <div className={classes.dropDownMenuItem}>
                <span
                  onClick={() => nonMotorInsuranceItemClick(item)}
                  className={classes.dropDownMenuItemText}
                >
                  {item.title}
                </span>
              </div>
            );
          })}
        </Collapse>

        <Link
          to={routes.ABOUT}
          className={`${classes.link} ${
            location.pathname === routes.ABOUT ? classes.activeLink : ""
          }`}
        >
          About Us
        </Link>
        <HashLink
          to={`${routes.BONNY_AUTO_HOME}#contact-us`}
          className={`${classes.link}`}
        >
          Contact Us
        </HashLink>
        {userDetails ? (
          <>
            <Link to={routes.HOME_PAGE} className={classes.link}>
              Profile
            </Link>
            <Link to={routes.HOME_PAGE} className={classes.link}>
              Log out
            </Link>
          </>
        ) : (
          <Link to={routes.LOGIN} className={classes.link}>
            Login/Register
          </Link>
        )}
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <Menu />
      </IconButton>
      <div>
        <img
          src={require("../../assets/images/bonny_auto_logo.png")}
          className={classes.logo}
        />
      </div>
    </>
  );
}
export default DrawerComponent;
