import React, { useEffect, useState } from "react";
import {
  addMotorInsurance,
  getBottomBannerImage,
  getTopBannerImages,
} from "../../common/services/services";
import BonnyAutoHomePageView from "./bonnyAutoHomePageView";
import {
  advantages,
  awards,
  emailRegex,
  faqs,
  insuranceCompanies,
  nonMotorInsuranceTypes,
  reviews,
  routes,
  STATUS,
  vehicleLoanItems,
} from "../../common/utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInsurances,
  selectInsurance,
} from "../../redux/slices/insuranceSlice";
import { selectUserDetails } from "../../redux/slices/userDetailsSlice";

const BonnyAutoHomePageContainer = () => {
  const insuranceObserver = useSelector(selectInsurance);
  const userDetailsObserver = useSelector(selectUserDetails);
  const userDetails = userDetailsObserver.userDetails;
  const dispatch = useDispatch();

  const offerItemImages = [
    require("../../assets/images/offer_image_1.png"),
    require("../../assets/images/offer_image_2.png"),
    require("../../assets/images/offer_image_3.png"),
  ];

  const bonnyAutoModalForm = [
    {
      title: "Insurance",
      imageSource: require("../../assets/images/insurance_logo.png"),
      // linkTo: userDetails ? routes.INSURANCE : routes.LOGIN,
    },
    {
      title: "Car Exchange",
      imageSource: require("../../assets/images/car_exchange_logo.png"),
      // linkTo: userDetails ? routes.CAR_EXCHANGE : routes.LOGIN,
    },
    {
      title: "Car Loans",
      imageSource: require("../../assets/images/car_loans_logo.png"),
      // linkTo: userDetails ? routes.CAR_LOAN : routes.LOGIN,
    },
  ];

  const content = [
    {
      title: "Our Vision",
      description:
        "To achieve our success, we educate our clients about their personal and business assets' exposures so they can make educated insurance decisions for themselves.\n\nUsing our insurance industry knowledge and relationships, we have been extremely successful in lowering insurance costs for our clients.\n\nAs a result, we're always  aggressively marketing and negotiating insurance premiums, which results in an insurance policy that is both comprehensive and competitively priced.\n\nWe care about our clients and know that their success is our success. At bonny auto, we come to work every day because we want to protect our clients from the risks they face in life and business.",
      imageSrc: require("../../assets/images/our_vision.png"),
    },
    {
      title: "Our Mission",
      description:
        "Our mission is to be the most respected insurance agency in town. We strive to maintain long-term relationships with our customers and adhere to the highest ethical and professional standards. As part of our commitment to enhancing our reputation, we offer a diverse range of coverage options through our extensive network of insurance providers.",
      imageSrc: require("../../assets/images/our_mission.png"),
    },
  ];

  const [bannerImages, setBannerImages] = useState(null);
  const [bannerBottomImages, setBannerBottomImages] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [modelType, setModelType] = useState(null);
  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [mobileNumberError, setMobileNumberError] = useState(null);
  const [state, setState] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [city, setCity] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [insuranceCompany, setInsuranceCompany] = useState(insuranceCompanies[0].value);
  const [carNumber, setCarNumber] = useState(null);
  const [carNumberError, setCarNumberError] = useState(null);
  const [carInsuranceType, setCarInsuranceType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileBannerImages, setMobileBannerImages] = useState(null);

  useEffect(() => {
    getTopBannerImages()
      .then((response) => {
        if (response.banerTopImages) {
          setBannerImages(response.banerTopImages);
        } else {
          setBannerImages([]);
        }
      })
      .catch((error) => {
        setBannerImages([]);
        setApiError(error.message);
      });

    getBottomBannerImage()
      .then((response) => {
        if (response.banerBottomImages) {
          let images = [];
          for (var i = 0; i < response.banerBottomImages.length; i = i + 2) {
            let temp = [];
            if (response.banerBottomImages[i]) {
              temp.push(response.banerBottomImages[i]);
            }
            if (response.banerBottomImages[i + 1]) {
              temp.push(response.banerBottomImages[i + 1]);
            }
            images.push(temp);
          }
          setBannerBottomImages(images);
          setMobileBannerImages(response.banerBottomImages)
        } else {
          setBannerBottomImages([]);
          setMobileBannerImages([])
        }
      })
      .catch((error) => {
        setBannerBottomImages([]);
        setMobileBannerImages([])
        setApiError(error.message);
      });

    if (userDetails) {
      if (
        insuranceObserver.status !== STATUS.SUCCESS ||
        insuranceObserver.status !== STATUS.LOADING
      ) {
        dispatch(fetchInsurances());
      }
    }
  }, []);

  const _showModal = (type) => {
    setModelType(type);
  };

  const _onSubmitClick = () => {
    if (!name || name.trim() === "") {
      setNameError("Enter name");
      return;
    } else {
      setNameError(null);
    }

    if (!email || email.trim() === "") {
      setEmailError("Enter email");
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter valid email");
      return;
    } else {
      setEmailError(null);
    }

    if (!mobileNumber || mobileNumber.trim() === "") {
      setMobileNumberError("Enter name");
      return;
    } else if (mobileNumber.length !== 10) {
      setMobileNumberError("Enter valid number");
      return;
    } else {
      setMobileNumberError(null);
    }

    if (!city || city.trim() === "") {
      setCityError("Enter city");
      return;
    } else {
      setCityError(null);
    }

    if (!state || state.trim() === "") {
      setStateError("Enter userState");
      return;
    } else {
      setStateError(null);
    }

    if (!carNumber || carNumber.trim() === "") {
      setCarNumberError("Enter car number");
      return;
    } else {
      setCarNumberError(null);
    }

    let formData = new FormData();
    formData.append("car_number", carNumber);
    formData.append("type_of_insurance", carInsuranceType);
    formData.append("name", name);
    formData.append("mobile_number", mobileNumber);
    formData.append("email", email);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("insurance_id", insuranceCompany);

    addMotorInsurance(formData)
      .then((response) => {
        setIsLoading(false);
        setModelType("SUCCESS");
      })
      .catch((error) => {
        setIsLoading(false);
        setApiError(error.message);
      });
  };

  return (
    <BonnyAutoHomePageView
      bannerImages={bannerImages}
      apiError={apiError}
      setApiError={setApiError}
      insuranceObserver={insuranceObserver}
      bannerBottomImages={bannerBottomImages}
      userDetails={userDetails}
      bonnyAutoModalForm={bonnyAutoModalForm}
      offerItemImages={offerItemImages}
      content={content}
      nonMotorInsuranceTypes={nonMotorInsuranceTypes}
      vehicleLoanItems={vehicleLoanItems}
      reviews={reviews}
      awards={awards}
      advantages={advantages}
      faqs={faqs}
      modelType={modelType}
      name={name}
      setName={setName}
      nameError={nameError}
      email={email}
      setEmail={setEmail}
      emailError={emailError}
      mobileNumber={mobileNumber}
      setMobileNumber={setMobileNumber}
      numberError={mobileNumberError}
      state={state}
      setState={setState}
      stateError={stateError}
      city={city}
      setCity={setCity}
      cityError={cityError}
      carInsuranceType={carInsuranceType}
      setCarInsuranceType={setCarInsuranceType}
      carNumber={carNumber}
      setCarNumber={setCarNumber}
      carNumberError={carNumberError}
      insuraceCompany={insuranceCompany}
      setInsuraceCompany={setInsuranceCompany}
      isLoading={isLoading}
      _showModal={_showModal}
      _onSubmitClick={_onSubmitClick}
      mobileBannerImages={mobileBannerImages}
    />
  );
};

export default BonnyAutoHomePageContainer;
