import { useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";
import { darkGrey, primaryDarkBlue } from "../../../common/colors";

const useStyles = makeStyles((theme) => {
  return {
    itemContainer: {
      width: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    title: {
      fontSize: "24px",
      lineHeight: "30px",
      fontWeight: 700,
      color: primaryDarkBlue,
      [theme.breakpoints.up("md")]: {
        fontSize: "48px",
        lineHeight: "60px",
      },
    },
    description: {
      fontSize: "17px",
      lineHeight: "22px",
      fontWeight: 400,
      color: darkGrey,
      letterSpacing: "1px",
      textAlign: "left",
      marginTop: "24px",
      whiteSpace: "pre-wrap",
      marginInline: "2em",
      wordSpacing: "2px",
      letterSpacing: "0.018em",
    },
    titleImage: {
      marginBottom: "6px",
      marginLeft: "4px",
      width: "56px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "12px",
        marginLeft: "8px",
        width: "108px"
      },
    },
  };
});

const Overview = ({ content }) => {
  const classes = useStyles();
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div className="overviewContainer">
      {content.map((item, index) => {
        return (
          <div
            className={`row align-items-center justify-content-center py-5 ${
              index % 2 === 0 ? "lightPinkGradient" : "lightPurpleGradient"
            }`}
            data-aos="fade-up"
            data-aos-delay={400 * index}
            id={item.title.replace(" ", "-")}
          >
            {index % 2 === 0 || !greaterThanMid ? (
              <div className="col-12 col-md-6 d-flex justify-content-center align-items-center my-5">
                <img src={item.imageSrc} className="img-fluid" />
              </div>
            ) : null}
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex flex-row align-items-end">
                <span className={classes.title}>{item.title}</span>
                <img
                  src={
                    index % 2 === 0
                      ? require("../../../assets/images/long_dash_red.png")
                      : require("../../../assets/images/slanted_lines.png")
                  }
                  className={classes.titleImage}
                />
              </div>
              <p className={classes.description}>{item.description}</p>
            </div>
            {index % 2 !== 0 && greaterThanMid ? (
              <div className="col-12 col-md-6 d-flex justify-content-center align-items-center my-5">
                <img src={item.imageSrc} />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default Overview;
