import { FormHelperText } from "@mui/material";
import React, { useRef } from "react";
import DropDown from "../../../common/components/dropDown";
import BaseInput from "../../../common/components/inputs/baseInput";
import { carTypes, engineTypes } from "../../../common/utils/constants";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    img: {
      width: "50%",
      height: "20vh",
      flexGrow: 1,
      objectFit: "cover",
      [theme.breakpoints.up("md")]: {
        height: "30vh",
      },
    },
    imgContainer: {
      width: "50%",
    },
    imgLarge: {
      height: "20vh",
      objectFit: "cover",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        height: "30vh",
      },
    },
    imgContainer100: {
      width: "100%",
    },
  };
});

const ModalForms = ({
  modalType,
  carName,
  setCarName,
  carNameError,
  budget,
  setBudget,
  budgetError,
  state,
  setState,
  stateError,
  distance,
  setDistance,
  distanceError,
  description,
  setDescription,
  descriptionError,
  carType,
  setCarType,
  engineType,
  setEngineType,
  ownerDetails,
  setOwnerDetails,
  ownerDetailsError,
  name,
  setName,
  nameError,
  mobileNumber,
  setMobileNumber,
  mobileNumberError,
  firstSideImage,
  setFirstSideImage,
  secondSideImage,
  setSecondSideImage,
  thirdSideImage,
  setThirdSideImage,
  forthSideImage,
  setForthSideImage,
  interiorImage,
  setInteriorImage,
  imageError,
  newCarName,
  setNewCarName,
  newCarNameError,
  email,
  setEmail,
  emailError,
  city,
  setCity,
  cityError,
}) => {
  const firstImageRef = useRef();
  const secondImageRef = useRef();
  const thirdImageRef = useRef();
  const forthImageRef = useRef();
  const interiorImageRef = useRef();
  const classes = useStyles();

  return (
    <div className="d-flex flex-column">
      <BaseInput
        placeholder="Enter Name"
        customClasses="mt-3"
        label="Name"
        value={name}
        setValue={setName}
        error={nameError}
      />
      <BaseInput
        placeholder="Enter Email"
        customClasses="mt-3"
        label="Email"
        value={email}
        setValue={setEmail}
        error={emailError}
      />
      <BaseInput
        placeholder="Enter Mobile Number"
        customClasses="mt-3"
        label="Mobile Number"
        value={mobileNumber}
        setValue={setMobileNumber}
        error={mobileNumberError}
      />
      <BaseInput
        placeholder="Enter City"
        customClasses="mt-3"
        label="City"
        value={city}
        setValue={setCity}
        error={cityError}
      />
      <BaseInput
        placeholder="Enter State"
        customClasses="mt-3"
        label="State"
        value={state}
        setValue={setState}
        error={stateError}
      />
      {modalType && modalType.type === "BUY_CAR" ? (
        <>
          <DropDown
            list={carTypes}
            className="mt-3"
            label="type"
            selectedValue={carType}
            setSelectedValue={setCarType}
          />
          <BaseInput
            placeholder="Enter Car Name"
            customClasses="mt-3"
            label="Car Name"
            value={carName}
            setValue={setCarName}
            error={carNameError}
          />
          <DropDown
            list={engineTypes}
            className="mt-3"
            label="Engine Type"
            selectedValue={engineType}
            setSelectedValue={setEngineType}
          />
          <BaseInput
            placeholder="Enter Budget"
            customClasses="mt-3"
            label="Budget"
            value={budget}
            setValue={setBudget}
            error={budgetError}
          />
        </>
      ) : modalType && modalType.type === "SELL_CAR" ? (
        <>
          <BaseInput
            placeholder="Enter KMS"
            customClasses="mt-3"
            label="Distance"
            value={distance}
            setValue={setDistance}
            error={distanceError}
          />
          <BaseInput
            placeholder="Enter Owner Details"
            customClasses="mt-3"
            label="Owner Details"
            value={ownerDetails}
            setValue={setOwnerDetails}
            error={ownerDetailsError}
          />
          <DropDown
            list={engineTypes}
            className="mt-3"
            label="Engine Type"
            selectedValue={engineType}
            setSelectedValue={setEngineType}
          />
          <span className="mt-3 upload_subtitle">Upload Side Images</span>
          <div className="d-flex flex-row">
            <div className={`mt-2 me-2 d-flex ${classes.imgContainer}`}>
              <img
                className={classes.img}
                onClick={() => firstImageRef.current.click()}
                src={
                  firstSideImage
                    ? URL.createObjectURL(firstSideImage)
                    : require("../../../assets/images/place_holder_image.png")
                }
              />
              <input
                hidden={true}
                type="file"
                accept="image/*"
                ref={firstImageRef}
                onChange={(event) => setFirstSideImage(event.target.files[0])}
              />
            </div>
            <div className={`mt-2 me-2 d-flex ${classes.imgContainer}`}>
              <img
                className={classes.img}
                onClick={() => secondImageRef.current.click()}
                src={
                  secondSideImage
                    ? URL.createObjectURL(secondSideImage)
                    : require("../../../assets/images/place_holder_image.png")
                }
              />
              <input
                hidden={true}
                type="file"
                accept="image/*"
                ref={secondImageRef}
                onChange={(event) => setSecondSideImage(event.target.files[0])}
              />
            </div>
          </div>

          <div className="d-flex flex-row">
            <div className={`mt-2 me-2 d-flex ${classes.imgContainer}`}>
              <img
                className={classes.img}
                onClick={() => thirdImageRef.current.click()}
                src={
                  thirdSideImage
                    ? URL.createObjectURL(thirdSideImage)
                    : require("../../../assets/images/place_holder_image.png")
                }
              />
              <input
                hidden={true}
                type="file"
                accept="image/*"
                ref={thirdImageRef}
                onChange={(event) => setThirdSideImage(event.target.files[0])}
              />
            </div>
            <div className={`mt-2 me-2 d-flex ${classes.imgContainer}`}>
              <img
                className={classes.img}
                onClick={() => forthImageRef.current.click()}
                src={
                  forthSideImage
                    ? URL.createObjectURL(forthSideImage)
                    : require("../../../assets/images/place_holder_image.png")
                }
              />
              <input
                hidden={true}
                type="file"
                accept="image/*"
                ref={forthImageRef}
                onChange={(event) => setForthSideImage(event.target.files[0])}
              />
            </div>
          </div>

          <div className={`mt-3 ${classes.imgContainer100}`}>
            <img
              className={classes.imgLarge}
              onClick={() => interiorImageRef.current.click()}
              src={
                interiorImage
                  ? URL.createObjectURL(interiorImage)
                  : require("../../../assets/images/place_holder_image.png")
              }
            />
            <input
              hidden={true}
              type="file"
              accept="image/*"
              ref={interiorImageRef}
              onChange={(event) => setInteriorImage(event.target.files[0])}
            />
          </div>

          {imageError ? (
            <FormHelperText className="mt-3" error={true}>
              {imageError}
            </FormHelperText>
          ) : null}

          <BaseInput
            placeholder="Description"
            customClasses="mt-3"
            label="Description"
            value={description}
            setValue={setDescription}
            error={descriptionError}
          />
        </>
      ) : (
        <>
          <BaseInput
            placeholder="Enter Old Car Name"
            customClasses="mt-3"
            label="Old Car Name"
            value={carName}
            setValue={setCarName}
            error={carNameError}
          />
          <BaseInput
            placeholder="Enter KMS"
            customClasses="mt-3"
            label="Distance"
            value={distance}
            setValue={setDistance}
            error={distanceError}
          />
          <BaseInput
            placeholder="Enter Budget"
            customClasses="mt-3"
            label="Budget"
            value={budget}
            setValue={setBudget}
            error={budgetError}
          />
          <BaseInput
            placeholder="Enter Car Name"
            customClasses="mt-3"
            label="Car Name"
            value={newCarName}
            setValue={setNewCarName}
            error={newCarNameError}
          />
          <span className="mt-3 upload_subtitle">Upload Side Images</span>
          <div className="d-flex flex-row">
            <div className={`mt-2 me-2 d-flex ${classes.imgContainer}`}>
              <img
                className={classes.img}
                onClick={() => firstImageRef.current.click()}
                src={
                  firstSideImage
                    ? URL.createObjectURL(firstSideImage)
                    : require("../../../assets/images/place_holder_image.png")
                }
              />
              <input
                hidden={true}
                type="file"
                accept="image/*"
                ref={firstImageRef}
                onChange={(event) => setFirstSideImage(event.target.files[0])}
              />
            </div>
            <div className={`mt-2 me-2 d-flex ${classes.imgContainer}`}>
              <img
                className={classes.img}
                onClick={() => secondImageRef.current.click()}
                src={
                  secondSideImage
                    ? URL.createObjectURL(secondSideImage)
                    : require("../../../assets/images/place_holder_image.png")
                }
              />
              <input
                hidden={true}
                type="file"
                accept="image/*"
                ref={secondImageRef}
                onChange={(event) => setSecondSideImage(event.target.files[0])}
              />
            </div>
          </div>

          <div className="d-flex flex-row">
            <div className={`mt-2 me-2 d-flex ${classes.imgContainer}`}>
              <img
                className={classes.img}
                onClick={() => thirdImageRef.current.click()}
                src={
                  thirdSideImage
                    ? URL.createObjectURL(thirdSideImage)
                    : require("../../../assets/images/place_holder_image.png")
                }
              />
              <input
                hidden={true}
                type="file"
                accept="image/*"
                ref={thirdImageRef}
                onChange={(event) => setThirdSideImage(event.target.files[0])}
              />
            </div>
            <div className={`mt-2 me-2 d-flex ${classes.imgContainer}`}>
              <img
                className={classes.img}
                onClick={() => forthImageRef.current.click()}
                src={
                  forthSideImage
                    ? URL.createObjectURL(forthSideImage)
                    : require("../../../assets/images/place_holder_image.png")
                }
              />
              <input
                hidden={true}
                type="file"
                accept="image/*"
                ref={forthImageRef}
                onChange={(event) => setForthSideImage(event.target.files[0])}
              />
            </div>
          </div>

          <div className={`mt-3 ${classes.imgContainer100}`}>
            <img
              className={classes.imgLarge}
              onClick={() => interiorImageRef.current.click()}
              src={
                interiorImage
                  ? URL.createObjectURL(interiorImage)
                  : require("../../../assets/images/place_holder_image.png")
              }
            />
            <input
              hidden={true}
              type="file"
              accept="image/*"
              ref={interiorImageRef}
              onChange={(event) => setInteriorImage(event.target.files[0])}
            />
          </div>

          {imageError ? (
            <FormHelperText className="mt-3" error={true}>
              {imageError}
            </FormHelperText>
          ) : null}
        </>
      )}
    </div>
  );
};

export default ModalForms;
