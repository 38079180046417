import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInsuranceMaster } from "../../common/services/services";
import { STATUS } from "../../common/utils/constants";

const initialState = {
  status: STATUS.IDLE,
  data: null,
  error: null,
};

export const fetchInsurances = createAsyncThunk(
  "insurance/fetchInsurances",
  async () => {
    const response = await getInsuranceMaster();
    return response;
  }
);

const insuranceSlice = createSlice({
  name: "insurance",
  initialState: initialState,
  reducers: {
    clearInsurances: {
      reducer: (state, action) => {
        state.status = STATUS.IDLE;
        state.data = null;
        state.error = null;
      },
    },
  },
  extraReducers: {
    [fetchInsurances.pending]: (state, action) => {
      state.status = STATUS.LOADING;
      state.error = null;
    },
    [fetchInsurances.fulfilled]: (state, action) => {
      let response = action.payload;
      if (response.status == 200) {
        state.status = STATUS.SUCCESS;
        let data = action.payload.insuranceMasters.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        state.data = data;
      } else {
        state.status = STATUS.ERROR;
        state.error = action.error.message;
      }
    },
    [fetchInsurances.rejected]: (state, action) => {
      state.status = STATUS.ERROR;
      state.error = action.error.message;
    },
  },
});

export const selectInsurance = (state) => state.insurance;
export const { clearInsurances } = insuranceSlice.actions;
export default insuranceSlice.reducer;
