import { Paper, Rating, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import InsuranceLogo from "../../../assets/images/insurance_logo.png";
import {
  backgroundGray,
  darkGrey,
  primaryDarkBlue,
  white,
} from "../../../common/colors";
import SolidButton from "../../../common/components/buttons/solidButton";
import LoanLogo from "../../../assets/images/car_loans_logo.png";
import ExchangeLogo from "../../../assets/images/car_exchange_logo.png";
import { default as AdvantagesLogo } from "../../../assets/images/advantages_logo.svg";
import { default as WorkMen } from "../../../assets/images/workmen_compensation.svg";
import { default as FireInsurance } from "../../../assets/images/fire_insurance.svg";
import { default as DomesticInsurance } from "../../../assets/images/Domestic_marine.svg";
import { default as ImportExport } from "../../../assets/images/import_export_insurance.svg";
import { default as HealthInsurance } from "../../../assets/images/health_insurance.svg";
import { default as TravelInsurance } from "../../../assets/images/travel_insurance.svg";
import { default as OtherInsurance } from "../../../assets/images/other_commercial_insurance.svg";
import { default as TwoWheelerLoan } from "../../../assets/images/two_wheeler_insurance.svg";
import { default as FourWheelerLoan } from "../../../assets/images/four_wheeler_insurance.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../common/utils/constants";
import CostEffectiveLogo from "../../../assets/images/CostEffective.png";
import ConvenientLogo from "../../../assets/images/ConvenientandTimeEffective.png";
import EasyEndorsement from "../../../assets/images/EasyEndorsement.png";
import NoMoreAgent from "../../../assets/images/NoMoreAgents.png";
import PayPremiumOnline from "../../../assets/images/PayPremiumOnline.png";
import PayReminder from "../../../assets/images/PaymentReminders.png";
import SoftCopy from "../../../assets/images/softcopyofthedocument.png";
import ZeroPaperwork from "../../../assets/images/ZeroPaperwork.png";
import MultiCarousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    paritialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 0,
  },
};

const useStyles = makeStyles((theme) => {
  return {
    sectionTitleImg: {
      width: "160px",
    },
    insuranceItemContainer: {
      width: "230px",
      maxWidth: "230px",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      cursor: "pointer",
    },
    insuranceItemTitle: {
      fontSize: "17px",
      lineHeight: "23px",
      color: primaryDarkBlue,
      textAlign: "center",
    },
    hideScrollBar: {
      msOverflowStyle: "none",
      scrollbarWidth: "none",
    },
    subSectionImage: {
      width: "152px",
      height: "152px",
    },
    subSectionSmallImage: {
      width: "72px",
      height: "72px",
    },
    listBullet: {
      minWidth: "5px",
      width: "5px",
      height: "5px",
      borderRadius: "10px",
      backgroundColor: darkGrey,
      [theme.breakpoints.up("md")]: {
        minWidth: "10px",
        width: "10px",
        height: "10px",
      },
    },
    width100: {
      width: "100%",
    },
    ratings: {
      color: primaryDarkBlue,
      width: "100%",
      fontSize: "18px",
    },
    awardImg: {
      width: "150px",
      height: "104px",
    },
    awardItem: {
      maxWidth: "300px",
      minWidth: "300px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    insuranceItemImage: {
      maxHeight: "148px",
    },
    insuranceContainer: {
      height: "40vh",
    },
    vehicleLoanDetailsContainer: {
      backgroundColor: white,
      borderRadius: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    vehicleLoanDetailsSubContainer: {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      minHeight: "16px",
      maxHeight: "16px",
    },
    vehicleLoanDetails: {
      padding: "16px 32px",
    },
    marginRight24: {
      [theme.breakpoints.up("md")]: {
        marginRight: "24px",
        marginLeft: "0px",
      },
    },
    marginLeft24: {
      marginLeft: "0px",
      [theme.breakpoints.up("md")]: {
        marginLeft: "24px",
      },
    },
    margin48: {
      marginRight: "0px",
      marginLeft: "4px",
      backgroundColor: white,
      borderRadius: "8px",
      [theme.breakpoints.up("md")]: {
        marginRight: "0px",
      },
    },
    insuranceBenefitItemDesc: {
      fontSize: "10px",
      lineHeight: "12px",
      marginBottom: "0px",
      [theme.breakpoints.up("md")]: {
        fontSize: "15px",
        lineHeight: "20px",
      },
    },
    caroselContainer: {
      minHeight: "250px",
      minWidth: "200px",
    },
  };
});

const Services = ({
  nonMotorInsuranceTypes,
  vehicleLoanItems,
  reviews,
  awards,
  advantages,
  _showModal,
}) => {
  const classes = useStyles();
  return (
    <div className="servicesContainer mt-5">
      <div className="d-flex flex-column servicesContent">
        <div className="py-3">
          <Typography variant="h1" className="my-5 text-center">
            Our Services
          </Typography>
        </div>

        {/* insurance section */}
        <div className={"d-flex flex-column align-items-center py-5 container"}>
          <Section
            imageSrc={InsuranceLogo}
            title="Insurance"
            subTitle="Benefits of Buying Insurance Policy"
            description="An insurance policy / plan is a contract between an individual (Policyholder) and an insurance advisor. Insurers require you to pay regular amounts of money to them (as premiums), and they pay you if an unfortunate event happens, for example, the untimely death of the insured person, an accident, or damage to the home. Buying insurance by has become a common practice as it offers numerous benefits. You can get your car insured against damages and financial losses caused to accidents, fires, theft etc, by buying a car insurance policy from an advisor in 3 simple steps. Check out the below advantages that come with buying four-wheeler insurance"
          />

          <div className="row">
            <div className="col-md-4 col-12 mt-4 flex-grow-1 d-flex">
              <div className={`${classes.margin48} row py-2`} elevation={1}>
                <div className="col-3">
                  <img className="img-fluid" src={NoMoreAgent} />
                </div>

                <div className="col-9">
                  <Typography variant="subtitle1">Agents Advise</Typography>
                  <p className={classes.insuranceBenefitItemDesc}>
                    We provide concrete and reliable information which helps you
                    make the right decision
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12 mt-4 flex-grow-1 d-flex">
              <div className={`${classes.margin48} row py-2`} elevation={1}>
                <div className="col-3">
                  <img className="img-fluid" src={ConvenientLogo} />
                </div>

                <div className="col-9">
                  <Typography variant="subtitle1">
                    Convenient and Time Effective
                  </Typography>
                  <p className={classes.insuranceBenefitItemDesc}>
                    You are not limited by working hours, branch. Buy the policy
                    at your convenient time.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12 mt-4 flex-grow-1 d-flex">
              <div className={`${classes.margin48} row py-2`} elevation={1}>
                <div className="col-3">
                  <img className="img-fluid" src={EasyEndorsement} />
                </div>

                <div className="col-9">
                  <Typography variant="subtitle1">Easy Endorsement</Typography>
                  <p className={classes.insuranceBenefitItemDesc}>
                    As easy as submitting a self-declaration and verification of
                    the amended information to your insurer.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12 mt-4 flex-grow-1 d-flex">
              <div className={`${classes.margin48} row py-2`} elevation={1}>
                <div className="col-3">
                  <img className="img-fluid" src={ZeroPaperwork} />
                </div>

                <div className="col-9">
                  <Typography variant="subtitle1">Zero Paperwork</Typography>
                  <p className={classes.insuranceBenefitItemDesc}>
                    Application filed digitally along with a scanned copy of
                    required documents with minimal to no paperwork
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12 mt-4 flex-grow-1 d-flex">
              <div className={`${classes.margin48} row py-2`} elevation={1}>
                <div className="col-3">
                  <img className="img-fluid" src={PayReminder} />
                </div>

                <div className="col-9">
                  <Typography variant="subtitle1">Payment Reminders</Typography>
                  <p className={classes.insuranceBenefitItemDesc}>
                    Our systems sends reminders at frequent intervals. So you
                    never miss a due date
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12 mt-4 flex-grow-1 d-flex">
              <div className={`${classes.margin48} row py-2`} elevation={1}>
                <div className="col-3">
                  <img className="img-fluid" src={SoftCopy} />
                </div>

                <div className="col-9">
                  <Typography variant="subtitle1">
                    Soft Copy of the Document
                  </Typography>
                  <p className={classes.insuranceBenefitItemDesc}>
                    Receive a digital copy of your insurance policy via email &
                    whatsapp
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* motor insurance section */}
        <div
          className={`container d-flex flex-column align-items-center py-5`}
          id="motor-insurance"
        >
          <Typography variant="h3" className="mt-4">
            {"Motor Insurance"}
          </Typography>
          <Typography variant="body1" className="mt-4 px-5">
            {
              "An insurance plan that covers motor vehicles can protect you financially against exorbitant medical costs. It covers all hospitalization expenses, so you can focus on a speedy recovery instead of worrying about finances. It is mandatory in India to carry third-party liability insurance under the Motor Vehicles Act."
            }
          </Typography>

          <SolidButton
            btnText="Get Quote Now"
            customClasses="mt-5"
            btnClick={() => _showModal("MOTOR")}
          />
        </div>

        {/* non motor insurance section */}
        <div>
          <div className="container d-flex flex-column align-items-center py-5">
            <Typography variant="h3" className="mt-4">
              {"Non - Motor Insurance"}
            </Typography>
            <Typography variant="body1" className="mt-4 px-5">
              {
                "A motor insurance policy is a mandatory policy issued by an insurance company as part of the prevention of public liability to protect the general public from any accident that might take place on the road.\n\nThe law mandates that every owner of a motor vehicle must have one motor insurance policy. An accident can include a storm, an earthquake, a fire, a lightning strike, an external explosion, a burglary, or even a riot."
              }
            </Typography>
          </div>

          <MultiCarousel
            ssr
            itemClass={classes.caroselContainer}
            infinite={true}
            responsive={responsive}
            containerClass="w-100"
            focusOnSelect={true}
          >
            {nonMotorInsuranceTypes.map((item) => {
              return <InsuranceItem insuranceItem={item} />;
            })}
          </MultiCarousel>
        </div>

        {/* vehicle loan */}
        <div className={`d-flex flex-column align-items-center py-5`}>
          <Section
            imageSrc={LoanLogo}
            title="Vehicle Loan"
            description="A Vehicle Loan is a loan that allows you to purchase two and four wheelers for personal use. Typically, the lender loans the money (making a direct payment to the dealer on the buyer’s behalf) while the buyer must repay the loan in Equated Monthly Instalments (EMIs) over a specific tenure at a specific interest rate. The EMI comprises a portion of the principal amount and the interest component. Once you repay the loan in full, the lender transfers the vehicle registration in your name. You can also apply for a Vehicle Loan to buy these vehicles to transport goods or company personnel. Common examples of commercial vehicles include buses, trucks, tractors, tippers, cabs, etc."
          />

          <div className="container">
            <div className="row">
              <div
                className={`col-md col-11 mx-auto mx-md-3 mt-5 ${classes.vehicleLoanDetailsContainer} ${classes.marginRight24}`}
              >
                <div
                  className={classes.vehicleLoanDetailsSubContainer}
                  style={{ backgroundColor: "#F5899A" }}
                />
                <div className={classes.vehicleLoanDetails}>
                  <Typography variant="subtitle1">
                    1. Loss or damage to the insured car
                  </Typography>
                  <Typography variant="body2" className="mt-2">
                    You are covered if your car is damaged in a collision, a
                    fire, or by self-ignition. Additionally, your insurance
                    policy covers losses caused by terrorism, strikes, rioting,
                    burglary, or theft of the vehicle. The fact that car
                    insurance covers loss or damage when being transported by
                    rail, inland waterways, air, road, or lift is an additional
                    perk.
                  </Typography>
                </div>
              </div>

              <div
                className={`col-md col-11 mx-auto mx-md-3 mt-5 ${classes.vehicleLoanDetailsContainer} ${classes.marginLeft24}`}
              >
                <div
                  className={classes.vehicleLoanDetailsSubContainer}
                  style={{ backgroundColor: "#7FE9DE" }}
                />
                <div className={classes.vehicleLoanDetails}>
                  <Typography variant="subtitle1">
                    2. Personal accident cover
                  </Typography>
                  <Typography variant="body2" className="mt-2">
                    Another advantage of car insurance is that it offers
                    personal accident cover for a pre-determined amount.
                    Personal Accident cover provides protection against
                    permanent total disability, Death due to an accident.
                    Furthermore, this cover can be taken for other passengers on
                    unnamed basis (maximum as per the vehicle’s seating
                    capacity) for a pre-determined amount under the car
                    insurance policy.
                  </Typography>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className={`col-md col-11 mx-auto mx-md-3 mt-5 ${classes.vehicleLoanDetailsContainer} ${classes.marginRight24}`}
              >
                <div
                  className={classes.vehicleLoanDetailsSubContainer}
                  style={{ backgroundColor: "#FFE15D" }}
                />
                <div className={classes.vehicleLoanDetails}>
                  <Typography variant="subtitle1">
                    3. Large network of garages
                  </Typography>
                  <Typography variant="body2" className="mt-2">
                    HDFC ERGO Car Insurance maintains a large network of garages
                    across the country. This ensures you receive cashless
                    services in all these locations, in case the need arises.
                    This feature makes it convenient to avail of repair services
                    in case of any damage to your vehicle.
                  </Typography>
                </div>
              </div>

              <div
                className={`col-md col-11 mx-auto mx-md-3 mt-5 ${classes.vehicleLoanDetailsContainer} ${classes.marginLeft24}`}
              >
                <div
                  className={classes.vehicleLoanDetailsSubContainer}
                  style={{ backgroundColor: "#C147E9" }}
                />
                <div className={classes.vehicleLoanDetails}>
                  <Typography variant="subtitle1">
                    4. Third-party liabilities
                  </Typography>
                  <Typography variant="body2" className="mt-2">
                    If your car is involved in an accident that results in
                    damage or loss to the property of any third parties, it is
                    covered under the car insurance. Furthermore, if you face
                    any legal liabilities in case of any bodily injury or death
                    of a third party, your car insurance protects you against
                    the same.
                  </Typography>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className={`col-md-6 col-11 mx-auto mt-5 ${classes.vehicleLoanDetailsContainer} ${classes.marginLeft24} mx-auto`}
              >
                <div
                  className={classes.vehicleLoanDetailsSubContainer}
                  style={{ backgroundColor: "#F5899A" }}
                />
                <div className={classes.vehicleLoanDetails}>
                  <Typography variant="subtitle1">5. No claim bonus</Typography>
                  <Typography variant="body2" className="mt-2">
                    One of the major advantages of having car insurance is the
                    no claim bonus (NCB). Customers are eligible for this
                    benefit for every claim-free year. This may be available as
                    a discount on the premium, which makes car insurance even
                    more affordable.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`d-flex flex-row align-items-center services_container justify-content-center mt-4 ${classes.insuranceContainer} ${classes.hideScrollBar}`}
          >
            {vehicleLoanItems.map((item) => {
              return <InsuranceItem insuranceItem={item} removeAuto={true} />;
            })}
          </div>
        </div>

        {/* car exchange */}
        <div
          className={`d-flex flex-column align-items-center py-5`}
          id="car-exchange"
        >
          <Section
            imageSrc={ExchangeLogo}
            title="Car Exchange"
            description="Talking about the scenario when someone wants to exchange old car and buy new car, bunch of automobile dealers from various brands provides many exchange offers on old car. Instead of selling his pre-owned car to an unknown buyer, the user exchanges his four-wheeler with the new one from the same dealer."
          />

          <SolidButton
            btnText="Get Quote Now"
            customClasses="mt-5"
            btnClick={() => _showModal("EXCHANGE")}
          />
        </div>

        {/* advantages */}
        <div className={`d-flex flex-column align-items-center py-5`}>
          <SubSectionTitle
            imageSrc={AdvantagesLogo}
            title="Advantages for Insurance and Loans"
            smallImage={true}
          />

          <div className="container">
            {advantages.map((item) => {
              return (
                <div className="d-flex flex-row align-items-center mt-4">
                  <div className={classes.listBullet} />
                  <Typography variant="body1" className="ms-md-5 ms-3">
                    {item}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Section = ({
  imageSrc,
  title,
  subTitle,
  description,
  containerClassName = "",
}) => {
  const classes = useStyles();
  return (
    <div
      className={`container d-flex flex-column align-items-center ${containerClassName}`}
    >
      <div className="d-flex flex-md-row flex-column align-items-center">
        <img src={imageSrc} className={classes.sectionTitleImg} />
        <Typography variant="h2" className="ms-md-5 mt-3 mt-md-0">
          {title}
        </Typography>
      </div>
      {subTitle ? (
        <Typography variant="h3" className="mt-4">
          {subTitle}
        </Typography>
      ) : null}
      <Typography variant="body1" className="mt-4">
        {description}
      </Typography>
    </div>
  );
};

export const SubSectionTitle = ({
  imageSrc,
  title,
  smallImage = false,
  customClasses = "",
}) => {
  const classes = useStyles();
  return (
    <div
      className={`d-flex flex-md-row align-items-center flex-column ${customClasses}`}
    >
      <img
        src={imageSrc}
        className={
          smallImage ? classes.subSectionSmallImage : classes.subSectionImage
        }
      />
      <Typography variant="h3" className="ms-md-5 mb-0 mt-3 mt-md-0">
        {title}
      </Typography>
    </div>
  );
};

const InsuranceItem = ({ insuranceItem, removeAuto = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div
      className={`${
        removeAuto ? "mx-md-5 mx-1" : "mx-auto"
      } px-4 py-4 rounded-3 ${
        classes.insuranceItemContainer
      } boxShadowLight h-100`}
      onClick={() => navigate(routes.INSURANCE, { state: insuranceItem })}
    >
      <img
        src={getNonMotorInsuranceImages(insuranceItem.type)}
        className={`img-fluid ${classes.insuranceItemImage}`}
      />
      <div className="flex-grow-1" />
      <span className={`mt-4 ${classes.insuranceItemTitle}`}>
        {insuranceItem.title}
      </span>
    </div>
  );
};

export const getNonMotorInsuranceImages = (type) => {
  if (type === "WORKMEN_COMPENSATION") {
    return WorkMen;
  } else if (type === "FIRE_INSURANCE") {
    return FireInsurance;
  } else if (type === "DOMESTIC_MARINE") {
    return DomesticInsurance;
  } else if (type === "IMPORT_EXPORT_MARINES") {
    return ImportExport;
  } else if (type === "HEALTH_INSURANCE") {
    return HealthInsurance;
  } else if (type === "TRAVEL_INSURANCE") {
    return TravelInsurance;
  } else if (type === "TWO_WHEELER_LOAN") {
    return TwoWheelerLoan;
  } else if (type === "FOUR_WHEELER_LOAN") {
    return FourWheelerLoan;
  } else {
    return OtherInsurance;
  }
};

export default Services;
