import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { modelTypes, scooterTypes } from "../../common/utils/constants";
import ServicesPageView from "./servicesPageView";

const ServicesPageContainer = () => {
  const { state } = useLocation();
  const [modelType, setModelType] = useState(
    state && state.type ? state.type : modelTypes[0].value
  );
  const [model, setModel] = useState(
    state && state.model ? state.model : scooterTypes[0].value
  );

  return (
    <ServicesPageView
      modelType={modelType}
      setModelType={setModelType}
      model={model}
      setModel={setModel}
    />
  );
};

export default ServicesPageContainer;
