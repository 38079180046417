import { Modal, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SecondaryButton from "./buttons/secondaryButton";
import SolidButton from "./buttons/solidButton";

const useStyles = makeStyles((theme) => {
  return {
    modalContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "98vw",
      paddingTop: "24px",
      paddingBottom: "32px",
      paddingLeft: "24px",
      paddingRight: "24px",
      [theme.breakpoints.up("md")]: {
        width: "50vw",
        paddingTop: "46px",
        paddingBottom: "54px",
        paddingLeft: "54px",
        paddingRight: "54px",
      },
    },
  };
});

const CustomModal = ({
  isSuccess = false,
  title,
  showModal,
  positiveButtonCallBack,
  positiveButtonText = "SUBMIT",
  negativeButtonCallBack,
  showNegativeButton = true,
  negativeButtonText = "CANCEL",
  children,
  hideModal,
  isPositiveButtonLoading = false,
}) => {
  const classes = useStyles();

  return (
    <Modal open={showModal} footer={null} onClose={hideModal}>
      <Paper className={classes.modalContainer}>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row mb-2">
            <Typography variant="h5">{title}</Typography>
          </div>
          <div className="modalContent mt-3">
            {isSuccess ? (
              <div className="d-flex flex-column align-items-center">
                <img src={require('../../assets/images/success.png')} height="64px" width="64px"/>
                <span className="mt-5 success_dialog_title">
                  Your Details Has Been Submitted !!!
                </span>
                <span className="mt-2 mb-5 success_dialog_subtext">
                  We will contact you shortly...
                </span>
              </div>
            ) : (
              <div>{children}</div>
            )}
          </div>

          {isSuccess || !showNegativeButton ? (
            <SolidButton
              btnText={isSuccess ? "Okay" : positiveButtonText}
              customClasses="flex-grow-1 me-3"
              btnClick={positiveButtonCallBack}
              isLoading={isPositiveButtonLoading}
            />
          ) : (
            <div className="d-flex flex-column flex-md-row align-items-md-center mt-3">
              <SolidButton
                btnText={positiveButtonText}
                customClasses="flex-grow-1 me-md-3"
                btnClick={positiveButtonCallBack}
                isLoading={isPositiveButtonLoading}
              />
              <SecondaryButton
                btnText={negativeButtonText}
                customClasses="flex-grow-1 ms-md-3 mt-3 mt-md-0"
                btnClick={negativeButtonCallBack}
              />
            </div>
          )}
        </div>
      </Paper>
    </Modal>
  );
};

export default CustomModal;
