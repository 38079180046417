import { Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { darkGrey, white } from "../../common/colors";
import DropDown from "../../common/components/dropDown";
import ScrollToTop from "../../common/components/scrollToTop";
import {
  amcIncludes,
  bikeTypes,
  customerAmcBenifits,
  freeAndPaidServices,
  htmlData,
  modelTypes,
  scooterTypes,
  serviceProviderAmcBenifits,
} from "../../common/utils/constants";

const useStyles = makeStyles((theme) => {
  return {
    dropDownContainer: {
      marginTop: "48px",
      [theme.breakpoints.up("md")]: {
        width: "60vw",
        marginInline: "auto",
        display: "flex",
        flexDirection: "row",
        alignItem: "center",
      },
    },
    img: {
      flexGrow: 1,
      width: "90vw",
      height: 300,
      [theme.breakpoints.up("md")]: {
        width: "30vw",
        flex: 1,
      },
    },
    vehicleLoanDetailsContainer: {
      backgroundColor: '#f8f9fa',
      borderRadius: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    marginRight24: {
      marginRight: "0px",
      [theme.breakpoints.up("md")]: {
        marginRight: "24px",
      },
    },
    vehicleLoanDetailsSubContainer: {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      minHeight: "16px",
      maxHeight: "16px",
    },
    vehicleLoanDetails: {
      padding: "16px 32px",
    },
    listBullet: {
      minWidth: "5px",
      width: "5px",
      height: "5px",
      marginTop: "8px",
      borderRadius: "10px",
      backgroundColor: darkGrey,
      [theme.breakpoints.up("md")]: {
        minWidth: "10px",
        width: "10px",
        height: "10px",
      },
    },
  };
});

const ServicesPageView = ({ modelType, setModelType, model, setModel }) => {
  const classes = useStyles();
  let data = htmlData[model];

  return (
    <Paper className="container flex-grow-1" elevation={0}>
      <ScrollToTop />
      <Typography variant="h3" className="mt-4">
        {"Free And Paid Service For Yamaha Bikes And Scooters"}
      </Typography>

      <div className="container">
        <div className="row mt-5">
          <div
            className={`col-md col-12 ${classes.vehicleLoanDetailsContainer} ${classes.marginRight24}`}
          >
            <div
              className={classes.vehicleLoanDetailsSubContainer}
              style={{ backgroundColor: "#F5899A" }}
            />
            <div className={classes.vehicleLoanDetails}>
              <Typography variant="subtitle1">Expert Technicians</Typography>

              <div className="d-flex flex-row">
                <div className={`${classes.listBullet}`} />
                <Typography variant="body2" className="ms-3">
                  Trained Technicians
                </Typography>
              </div>

              <div className="d-flex flex-row">
                <div className={`${classes.listBullet}`} />
                <Typography variant="body2" className="ms-3">
                  Lady Supervisor
                </Typography>
              </div>
            </div>
          </div>

          <div
            className={`col-md col-12 ${classes.vehicleLoanDetailsContainer} ${classes.marginLeft24}`}
          >
            <div
              className={classes.vehicleLoanDetailsSubContainer}
              style={{ backgroundColor: "#7FE9DE" }}
            />
            <div className={classes.vehicleLoanDetails}>
              <Typography variant="subtitle1">Latest Facility</Typography>
              <div className="d-flex flex-row">
                <div className={`${classes.listBullet}`} />
                <Typography variant="body2" className="ms-3">
                  Fuel Injection Stations
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div
            className={`col-md col-12 ${classes.vehicleLoanDetailsContainer} ${classes.marginRight24}`}
          >
            <div
              className={classes.vehicleLoanDetailsSubContainer}
              style={{ backgroundColor: "#FFE15D" }}
            />
            <div className={classes.vehicleLoanDetails}>
              <Typography variant="subtitle1">Unique Program</Typography>
              <div className="d-flex flex-row">
                <div className={`${classes.listBullet}`} />
                <Typography variant="body2" className="ms-3">
                  Time Commitment Service
                </Typography>
              </div>

              <div className="d-flex flex-row">
                <div className={`${classes.listBullet}`} />
                <Typography variant="body2" className="ms-3">
                  Lady Supervisor
                </Typography>
              </div>
            </div>
          </div>

          <div
            className={`col-md col-12 ${classes.vehicleLoanDetailsContainer} ${classes.marginLeft24}`}
          >
            <div
              className={classes.vehicleLoanDetailsSubContainer}
              style={{ backgroundColor: "#C147E9" }}
            />
            <div className={classes.vehicleLoanDetails}>
              <Typography variant="subtitle1">Add-on Benefits</Typography>
              <div className="d-flex flex-row">
                <div className={`${classes.listBullet}`} />
                <Typography variant="body2" className="ms-3">
                  Road Side Assistance
                </Typography>
              </div>

              <div className="d-flex flex-row">
                <div className={`${classes.listBullet}`} />
                <Typography variant="body2" className="ms-3">
                  Pick and Drop Service
                </Typography>
              </div>

              <div className="d-flex flex-row">
                <div className={`${classes.listBullet}`} />
                <Typography variant="body2" className="ms-3">
                  Cashless Insurance
                </Typography>
              </div>

              <div className="d-flex flex-row">
                <div className={`${classes.listBullet}`} />
                <Typography variant="body2" className="ms-3">
                  AMC - Annual Maintenance Contract
                </Typography>
              </div>

              <div className="d-flex flex-row">
                <div className={`${classes.listBullet}`} />
                <Typography variant="body2" className="ms-3">
                  Complete peace of mind with 5 years of Protection Plus policy
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.dropDownContainer}>
        <div className="d-flex flex-grow-1 px-3 my-md-1">
          <DropDown
            list={modelTypes}
            label="Model"
            className="flex-grow-1"
            selectedValue={modelType}
            setSelectedValue={setModelType}
          />
        </div>
        <div className="d-flex flex-grow-1 px-3 my-3 my-md-1">
          <DropDown
            list={modelType === "scooter" ? scooterTypes : bikeTypes}
            label="Product"
            className="flex-grow-1"
            selectedValue={model}
            setSelectedValue={setModel}
          />
        </div>
      </div>

      <div className="d-md-flex flex-md-row align-items-center mx-md-auto px-md-5 py-5">
        <img src={data.imageSource} className={classes.img} />
        <div
          dangerouslySetInnerHTML={{ __html: data.html }}
          className="px-md-5 px-1 flex-grow-2"
        ></div>
      </div>

      <Typography variant="h3" className="mt-4">
        {"Annual Mainteinance Contract AMC"}
      </Typography>
      <Typography variant="subtitle1">
        What is an Annual Maintenance Contract (AMC)?
      </Typography>
      <Typography variant="body2">
        {
          "An annual maintenance contract is an agreement between a company and a provider that sets expectations for the ongoing maintenance of machinery or property that the company purchases from the provider. The annual maintenance contract ensures that the service provider will repair or replace equipment or products sold to the customer either when they are not functioning or as otherwise agreed between the two so as to minimize the consequent impact on downtime or irregularities in ensuring business continuity.\n\nAnnual maintenance services can apply to buildings, landscaping, computers, machinery, technically-enhanced furniture, and any other technology or business systems that require post-purchase service and maintenance. In specific industries, AMCs are certainly more prevalent than others. For instance: manufacturing, food service providers, healthcare, and retail have more annual maintenance needs than, say, an office.\n\nFor example, an office building may enact an AMC with the HVAC company that built its HVAC system. The contract would detail information like which equipment was covered, how often maintenance would be done, and what the costs of maintenance would be"
        }
      </Typography>
      <Typography variant="subtitle1" className="mt-3 mb-3">
        The typical annual maintenance contract format includes:
      </Typography>

      {amcIncludes.map((item) => {
        return (
          <div className="d-flex flex-row">
            <div className={`${classes.listBullet}`} />
            <Typography variant="body2" className="ms-3">
              {item}
            </Typography>
          </div>
        );
      })}

      <Typography variant="h3" className="mt-4">
        {"Benefits of having an annual maintenance contract"}
      </Typography>
      <Typography variant="body2">
        {
          "Regardless of the terms and conditions of an AMC, the service provider and their clients can benefit greatly from it. Here are some other benefits for companies for having an annual maintenance contract:"
        }
      </Typography>

      <Typography variant="subtitle1" className="mt-4">
        {"For Service providers:"}
      </Typography>
      {serviceProviderAmcBenifits.map((item) => {
        return (
          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              {item.title ? (
                <Typography variant="subtitle1">{item.title}</Typography>
              ) : null}
              {item.description ? (
                <Typography variant="body2">{item.description}</Typography>
              ) : null}
            </div>
          </div>
        );
      })}
      <Typography variant="subtitle1" className="mt-4">
        {"For customers:"}
      </Typography>
      {customerAmcBenifits.map((item) => {
        return (
          <div className="d-flex flex-row mt-3">
            <div className={`${classes.listBullet}`} />
            <div className="d-flex flex-column ms-3">
              {item.title ? (
                <Typography variant="subtitle1">{item.title}</Typography>
              ) : null}
              {item.description ? (
                <Typography variant="body2">{item.description}</Typography>
              ) : null}
            </div>
          </div>
        );
      })}
    </Paper>
  );
};

export default ServicesPageView;
