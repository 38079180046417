import React, { useEffect, useRef, useState } from "react";
import {
  getBottomBannerImage,
  getTopBannerImages,
} from "../../common/services/services";
import BonnyYamahaHomePageView from "./bonnyYamahaHomePageView";
import {
  awards,
  bikeTypes,
  faqs,
  reviews,
  routes,
  scooterTypes,
  STATUS,
} from "../../common/utils/constants";
import { useSelector } from "react-redux";
import { selectUserDetails } from "../../redux/slices/userDetailsSlice";
import { useNavigate } from "react-router-dom";

const BonnyYamahaHomePageContainer = () => {
  const bikesCaroselRef = useRef();
  const scooterCaroselRef = useRef();
  const navigate = useNavigate();
  const userDetailsObserver = useSelector(selectUserDetails);
  const userDetails = userDetailsObserver.userDetails;

  const modalForms = [
    {
      type: "PRODUCT_INQUIRY",
      title: "Product Inquiry",
      imageSource: require("../../assets/images/product_inquiry.png"),
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      linkTo: null,
    },
    {
      type: "SERVICES",
      title: "Services",
      imageSource: require("../../assets/images/services.png"),
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      linkTo: routes.SERVICES,
      linkTo: routes.SERVICES,
    },
  ];

  const offerItemImages = [
    require("../../assets/images/offer_image_1.png"),
    require("../../assets/images/offer_image_2.png"),
    require("../../assets/images/offer_image_3.png"),
  ];

  const [bannerImages, setBannerImages] = useState(null);
  const [bannerBottomImages, setBannerBottomImages] = useState(null);
  const [mobileBannerImages, setMobileBannerImages] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [city, setCity] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [userState, setUserState] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [name, setName] = useState(userDetails?.name);
  const [nameError, setNameError] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(userDetails?.mobile_no);
  const [numberError, setNumberError] = useState(null);
  const [email, setEmail] = useState(userDetails?.email);
  const [emailError, setEmailError] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getTopBannerImages()
      .then((response) => {
        if (response.banerTopImages) {
          setBannerImages(response.banerTopImages);
        } else {
          setBannerImages([]);
        }
      })
      .catch((error) => {
        setBannerImages([]);
        setApiError(error.message);
      });

    getBottomBannerImage()
      .then((response) => {
        if (response.banerBottomImages) {
          let images = [];
          for (var i = 0; i < response.banerBottomImages.length; i = i + 2) {
            let temp = [];
            if (response.banerBottomImages[i]) {
              temp.push(response.banerBottomImages[i]);
            }
            if (response.banerBottomImages[i + 1]) {
              temp.push(response.banerBottomImages[i + 1]);
            }
            images.push(temp);
          }
          setBannerBottomImages(images);
          setMobileBannerImages(response.banerBottomImages)
        } else {
          setBannerBottomImages([]);
          setMobileBannerImages([]);
        }
      })
      .catch((error) => {
        setBannerBottomImages([]);
        setMobileBannerImages([])
        setApiError(error.message);
      });
  }, []);

  const _showModal = (type) => {
    setModalType(type);
  };

  const navigateToServices = (type, data) => {
    navigate(routes.SERVICES, { state: { type: type, model: data.value } });
  };

  const _onSubmitClick = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile_number", mobileNumber);
    formData.append("city", city);
    formData.append("state", userState);
    formData.append("model_id", selectedModel);
  };

  return (
    <BonnyYamahaHomePageView
      bannerImages={bannerImages}
      apiError={apiError}
      setApiError={setApiError}
      modalForms={modalForms}
      bannerBottomImages={bannerBottomImages}
      userDetails={userDetails}
      reviews={reviews}
      faqs={faqs}
      awards={awards}
      offerItemImages={offerItemImages}
      bikeTypes={bikeTypes}
      bikesCaroselRef={bikesCaroselRef}
      scooterCaroselRef={scooterCaroselRef}
      navigateToServices={navigateToServices}
      _showModal={_showModal}
      name={name}
      setName={setName}
      nameError={nameError}
      mobileNumber={mobileNumber}
      setMobileNumber={setMobileNumber}
      numberError={numberError}
      city={city}
      setCity={setCity}
      cityError={cityError}
      state={userState}
      setState={setUserState}
      stateError={stateError}
      email={email}
      setEmail={setEmail}
      emailError={emailError}
      isLoading={isLoading}
      modalType={modalType}
      _onSubmitClick={_onSubmitClick}
      mobileBannerImages={mobileBannerImages}
    />
  );
};

export default BonnyYamahaHomePageContainer;
