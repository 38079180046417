import { Checkbox, FormControlLabel, Paper, Typography } from "@mui/material";
import React from "react";
import BaseInput, { inputType } from "../../common/components/inputs/baseInput";
import { makeStyles } from "@mui/styles";
import { darkGrey, formTitleColor, primaryBlue } from "../../common/colors";
import SolidButton from "../../common/components/buttons/solidButton";
import { Link } from "react-router-dom";
import { routes } from "../../common/utils/constants";
import CustomAlert from "../../common/components/customAlert";

const useStyles = makeStyles((theme) => {
  return {
    pageContainer: {
      minHeight: "100vh",
      justifyContent: "center",
      alignItems: "center",
    },
    formContainer: {
      width: "90vw",
      margin: "auto",
      paddingTop: "24px",
      paddingBottom: "24px",
      paddingLeft: "16px",
      paddingRight: "16px",
      [theme.breakpoints.up("md")]: {
        width: "50vw",
        paddingLeft: "56px",
        paddingRight: "56px",
        paddingTop: "44px",
        paddingBottom: "44px",
      },
    },
    formHeader: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "16px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "24px",
      },
    },
    checkBoxHighLightText: {
      fontSize: "14px",
      color: primaryBlue,
    },
    redirectText: {
      fontSize: "14px",
      color: formTitleColor,
    },
    redirect: {
      display: "flex",
      flexDirection: "row",
      alignSelf: "center",
      marginTop: "18px",
    },
    forgotPasswordText: {
      fontSize: "13px",
      color: darkGrey,
      textAlign: "right",
      marginTop: "10px",
    },
  };
});

const LoginPageView = ({
  email,
  password,
  setPassword,
  onLoginClick,
  emailError,
  passwordError,
  isLoading,
  _emailMobileCallback,
  error,
  setError,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.pageContainer} elevation={0}>
      <CustomAlert
        isShow={error}
        message={error}
        onClose={() => setError(null)}
      />
      <Paper elevation={3} className={classes.formContainer}>
        <div className={classes.formHeader}>
          <Typography variant="h5">Login</Typography>
        </div>
        <BaseInput
          placeholder="Enter Email Id/Mobile Number"
          label="Email Id/Mobile Number"
          value={email}
          setValue={_emailMobileCallback}
          error={emailError}
        />
        <BaseInput
          placeholder="Enter Password"
          customClasses="mt-3"
          label="Password"
          value={password}
          setValue={setPassword}
          type={inputType.PASSWORD}
          error={passwordError}
        />

        <Link
          to={routes.FORGOT_PASSWORD}
          className={classes.forgotPasswordText}
        >
          Forgot Password ?
        </Link>

        <SolidButton
          btnText="LOG IN"
          btnClick={onLoginClick}
          customClasses="mt-3"
          isLoading={isLoading}
        />

        <div className={classes.redirect}>
          <span className={classes.redirectText}>
            {"Didn’t have an account ?"}
          </span>
          <Link className={classes.checkBoxHighLightText} to={routes.REGISTER}>
            REGISTER
          </Link>
        </div>
      </Paper>
    </Paper>
  );
};

export default LoginPageView;
