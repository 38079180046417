import React from "react";
import DropDown from "../../../common/components/dropDown";
import BaseInput from "../../../common/components/inputs/baseInput";
import { ageTypes, engineTypes } from "../../../common/utils/constants";

const ModalForms = ({
  modalType,
  carName,
  setCarName,
  carNameError,
  budget,
  setBudget,
  budgetError,
  engineType,
  setEngineType,
  loanYears,
  setLoanYears,
  loanYearsError,
  oldLoan,
  setOldLoan,
  oldLoanError,
  ageType,
  setAgeType,
  name,
  setName,
  nameError,
  mobileNumber,
  setMobileNumber,
  numberError,
  email,
  setEmail,
  emailError,
  companyName,
  setCompanyName,
  companyNameError,
  city,
  setCity,
  cityError,
  state,
  setState,
  stateError,
  carNumber,
  setCarNumber,
  carNumberError,
}) => {
  return (
    <div>
      {modalType &&
      (modalType.type === "NEW_CAR_LOAN" ||
        modalType.type === "USED_CAR_LOAN") ? (
        <div className="d-flex flex-column">
          <BaseInput
            placeholder="Enter Name"
            customClasses="mt-3"
            label="Name"
            value={name}
            setValue={setName}
            error={nameError}
          />
          <BaseInput
            placeholder="Enter Email"
            customClasses="mt-3"
            label="Email"
            value={email}
            setValue={setEmail}
            error={emailError}
          />
          <BaseInput
            placeholder="Enter Mobile Number"
            customClasses="mt-3"
            label="Mobile Number"
            value={mobileNumber}
            setValue={setMobileNumber}
            error={numberError}
          />
          <BaseInput
            placeholder="Enter City"
            customClasses="mt-3"
            label="City"
            value={city}
            setValue={setCity}
            error={cityError}
          />
          <BaseInput
            placeholder="Enter State"
            customClasses="mt-3"
            label="State"
            value={state}
            setValue={setState}
            error={stateError}
          />
          <BaseInput
            placeholder="Car Company Name"
            customClasses="mt-3"
            label="Company Name"
            value={companyName}
            setValue={setCompanyName}
            error={companyNameError}
          />
          <BaseInput
            placeholder="Car Name"
            customClasses="mt-3"
            label="Car Name"
            value={carName}
            setValue={setCarName}
            error={carNameError}
          />
          {modalType.type === "USED_CAR_LOAN" ? (
            <BaseInput
              placeholder="Car Number"
              customClasses="mt-3"
              label="Enter Car Number"
              value={carNumber}
              setValue={setCarNumber}
              error={carNumberError}
            />
          ) : null}
          <DropDown
            list={engineTypes}
            className="mt-3"
            label="Engine Type"
            selectedValue={engineType}
            setSelectedValue={setEngineType}
          />
          <BaseInput
            placeholder="Budget"
            customClasses="mt-3"
            label="Budget"
            value={budget}
            setValue={setBudget}
            error={budgetError}
          />
          <BaseInput
            placeholder="Years"
            customClasses="mt-3"
            label="Years"
            value={loanYears}
            setValue={setLoanYears}
            error={loanYearsError}
          />
        </div>
      ) : (
        <div className="d-flex flex-column">
          <BaseInput
            placeholder="Enter Name"
            customClasses="mt-3"
            label="Name"
            value={name}
            setValue={setName}
            error={nameError}
          />
          <BaseInput
            placeholder="Enter Email"
            customClasses="mt-3"
            label="Email"
            value={email}
            setValue={setEmail}
            error={emailError}
          />
          <BaseInput
            placeholder="Enter Mobile Number"
            customClasses="mt-3"
            label="Mobile Number"
            value={mobileNumber}
            setValue={setMobileNumber}
            error={numberError}
          />
          <BaseInput
            placeholder="Enter City"
            customClasses="mt-3"
            label="City"
            value={city}
            setValue={setCity}
            error={cityError}
          />
          <BaseInput
            placeholder="Enter State"
            customClasses="mt-3"
            label="State"
            value={state}
            setValue={setState}
            error={stateError}
          />
          <DropDown
            list={ageTypes}
            className="mt-3"
            label="Engine Type"
            selectedValue={ageType}
            setSelectedValue={setAgeType}
          />
          <BaseInput
            placeholder="Enter Old Loan"
            customClasses="mt-3"
            label="Old Loan"
            value={oldLoan}
            setValue={setOldLoan}
            error={oldLoanError}
          />
          <BaseInput
            placeholder="Years"
            customClasses="mt-3"
            label="Years"
            value={loanYears}
            setValue={setLoanYears}
            error={loanYearsError}
          />
        </div>
      )}
    </div>
  );
};

export default ModalForms;
