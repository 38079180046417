import {
  AppBar,
  Toolbar,
  CssBaseline,
  useMediaQuery,
  Menu,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  nonMotorInsuranceTypes,
  routes,
  vehicleLoanItems,
} from "../utils/constants";
import { makeStyles, useTheme } from "@mui/styles";
import {
  formTitleColor,
  hoverBlue,
  primaryBlue,
  lightBackgroundGray,
} from "../colors";
import DrawerComponent from "./drawer";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../services/services";
import CustomAlert from "./customAlert";
import {
  clearRedux,
  selectUserDetails,
} from "../../redux/slices/userDetailsSlice";
import { clearInsurances } from "../../redux/slices/insuranceSlice";
import ProfileImg from "../../assets/images/profile.svg";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles((theme) => {
  return {
    navlinks: {
      marginLeft: theme.spacing(10),
      display: "flex",
    },
    logoContainer: {
      flexGrow: "1",
      cursor: "pointer",
      paddingLeft: "48px",
    },
    logo: {
      width: "150px",
      height: "48px",
    },
    link: {
      textDecoration: "none",
      color: formTitleColor,
      fontSize: "15px",
      fontWeight: "600",
      marginLeft: theme.spacing(6),
      cursor: "pointer",
      "&:hover": {
        color: primaryBlue,
      },
    },
    activeLink: {
      color: primaryBlue,
      fontSize: "15px",
      fontWeight: "700",
    },
    displayNone: {
      display: "none",
    },
    appBar: {
      display: "block",
      backgroundColor: "#ffffff",
    },
    iconContainer: {
      marginLeft: theme.spacing(9),
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
    },
    menuContainer: {
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
    },
    menuItem: {
      padding: "12px 24px",
      color: primaryBlue,
      "&:hover": {
        backgroundColor: hoverBlue,
      },
    },
    profileImg: {
      width: 24,
      height: 24,
    },
    primaryButton: {
      backgroundColor: primaryBlue,
      color: "white",
      fontSize: "14px",
      paddingBlock: "12px",
      paddingInline: "24px",
      borderRadius: "10px",
    },
    name: {
      fontSize: "16px",
      color: "black",
      marginLeft: "8px",
    },
    menuListContainer: {
      maxWidth: "250px",
      minWidth: "250px",
    },
    dropDownMenuItem: {
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "16px",
      paddingRight: "30px",
      wordWrap: "break-word",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: lightBackgroundGray,
        color: primaryBlue,
      },
    },
    dropDownMenuItemText: {
      fontSize: "16px",
      lineHeight: 1.35,
      wordWrap: "break-word",
    },
    icon: {
      height: "15px",
      width: "15px",
      marginLeft: "16px",
    },
  };
});

const NavBar = () => {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetailsObserver = useSelector(selectUserDetails);
  const { accessToken, userDetails } = userDetailsObserver || {};
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [insuranceAnchorEl, setInsuranceAnchorEl] = useState(null);
  const [motorInsuranceAnchorEl, setMotorInsuranceAnchorEl] = useState(null);
  const [nonMotorInsuranceAnchorEl, setNonMotorInsuranceAnchorEl] =
    useState(null);
  const [apiError, setApiError] = useState(null);

  const open = Boolean(anchorEl);
  const isInsuranceMenuOpen = Boolean(insuranceAnchorEl);
  const isMotorInsuranceOpen = Boolean(motorInsuranceAnchorEl);
  const isNonMotorInsuranceOpen = Boolean(nonMotorInsuranceAnchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openInsuranceMenu = (event) => {
    setInsuranceAnchorEl(event.currentTarget);
  };
  const closeInsuranceMenu = () => {
    setInsuranceAnchorEl(null);
  };

  const openMotorInsuranceMenu = (event) => {
    setMotorInsuranceAnchorEl(event.currentTarget);
  };
  const closeMotorInsuranceMenu = () => {
    setMotorInsuranceAnchorEl(null);
  };

  const openNonMotorInsuranceMenu = (event) => {
    setNonMotorInsuranceAnchorEl(event.currentTarget);
  };
  const closeNonMotorInsuranceMenu = () => {
    setNonMotorInsuranceAnchorEl(null);
  };

  const nonMotorInsuranceItemClick = (insurance) => {
    closeNonMotorInsuranceMenu();
    navigate(routes.INSURANCE, {
      state: insurance,
    });
  };

  const logoutClick = () => {
    handleClose();
    logout()
      .then((response) => {
        dispatch(clearRedux());
        dispatch(clearInsurances());
        navigate(routes.HOME_PAGE);
      })
      .catch((error) => {
        dispatch(clearRedux());
        dispatch(clearInsurances());
        setApiError(error.message);
      });
  };
  return (
    <AppBar
      position="static"
      className={
        location.pathname === "/" ? classes.displayNone : classes.appBar
      }
      elevation={0}
    >
      <CssBaseline />
      <CustomAlert
        isShow={apiError}
        message={apiError}
        onClose={() => setApiError(null)}
      />
      <Toolbar>
        {isMobile ? (
          <>
            <DrawerComponent />
            <div className={classes.logo}></div>
          </>
        ) : (
          <>
            <div className={classes.logoContainer}>
              <img
                src={require("../../assets/images/bonny_auto_logo.png")}
                className={classes.logo}
              />
            </div>
            <div className={classes.navlinks}>
              <div className={classes.navlinks}>
                <Link
                  to={routes.BONNY_AUTO_HOME}
                  className={`${classes.link} ${
                    location.pathname === routes.BONNY_AUTO_HOME
                      ? classes.activeLink
                      : ""
                  }`}
                >
                  Bonny Auto
                </Link>
                <Link
                  to={routes.BONNY_YAMAHA_HOME}
                  className={`${classes.link} ${
                    location.pathname === routes.BONNY_YAMAHA_HOME
                      ? classes.activeLink
                      : ""
                  }`}
                >
                  Bonny Yamaha
                </Link>
                <span
                  className={`${classes.link}`}
                  onClick={openInsuranceMenu}
                >
                  Insurance
                </span>
                <Menu
                  id="basic-menu"
                  anchorEl={insuranceAnchorEl}
                  open={isInsuranceMenuOpen}
                  onClose={closeInsuranceMenu}
                  PaperProps={{
                    style: {
                      width: "230px",
                      marginTop: "16px",
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className={classes.menuListContainer}
                >
                  <div className={classes.dropDownMenuItem}>
                    <span
                      onClick={() => nonMotorInsuranceItemClick({})}
                      className={classes.dropDownMenuItemText}
                    >
                      Motor Insurance
                    </span>
                  </div>
                  {nonMotorInsuranceTypes.map((item) => {
                    return (
                      <div className={classes.dropDownMenuItem}>
                        <span
                          onClick={() => nonMotorInsuranceItemClick(item)}
                          className={classes.dropDownMenuItemText}
                        >
                          {item.title}
                        </span>
                      </div>
                    );
                  })}
                </Menu>

                <span
                  className={`${classes.link}`}
                  onClick={openMotorInsuranceMenu}
                >
                  Loan
                </span>

                <Menu
                  id="basic-menu"
                  anchorEl={motorInsuranceAnchorEl}
                  open={isMotorInsuranceOpen}
                  onClose={closeMotorInsuranceMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{ style: { marginTop: "16px" } }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                  {vehicleLoanItems.map((item) => {
                    return (
                      <div className={classes.dropDownMenuItem}>
                        <span
                          onClick={() => nonMotorInsuranceItemClick(item)}
                          className={classes.dropDownMenuItemText}
                        >
                          {item.title}
                        </span>
                      </div>
                    );
                  })}
                </Menu>
                <Link
                  to={routes.ABOUT}
                  className={`${classes.link} ${
                    location.pathname === routes.ABOUT
                      ? classes.activeLink
                      : ""
                  }`}
                >
                  About Us
                </Link>
                <HashLink
                  to={`${routes.BONNY_AUTO_HOME}#contact-us`}
                  className={`${classes.link}`}
                >
                  Contact Us
                </HashLink>
                {userDetails ? (
                  <div
                    className={classes.iconContainer}
                    id="basic-button"
                    onClick={handleClick}
                  >
                    <img src={ProfileImg} className={classes.profileImg} />
                    <span className={classes.name}>
                      {userDetails && userDetails.name
                        ? userDetails.name
                        : "User"}
                    </span>
                  </div>
                ) : (
                  <Link to={routes.LOGIN} className={classes.link}>
                    <span className={classes.primaryButton}>
                      Login/Register
                    </span>
                  </Link>
                )}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                    disablePadding: true,
                  }}
                >
                  <div className={classes.menuContainer}>
                    <span className={classes.menuItem} onClick={logoutClick}>
                      {"Log out"}
                    </span>
                  </div>
                </Menu>
              </div>
            </div>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
