import React from "react";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    btn: {
      borderRadius: "10px",
      fontSize: "12px",
      padding: "10px 18px",
    },
  };
});

const SolidButton = ({
  btnText = "Submit",
  btnClick,
  customClasses = "",
  isLoading = false,
  isDisabled = false,
  endIcon,
}) => {
  const classes = useStyles();
  return (
    <LoadingButton
      variant="contained"
      onClick={btnClick}
      loading={isLoading}
      className={`${classes.btn} ${customClasses}`}
      disabled={isDisabled}
      endIcon={endIcon}
    >
      {btnText}
    </LoadingButton>
  );
};

export default SolidButton;
