import { Box, CssBaseline, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BonnyAutoLogo from "../../assets/images/bonny_auto_transparent_logo.png";
import LicensedByLogo from "../../assets/images/power_by_logo.png";
import VisaLogo from "../../assets/images/visa_logo.png";
import MasterCardLogo from "../../assets/images/master_card_logo.png";
import NetBankingLogo from "../../assets/images/net_banking_logo.png";
import CdLogo from "../../assets/images/cash_on_delivery_logo.png";
import { makeStyles } from "@mui/styles";
import FacebookLogo from "../../assets/images/facebook_logo.png";
import TwitterLogo from "../../assets/images/twitter_logo.png";
import InstagramLogo from "../../assets/images/instagram_logo.png";
import WhatsappLogo from "../../assets/images/whatsapp_log.ico";
import { HashLink } from "react-router-hash-link";
import {
  insureTypes,
  nonMotorInsuranceTypes,
  routes,
  vehicleLoanItems,
} from "../utils/constants";
import { primaryDarkBlue } from "../colors";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    poweredByImg: {
      height: "37px",
      maxWidth: "102px",
    },
    paymentOptionsImages: {
      height: "53px",
    },
    socialLogos: {
      width: "20px",
      height: "20px",
    },
    logo: {
      width: "70%",
    },
    footerCopyright: {
      fontSize: "15px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#646464",
    },
    padding0: {
      paddingRight: "0px",
      paddingLeft: "0px",
    },
  };
});

const Footer = () => {
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();

  const nonMotorInsuranceItemClick = (insurance) => {
    navigate(routes.INSURANCE, {
      state: insurance,
    });
  };

  return (
    <div className="footerContainer pt-5">
      <Box
        className={
          location.pathname === "/" ? "d-none" : `footer py-5 px-md-5 px-3`
        }
      >
        <div>
          <div className="row align-items-center">
            <div className="col-md-3 col-12">
              <img
                src={BonnyAutoLogo}
                className={`img-fluid me-5 ${classes.logo}`}
              />
            </div>

            <div className="col-md-9 col-12 mt-3 mt-md-0">
              {/* payment option row */}
              <div className="row px-md-5 mx-md-5">
                <div className="col d-flex align-items-center flex-column">
                  <Typography variant="body1">LICENSED BY</Typography>
                  <img src={LicensedByLogo} className={classes.poweredByImg} />
                </div>
                <div className="col align-items-center mt-3 mt-md-0">
                  <Typography className="text-center">
                    PAYMENT OPTIONS
                  </Typography>
                  <div className="d-flex flex-row">
                    <img
                      src={VisaLogo}
                      className={classes.paymentOptionsImages}
                    />
                    <img
                      src={MasterCardLogo}
                      className={classes.paymentOptionsImages}
                    />
                    <img
                      src={NetBankingLogo}
                      className={classes.paymentOptionsImages}
                    />
                    <img
                      src={CdLogo}
                      className={classes.paymentOptionsImages}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                {/* other links column 1 */}
                <div className="col-md-5 col-6 mt-5">
                  <Typography variant="subtitle1" className="text-center">
                    What We Specialize In
                  </Typography>
                  <div className="row mb-2">
                    <div className="col-6">
                      <HashLink
                        to={`${routes.BONNY_AUTO_HOME}#motor-insurance`}
                        className="mt-3"
                      >
                        <Typography variant="body2" className="mt-2">
                          Motor Insurance
                        </Typography>
                      </HashLink>
                      {nonMotorInsuranceTypes.slice(0, 5).map((item) => {
                        return (
                          <div
                            className="mt-2 cursorPointer"
                            onClick={() => nonMotorInsuranceItemClick(item)}
                          >
                            <Typography variant="body2">
                              {item.title}
                            </Typography>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-6">
                      {nonMotorInsuranceTypes
                        .slice(5, nonMotorInsuranceTypes.length)
                        .map((item) => {
                          return (
                            <div
                              className="mt-2 cursorPointer"
                              onClick={() => nonMotorInsuranceItemClick(item)}
                            >
                              <Typography variant="body2">
                                {item.title}
                              </Typography>
                            </div>
                          );
                        })}

                      {vehicleLoanItems.map((item) => {
                        return (
                          <div
                            className="mt-2 cursorPointer"
                            onClick={() => nonMotorInsuranceItemClick(item)}
                          >
                            <Typography variant="body2">
                              {item.title}
                            </Typography>
                          </div>
                        );
                      })}
                      <HashLink
                        to={`${routes.BONNY_AUTO_HOME}#car-exchange`}
                        className="mt-3"
                      >
                        <Typography variant="body2" className="mt-2">
                          Car Exchange
                        </Typography>
                      </HashLink>
                    </div>
                  </div>
                </div>

                {/* other links column 2 */}
                <div className="col-md-2 col-6 mt-5">
                  <HashLink
                    to={`${routes.BONNY_YAMAHA_HOME}#top`}
                    className="mt-3"
                  >
                    <Typography variant="subtitle1">Bonny Yamaha</Typography>
                  </HashLink>
                  <HashLink
                    to={`${routes.BONNY_AUTO_HOME}#top`}
                    className="mt-3"
                  >
                    <Typography variant="subtitle1" className="mt-3">
                      Bonny Auto
                    </Typography>
                  </HashLink>
                  <HashLink
                    to={`${location.pathname}#Our-Mission`}
                    className="mt-3"
                  >
                    <Typography variant="subtitle1" className="mt-3">
                      Our Mission
                    </Typography>
                  </HashLink>
                  <HashLink
                    to={`${routes.BONNY_AUTO_HOME}#Our-Vision`}
                    className="mt-3"
                  >
                    <Typography variant="subtitle1" className="mt-3">
                      Our Vision
                    </Typography>
                  </HashLink>
                  {/* <Link className="mt-3" to={routes.PRIVACY_POLICY}>
                    <Typography variant="subtitle1" className="mt-3">
                      Privacy Policy
                    </Typography>
                  </Link> */}
                  {/* <Link className="mt-3" to={routes.TERMS}>
                    <Typography variant="subtitle1" className="mt-3">
                      Terms & Conditions
                    </Typography>
                  </Link> */}

                  <Link className="mt-3" to={routes.ABOUT}>
                    <Typography variant="subtitle1" className="mt-3">
                      About Us
                    </Typography>
                  </Link>

                  <HashLink
                    to={`${routes.BONNY_AUTO_HOME}#contact-us`}
                    className="mt-3"
                  >
                    <Typography variant="subtitle1" className="mt-3">
                      Contact Us
                    </Typography>
                  </HashLink>
                </div>

                {/* contact us column */}
                <div className="col-md-3 col-6 mt-5">
                  <Typography variant="subtitle1">Contact Us</Typography>
                  <Typography variant="body2" className="mt-3">
                    Bonny Auto Ravapar Main Road, Morbi - 363641 Gujarat, India
                  </Typography>

                  <Typography variant="body2" className="mt-2">
                    +919825375754
                  </Typography>

                  <Typography variant="body2" className="mt-2">
                    info@bonnyauto.co.in
                  </Typography>

                  <Typography variant="body2" className="mt-2">
                    https://www.bonnyauto.co.in
                  </Typography>
                </div>

                {/* stay connected column */}
                <div className="col-md-2 col-6 mt-5">
                  <Typography variant="subtitle1">Stay Connected</Typography>
                  <div className="row mt-3">
                    <div className={`col ${classes.padding0}`}>
                      <img
                        src={FacebookLogo}
                        className={classes.socialLogos}
                        role="button"
                        onClick={() =>
                          window.open(
                            "https://www.facebook.com/BonnyAuto",
                            "_blank"
                          )
                        }
                      />
                    </div>
                    <div className={`col ${classes.padding0}`}>
                      <img
                        src={TwitterLogo}
                        className={classes.socialLogos}
                        role="button"
                        onClick={() =>
                          window.open(
                            "https://mobile.twitter.com/bonnyauto",
                            "_blank"
                          )
                        }
                      />
                    </div>
                    <div className={`col ${classes.padding0}`}>
                      <img
                        src={InstagramLogo}
                        className={classes.socialLogos}
                        role="button"
                        onClick={() =>
                          window.open(
                            "https://www.instagram.com/bonnyauto/?hl=en",
                            "_blank"
                          )
                        }
                      />
                    </div>
                    <div className={`col ${classes.padding0}`}>
                      <img
                        src={WhatsappLogo}
                        className={classes.socialLogos}
                        role="button"
                        onClick={() =>
                          window.open(`https://wa.me/${919825375754}`, "_blank")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* row other links */}
          {/* <div className="w-md-75 w-100 mt-5 m-auto">
            <hr />
            <div className="row">
              <Typography variant="caption" className="col text-center">
                ABOUT US
              </Typography>
              <HashLink
                to={`${routes.BONNY_AUTO_HOME}#contact-us`}
                className="col text-center border-left"
              >
                <Typography variant="caption">CONTACT US</Typography>
              </HashLink>
              <Link
                to={routes.PRIVACY_POLICY}
                className="col text-center border-left"
              >
                <Typography variant="caption">PRIVACY POLICY</Typography>
              </Link>
              <Link to={routes.TERMS} className="col text-center border-left">
                <Typography variant="caption">TERMS & CONDITIONS</Typography>
              </Link>
            </div>
            <hr />
          </div> */}

          <Typography
            variant="body2"
            className="mt-4 text-center"
            style={{ letterSpacing: "0.001em" }}
          >
            Copyright 2022 Bonny Auto. All rights reserved. Powered By
            <span style={{ color: primaryDarkBlue }}> CubixKraft</span>
          </Typography>
        </div>
      </Box>
    </div>
  );
};

export default Footer;
